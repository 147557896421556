import {formatPrice} from '../common';

const getAllCheckboxes = () => {
  return document.querySelectorAll('.js-select-for-download-check');
}

const setAllCheckboxesChecked = (checked: boolean) => {
  const checkboxes = getAllCheckboxes()
  checkboxes.forEach((x: HTMLInputElement) => {
    x.checked = checked;
  });
  handleCheckboxOnChanged();
}

/**
 * 全ての写真をダウンロード対象にする。
 */
const selectAllPhotoForDownload = () => {
  setAllCheckboxesChecked(true);
};

/**
 * 全ての写真をダウンロード対象から除外する。
 */
const unselectAllPhotoForDownload = () => {
  setAllCheckboxesChecked(false);
}

const handleCheckboxOnChanged = () => {
  let subtotal = 0;
  const checkboxes = document.querySelectorAll('.js-select-for-download-check:checked');
  checkboxes.forEach((x: HTMLInputElement) => {
    const priceText = x.dataset.dataPhotoPrice;
    const price = Number.parseInt(priceText);
    if (Number.isNaN(price)) {
      return;
    }
    subtotal += price;
  });

  const labels = document.querySelectorAll('.js-data-photo-price-subtotal');
  labels.forEach((x: HTMLElement) => {
    x.innerText = formatPrice(subtotal);
  });

  const subtotalLabels = document.querySelectorAll('.js-select-for-download-subtotal');
  subtotalLabels.forEach((x: HTMLElement) => {
    const otherPriceText = x.dataset.otherPrices;
    const otherPrice = Number.parseInt(otherPriceText);
    if (Number.isNaN(otherPrice)) {
      return;
    }
    x.innerText = formatPrice(otherPrice + subtotal);
  });

};

const handleSubmitButtonOnClick = () => {
  const checkboxes = document.querySelectorAll('.js-select-for-download-check:checked');

  const container = document.querySelector('.selected-photo-container');
  container.querySelectorAll(':scope > *').forEach(x => x.remove());

  checkboxes.forEach((x: HTMLInputElement, i) => {
    const fileNumberInput = document.createElement('input');
    fileNumberInput.type = 'hidden';
    fileNumberInput.value = x.dataset.fileNumber;
    fileNumberInput.name = `DownloadSelections[${i}].FileNumber`;
    container.appendChild(fileNumberInput);

    const photoSizeInput = document.createElement('input');
    photoSizeInput.type = 'hidden';
    photoSizeInput.value = x.dataset.photoSize;
    photoSizeInput.name = `DownloadSelections[${i}].PhotoSize`;
    container.appendChild(photoSizeInput);

    const photoIdInput = document.createElement('input');
    photoIdInput.type = 'hidden';
    photoIdInput.value = x.dataset.photoId;
    photoIdInput.name = `DownloadSelections[${i}].PhotoId`;
    container.appendChild(photoIdInput);
  });

  const form = container.closest('form') as HTMLFormElement;
  form.submit();
};

document.addEventListener('DOMContentLoaded', () => {

  const checkboxes = getAllCheckboxes();
  checkboxes.forEach((x: HTMLInputElement) => {
    x.addEventListener('change', () => {
      handleCheckboxOnChanged();
    });
  });

  const buttons = document.querySelectorAll('.js-select-for-download-submit-button');
  buttons.forEach((x: HTMLButtonElement) => {
    x.addEventListener('click', handleSubmitButtonOnClick);
  });

  const selectAllButtons = document.querySelectorAll('.js-select-all-photo-for-download');
  selectAllButtons.forEach((x: HTMLElement) => {
    x.addEventListener('click', () => selectAllPhotoForDownload());
  });

  const unselectAllButtons = document.querySelectorAll('.js-unselect-all-photo-for-download');
  unselectAllButtons.forEach((x: HTMLElement) => {
    x.addEventListener('click', () => unselectAllPhotoForDownload());
  });

});
