import {querySelectorAll} from '../../utilities';
import {getClickEventTarget} from '../../common/click-event-target';

const kDownloadButtonSelector = '.download-order-receipt-button';

const kOrderIdPlaceholder = '%ORDER_ID%';
const kDownloadReceiptUrl = `/Customer/api/Orders/${kOrderIdPlaceholder}/Receipt`;

/**
 * 領収書ダウンロードボタンクリック。
 * @param {Event} e イベント。
 */
const handleDownloadButtonOnClick = (e: Event) => {
  const button = getClickEventTarget(e) as HTMLButtonElement;
  if (!button || !button.dataset.orderId) {
    console.warn('領収書ダウンロード対象の注文IDが取得できません。', button);
    return;
  }
  const orderId = button.dataset.orderId;

  const url = kDownloadReceiptUrl.replace(kOrderIdPlaceholder, orderId);
  window.open(url, '_blank', 'noreferrer');
};

document.addEventListener('DOMContentLoaded', () => {
  querySelectorAll(kDownloadButtonSelector).forEach(x => {
    const button = x as HTMLButtonElement;
    button.addEventListener('click', handleDownloadButtonOnClick);
  });
});
