const appSettings = require('../../features.json');

export class FeatureFlag {
  static isEnabled(featureName: string): boolean {
    if (!appSettings) {
      return false;
    }

    if (!appSettings.hasOwnProperty('Features')) {
      return false;
    }
    return appSettings['Features'] && (appSettings['Features'][featureName] === true);
  }
}
