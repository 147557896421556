import {querySelectorAll} from '../utilities';
import {getClickEventTarget} from '../common/click-event-target';

const openNav = (target: HTMLElement) => {
  const wrapperSelector =target.dataset.targetWrapperSelector;
  const wrapper = (document.querySelector(wrapperSelector) as HTMLElement);
  wrapper.style.visibility = 'visible';
  if (wrapper.classList.contains('side-menu-wrapper-right')) {
    wrapper.style.right = '-320px';
  }
  (wrapper.querySelector('.side-menu')as HTMLElement).style.width = '320px';
};

const closeNav = () => {
  document.querySelectorAll('.side-menu-wrapper').forEach(wrapperElement => {
    const wrapper = wrapperElement as HTMLElement;
    wrapper.style.visibility = 'collapse';
    if (wrapper.classList.contains('side-menu-wrapper-right')) {
      wrapper.style.right = '0';
    }
    (wrapper.querySelector('.side-menu')as HTMLElement).style.width = '0';
  });
};

const initSideMenu = () => {
  querySelectorAll('.navbar-toggler').forEach(element => {
    const htmlElement = element as HTMLElement;
    htmlElement.addEventListener('click', e => {
      const target = getClickEventTarget(e);
      openNav(target);
    });
  });

  querySelectorAll('.side-menu .close-btn').forEach(element => {
    const htmlElement = element as HTMLElement;
    htmlElement.addEventListener('click', () => {
      closeNav();
    });
  });

  querySelectorAll('.side-menu-wrapper').forEach(element => {
    const htmlElement = element as HTMLElement;
    htmlElement.addEventListener('click', e => {
      let element = e.target as HTMLElement;
      while (element != null) {
        if (element.classList.contains('side-menu')) {
          return;
        }
        element = element.parentElement;
      }
      closeNav();
    });
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initSideMenu();
});

