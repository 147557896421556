import {querySelectorAll} from '../utilities';

document.addEventListener('DOMContentLoaded', () => {
  const inputs = querySelectorAll('input.trim-spaces');
  inputs.forEach((input: HTMLInputElement) => {
    input.addEventListener('blur', e => {
      const target = e.target as HTMLInputElement;
      if (target && target.value) {
        target.value = target.value.trim();
      }
    });
  });

  const forms = querySelectorAll('form');
  forms.forEach((form: HTMLFormElement) => {
    form.addEventListener('submit', e => {
      const container = e.target as HTMLFormElement;
      const inputs = querySelectorAll('input.trim-spaces', container);
      inputs.forEach((input: HTMLInputElement) => {
        if (input && input.value) {
          input.value = input.value.trim();
        }
      });
    });
  });
});
