/**
 * `value`がサーバーサイドの`true`か判定する。
 * - `valeu`が`boolean`の場合はその値を返す。
 * - `value`が`string`の場合は、文字列`true`とcase-insensitiveに一致する場合のみ`true`を返す。
 * - それ以外の場合は常に`false`を返す。
 * @param {string | boolean | undefined} value チェック対象の値。
 * @returns {boolean} `value`がサーバーサイドの`true`値であるか否か。
 */
export const isServerSideTrue = (value?: string | boolean): boolean => {
  if (typeof value === 'boolean') {
    return value;
  }

  if (typeof value === 'string') {
    return value.toUpperCase() === (`${true}`).toUpperCase();
  }

  return false;
};
