export class PhotoViewData {
  readonly photoCount: number;
  readonly minPhotoCount: number;
  readonly maxPhotoCount: number;
  readonly unitPrice: number;

  constructor(
    readonly itemId: string,
    readonly sessionId: string,
    readonly userId: string,
    readonly exhibitionRoomId: string,
    readonly photoId: string,
    readonly photoSize: string,
    readonly photoFileNumber: string,
    photoCount: string,
    minPhotoCount: string,
    maxPhotoCount: string,
    unitPrice: string
  ) {
    if (!itemId) {
      throw new Error('itemIdは必須です。');
    }
    if (!sessionId) {
      throw new Error('session idは必須です。');
    }
    if (!userId) {
      throw new Error('user idは必須です。');
    }
    if (!exhibitionRoomId) {
      throw new Error('exhibition room idは必須です。');
    }
    if (!photoId) {
      throw new Error('photo idは必須です。');
    }
    if (!photoFileNumber) {
      throw new Error('photo file numberは必須です。');
    }

    this.photoCount = PhotoViewData.parseInt(photoCount, 'photoCount');
    this.minPhotoCount = PhotoViewData.parseInt(minPhotoCount, 'minPhotoCount');
    this.maxPhotoCount = PhotoViewData.parseInt(maxPhotoCount, 'minPhotoCount');
    this.unitPrice = PhotoViewData.parseInt(unitPrice, 'unitPrice');
  }

  private static parseInt(value: string, name: string) {
    const result = Number.parseInt(value);
    if (Number.isNaN(result)) {
      throw new Error(`${name}が数値ではありません。`);
    }
    return result;
  }

  updatePhotoCount(count: number) {
    return new PhotoViewData(
        this.itemId,
        this.sessionId,
        this.userId,
        this.exhibitionRoomId,
        this.photoId,
        this.photoSize,
        this.photoFileNumber,
        count.toString(),
        this.minPhotoCount.toString(),
        this.maxPhotoCount.toString(),
        this.unitPrice.toString()
    );
  }
}
