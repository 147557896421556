import {closestClass, querySelector, querySelectorAll} from '../utilities';
import {getClickEventTarget} from './click-event-target';

const openDialog = (dialog: HTMLElement) => {
  const foundForm = querySelector(dialog.dataset.formSelector);
  if (!foundForm) {
    return;
  }

  const form = foundForm as HTMLFormElement;
  if (!form.checkValidity()) {
    form.reportValidity();
    return;
  }
  
  (dialog as any).confirmed = undefined;
  $(dialog).modal('show');
};

const closeDialog = (dialog: HTMLElement) => {
  $(dialog).modal('hide');
};


const handleYesButtonOnClick = (e: Event) => {

  const dialog = closestClass(e.target as HTMLElement, 'confirmation-dialog');
  if (!dialog) {
    console.error('確認ダイアログが見つかりません。');
    return;
  }
  const button = getClickEventTarget(e);
  if (button.dataset.customConfirmedEvent) {
    const confirmedEvent = new CustomEvent(button.dataset.customConfirmedEvent);
    document.dispatchEvent(confirmedEvent);
    (dialog as any).confirmed = true;
    if (!button.dataset.shouldExecuteCommonHandler) {
      return;
    }
  }

  // フォーム送信。
  const foundForm = querySelector(dialog.dataset.formSelector);
  if (!foundForm) {
    return;
  }
  const form = foundForm as HTMLFormElement;
  form.submit();

  // ダイアログは閉じる。
  closeDialog(dialog);
};

const handleButtonOnClick = (e: Event) => {
  const button = getClickEventTarget(e) as HTMLButtonElement;
  const selector = button.dataset.confirmationDialogSelector;
  const dialog = querySelector(selector) as HTMLElement;
  openDialog(dialog);
};


document.addEventListener('DOMContentLoaded', () => {
  const yesButtons = querySelectorAll('.confirmation-dialog .yes-button');
  yesButtons.forEach((button: HTMLElement) => {
    button.addEventListener('click', e => {
      handleYesButtonOnClick(e);
    });
  });

  const dialogs = querySelectorAll('.confirmation-dialog');
  dialogs.forEach((dialog: HTMLElement) => {
    $(dialog).on('hidden.bs.modal', e => {
      if (!(e.target as any).confirmed && e.target.dataset.customCanceledEvent) {
        const event = new CustomEvent(e.target.dataset.customCanceledEvent);
        document.dispatchEvent(event);
      }
    });
  });

  const submitButtons = querySelectorAll('.confirm-before-submit');
  submitButtons.forEach((button: HTMLElement) => {
    button.addEventListener('click', e => {
      handleButtonOnClick(e);
    });
  });
});
