import {querySelector} from '../../utilities';

const toggleSeparatorVisibility = () => {
  const labelElement = querySelector('.subtotal .label') as HTMLElement;
  const separatorElement = querySelector('.subtotal .separator') as HTMLElement;
  const valueElement = querySelector('.subtotal .value') as HTMLElement;
  if (labelElement.offsetTop == valueElement.offsetTop) {
    separatorElement.style.visibility = 'visible';
  } else {
    separatorElement.style.visibility = 'hidden';
  }
};

const initializePhotoSubtotal = () => {
  const isCartOrCheckoutConfirmation = !!querySelector('.page.cart')
      || !!querySelector('.page.checkout.checkout-confirmation');
  if (!isCartOrCheckoutConfirmation) {
    return;
  }

  toggleSeparatorVisibility();
};

window.addEventListener('resize', () => {
  initializePhotoSubtotal();
});

document.addEventListener('DOMContentLoaded', () => {
  initializePhotoSubtotal();
});
