import {getNumberOrZero, querySelector, querySelectorAll} from '../utilities';
import {clearAddress, showAddress} from './checkout-address';
import {isServerSideTrue} from '../utilities/is-server-side-true';
import {formatPrice} from '../common';

// region DOM要素の取得。
// #region DOM要素の取得。

/**
 * 選択されている送料ラジオボタンを取得する。
 * @return {HTMLInputElement} 選択中の送料ラジオボタン。
 */
const getCheckedShippingRadio = () => {
  const selector = '.page.checkout-shipping input.shipping-method-radio:checked';
  return querySelector(selector) as HTMLInputElement;
};

/**
 * 写真ダウンロードチェックボックスを取得する。
 * @returns {HTMLInputElement} チェックボックス。
 */
function getDownloadCheckbox() {
  const elem = querySelector('.page.checkout-shipping #ShouldDownload');
  return elem as HTMLInputElement;
}

// #endregion DOM要素の取得。
// endregion DOM要素の取得。

// region 選択中の料金の取得
// #region 選択中の料金の取得

/**
 * ダウンロード料を取得する。
 * @returns {number} ダウンロード料。
 */
const getDownloadFee = () => {
  const element = querySelector('#ShouldDownload');
  const checkbox = element as HTMLInputElement;
  if (!checkbox || !checkbox.checked) {
    return 0;
  }
  return getNumberOrZero(checkbox.dataset.downloadFee);
};

/**
 * 送料を取得する。
 * @returns {number} 送料。
 */
const getShippingFee = () => {
  const shippingRadio = getCheckedShippingRadio();
  if (!shippingRadio) {
    return 0;
  }
  const selectedRadio = shippingRadio as HTMLInputElement;
  return getNumberOrZero(selectedRadio.dataset.fee);
};

// #endregion 選択中の料金の取得
// endregion 選択中の料金の取得

// region 表示の更新
// #region 表示の更新

/**
 * 最新のダウンロード手数料、送料を反映して
 * 各種金額などを表示する。
 */
const showFeeAndPrices = () => {
  const fee = getShippingFee();
  const downloadFee = getDownloadFee();

  // 手数料の表示。
  showFee(fee);
  // 手数料の表示。
  showDownloadFee(downloadFee);

  // 合計金額の表示。
  showTotalValue(downloadFee, fee);

  // 送付先住所入力欄の表示切り替え
  showAddressForm();
};

/**
 * 画面に最新の送料を表示する。
 * @param {number} fee 送料。
 */
const showFee = (fee: number) => {
  const feeValues
      = querySelectorAll('.page.checkout-shipping .price-list-item.shipping-fee .value');

  feeValues.forEach((x: HTMLElement) => {
    x.innerText = formatPrice(fee).toString();
  });
};

/**
 * 画面に最新の送料を表示する。
 * @param {number} downloadFee 送料。
 */
const showDownloadFee = (downloadFee: number) => {
  const feeValues
      = querySelectorAll('.page.checkout-shipping .price-list-item.download-fee .value');

  feeValues.forEach((x: HTMLElement) => {
    x.innerText = formatPrice(downloadFee).toString();
  });
};


/**
 * 合計金額を表示する。
 * @param {number} downloadFee ダウンロード料。
 * @param {number} fee 送料。
 */
const showTotalValue = (downloadFee: number, fee: number) => {

  const totalValues
      = querySelectorAll('.page.checkout-shipping .price-list-item.total-price .value');

  totalValues.forEach((x: HTMLElement) => {
    // ここのphotoPriceには写真価格の合計 + 基本料！
    const subTotal = getNumberOrZero(x.dataset.photoPrice);
    const total = Math.round(subTotal + downloadFee + fee);
    x.innerText = formatPrice(total).toString();
  });
};

/**
 * 住所入力欄の表示を切り替える。
 */
const showAddressForm = () => {
  const row = querySelector('.page.checkout .checkout-address-row');
  if (!row) {
    return;
  }
  const checkedShippingRadio = getCheckedShippingRadio();

  const needsAddress = checkedShippingRadio
      && isServerSideTrue(checkedShippingRadio.dataset.needsAddress);

  if (needsAddress) {
    showAddress();
    row.classList.remove('d-none');
  } else {
    clearAddress();
    row.classList.add('d-none');
  }
};

// #endregion 表示の更新
// endregion 表示の更新

// region イベントハンドラ
// #region イベントハンドラ

/**
 * お届け方法選択ラジオボタンの選択変更イベント処理。
 */
const handleShippingMethodSelectOnChange = () => {
  showFeeAndPrices();
};

/**
 * 写真ダウンロードチェックボックス変更ハンドラ。
 */
const handleDownloadCheckboxOnChange = () => {
  showFeeAndPrices();

  // ダウンロード選択画面ボタンの表示切り替え。
  const checkbox = document.querySelector('#ShouldDownload') as HTMLInputElement;
  const downloadLabel = document.querySelector('.js-next-to-select-for-download-label') as HTMLElement;
  const paymentMethodLabel = document.querySelector('.js-next-to-payment-method-label') as HTMLElement;
  console.log('xXXXXX', checkbox, downloadLabel, paymentMethodLabel);
  if (checkbox.checked) {
    if (downloadLabel) {
      downloadLabel.classList.remove('d-none');
      paymentMethodLabel.classList.add('d-none');
    } else {
      paymentMethodLabel.classList.remove('d-none');
    }
  } else {
    downloadLabel?.classList?.add('d-none');
    paymentMethodLabel.classList.remove('d-none');
  }
};

// #endregion イベントハンドラ
// endregion イベントハンドラ

// region 初期化
// #region 初期化

/**
 * お届け方法選択ラジオボタンの初期化。
 */
const initializeShippingMethodRadio = () => {
  const shippingMethodSelects
      = querySelectorAll('.page.checkout-shipping input.shipping-method-radio');
  shippingMethodSelects.forEach((select: HTMLSelectElement) => {
    select.addEventListener('click', () => {
      handleShippingMethodSelectOnChange();
    });
  });
};

/**
 * ダウンロードチェックボックスの初期化。
 */
const initializeDownloadCheckbox = () => {
  const downloadCheckbox = getDownloadCheckbox();
  if (!downloadCheckbox) {
    return;
  }
  downloadCheckbox.addEventListener('change', () => {
    handleDownloadCheckboxOnChange();
  });
};

/**
 * お届け方法選択画面の初期化。
 */
const initializeCheckoutShipping = () => {
  if (!querySelector('.page.checkout-shipping')) {
    return;
  }

  initializeShippingMethodRadio();
  initializeDownloadCheckbox();

  handleShippingMethodSelectOnChange();
  handleDownloadCheckboxOnChange();
};

document.addEventListener('DOMContentLoaded', () => {
  initializeCheckoutShipping();
});

// #endregion 初期化
// endregion 初期化
