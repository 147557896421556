export * from './cart';


export interface Photo {
  id: string;
  fileNumber: number;
  firstFileNumber: number;
  eventCode: number;
  lastFileNumber: number;
  originalFileName: string;
  photoId: string;
  primaryCategoryName: string;
  saleEventId: string;
  secondaryCategoryId: string;
  secondaryCategoryName: string;
  tertiaryCategoryId: string;
  tertiaryCategoryName: string;
  isInFavorite?: boolean;
}

export interface ExhibitionRoom {
  basicInformation: BasicInformation;
  branchOfficeInformation: BranchOfficeInformation;
  cartInformation: CartInformation;
  createdAt: Date;
  creatorId: string;
  customerPrices: any | null;
  id: string;
  otherSetting: OtherSetting;
  priceSetting: PriceSetting;
  publicationPeriodCheckedAt: Date | null;
  secondaryCategories: SecondaryCategory[]
  updatedAt: Date;
}

export const computePriceWithFactor = (room: ExhibitionRoom, price: number) => {
  return Math.floor(price* getPriceFactor(room));
};
export const getPriceFactor = (room: ExhibitionRoom) => {
  if (!room?.basicInformation?.endDate) {
    return 1.0;
  }

  const getEndDate = () => {
    if (typeof room.basicInformation.endDate === 'string') {
      return new Date(room.basicInformation.endDate);
    }
    if (room.basicInformation.endDate instanceof Date) {
      return room.basicInformation.endDate;
    }
    return new Date(room.basicInformation.endDate);
  };


  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (getEndDate() > today) {
    return 1.0;
  }

  const factor = (room.basicInformation.priceFactorAfterPeriod ?? 0.0) / 100.0;
  return 1.0 + factor;
};

export const canAddToCartFroExhibitionRoom = (room: ExhibitionRoom) => {
  return (room?.basicInformation?.cartKind ?? 0) > 0;
};

export interface BasicInformation {
  canDownloadInHistory: boolean;
  cartKind: number;
  endDate: Date | null;
  priceFactorAfterPeriod?: number | null;
  eventCode: string;
  eventName: string;
  eventPassword: string;
  isFaceSearchTarget: boolean;
  primaryCategory: string;
  saleEventId: string;
  startDate: Date | null;
}

export interface BranchOfficeInformation {
  accessCode: string;
  accountName: string;
  accountNumber: string;
  accountType: string;
  bankBranchOfficeName: string;
  branchOfficeAddress: string;
  branchOfficeEmail: string;
  branchOfficeName: string;
  branchOfficePhoneNumber1: string;
  branchOfficePhoneNumber2: string;
  branchOfficePhoneNumber3: string;
  branchOfficePostalCode: string;
  financialInstitutionName: string;
  saleEventId: string;
  staffName: string;
}

export interface CartInformation {
  basicPrice: number | null;
  downloadFee: number | null;
  parentNameRequired: boolean;
  paymentMethods: PaymentMethod[];
  saleEventId: string;
  shippingMethods: ShippingMethod[]
}

export interface PaymentMethod {
  cartInformationId: string;
  fee: number | null;
  key: string;
  name: string;
  needsAddress: boolean;
  orderNumber: number;
  paymentMethodId: string;
}

export interface ShippingMethod {
  cartInformationId: string;
  fee: number | null;
  freeShippingThreshold: number | null;
  hasFreeShippingThreshold: boolean
  key: string;
  name: string;
  needsAddress: boolean;
  orderNumber: number;
  shippingMethodId: string;
}

export interface OtherSetting {
  guidanceAfterOrderConfirmation: string;
  saleEventId: string;
  saleEventListMessage: string;
}

export interface PriceSetting {
  fileNumberPriceRules: FileNumberPriceRule[];
  flatPriceRules: FlatPriceRule[];
  priceRuleKind: number;
  priceRuleKindIsByFileNumber: false
  priceRuleKindIsFlatPrice: false
  priceRuleKindIsNone: true
  saleEventId: '451d3ff9-8efb-4d41-a9f7-e67cef4b7f49'
  shouldHidePriceList: false
}

export interface FileNumberPriceRule {
  firstFileNumber: number;
  id: string;
  saleEventPriceSettingId: string;
  sizePrices: SizePrice[];
}

export interface PhotoCountRange {
  maxOrderCount: number | null;
  minOrderCount: number | null;
};

export interface SizePrice extends PhotoCountRange {
  fileNumberPriceRuleId: string;
  id: string;
  price: number;
  size: number;
}

export interface FlatPriceRule extends PhotoCountRange {
  id: string;
  price: number;
  saleEventPriceSettingId: string;
  size: number;
}

export interface SecondaryCategory {
  id: string;
  saleEventId: string;
  secondaryCategoryName: string;
  tertiaryCategories: TertiaryCategory[];
}

export interface TertiaryCategory {
  firstFileNumber: number;
  id: string;
  lastFileNumber: number;
  secondaryCategoryId: string;
  tertiaryCategoryName: string;
}


export const isFileNumberPriceRule = (rule?: any): rule is FileNumberPriceRule => {
  if (typeof rule !== 'object') {
    return;
  }
  if (!rule) {
    return;
  }
  return typeof (rule as FileNumberPriceRule).firstFileNumber === 'number'
    && Array.isArray((rule as FileNumberPriceRule).sizePrices);
};

export const isFlatPriceRule = (rule?: any): rule is FlatPriceRule => {
  if (typeof rule !== 'object') {
    return;
  }
  if (!rule) {
    return;
  }
  return typeof (rule as FlatPriceRule).size === 'number'
    && typeof (rule as FlatPriceRule).price === 'number'
    && typeof (rule as FlatPriceRule).id === 'string';

};
