import {Photo} from '../models';

/**
 * お気に入りURL。
 */
export const favoriteUrl = '/Customer/Api/Favorites';

/**
 * お気に入り削除URL。
 */
export const favoriteRemoveUrl = '/Customer/Api/Favorites/Remove';

/**
 * カートに追加URL。
 */
export const addToCartUrl = '/Customer/Api/Cart/Items';

/**
 * 写真のURLフォーマット。
 */
export const photoUrlFormat = '/Customer/Api/Photos/'
  + '%SESSION_ID%/%USER_ID%/%EXHIBITION_ROOM_ID%/%PHOTO_ID%'
  + '/Thumbnail';


/**
 * 写真URL(フロント用)を作成する。
 * @param userId ユーザーID。
 * @param sessionId セッションID。
 * @param photo 写真データ。
 * @param watermarkKind 透かし種別。
 * @returns {string} 写真URL。
 */
export const createPhotoUrl = (userId: string, sessionId: string, photo: Photo, watermarkKind?: number): string => {
  return createPhotoUrl2(userId, sessionId, photo.saleEventId, photo.photoId, watermarkKind);
};

/**
 * 写真URL(フロント用)を作成する。
 * @param userId ユーザーID。
 * @param sessionId セッションID。
 * @param exhibitionRoomId 写真データ。
 * @param photoId 写真データ。
 * @param watermarkKind 透かし種別。
 * @returns {string} 写真URL。
 */
export const createPhotoUrl2 = (userId: string, sessionId: string, exhibitionRoomId: string, photoId: string, watermarkKind?: number): string => {
  const result = photoUrlFormat
      .replace('%USER_ID%', userId)
      .replace('%SESSION_ID%', sessionId)
      .replace('%EXHIBITION_ROOM_ID%', exhibitionRoomId)
      .replace('%PHOTO_ID%', photoId);
  if (typeof watermarkKind === 'number') {
    return result + `?watermarkKind=${watermarkKind}`;
  }
  return result;
};
