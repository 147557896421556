import {querySelector, querySelectorAll} from '../utilities';

const showPasswordIconFileName = 'eye.svg';

const hidePasswordIconFileName = 'eye-slash.svg';

const showPassword = (input: HTMLInputElement, image: HTMLImageElement) => {
  input.type = 'text';
  image.src = image.src.replace(showPasswordIconFileName, hidePasswordIconFileName);
};

const hidePassword = (input: HTMLInputElement, image: HTMLImageElement) => {
  input.type = 'password';
  image.src = image.src.replace(hidePasswordIconFileName, showPasswordIconFileName);
};

document.addEventListener('DOMContentLoaded', () => {
  const passwordForms = querySelectorAll('.password-input');
  if (!passwordForms) {
    return;
  }

  const wrappers = querySelectorAll('.password-mask-icon-wrapper');
  wrappers.forEach((wrapper: HTMLElement) => {
    wrapper.addEventListener('click', (e: Event) => {
      const target = e.currentTarget as HTMLElement;
      const inputElement
          = querySelector('.password-input-field', target.parentElement) as HTMLInputElement;
      const imageElement = querySelector('img', target) as HTMLImageElement;
      if (inputElement.type === 'password') {
        showPassword(inputElement, imageElement);
      } else {
        hidePassword(inputElement, imageElement);
      }
    });
  });
});
