import {ExhibitionRoom} from '../models';
import {PhotoQueryParameterByExhibitionRoom} from './photos-index-base';
import {querySelector, querySelectorAll} from '../../utilities';
import {BehaviorSubject, filter, fromEvent, tap} from 'rxjs';
import {Modal} from 'bootstrap';
import {SearchByPhotoResult} from './search-by-photo-result';


const childIdListKey = 'childIdListKey';

/**
 * 写真で検索ダイアログ。
 */
export class SearchByPhotoDialog {

  private modalElement: Modal;

  private unavailableDialogElement: Modal;

  private exhibitionRooms: ExhibitionRoom[] = [];
  private queryParameter: PhotoQueryParameterByExhibitionRoom = null;
  private isFaceSearchTarget = false;
  private childIds$ = new BehaviorSubject<string[]>([]);
  private searchResultIsVisible$ = new BehaviorSubject(false);

  public constructor() {
    const rootElement = querySelector('#child-search-dialog') as HTMLElement;
    this.modalElement = new Modal(rootElement);

    const unavailableElement = querySelector('#face-search-unavailable-dialog') as HTMLElement;
    this.unavailableDialogElement = new Modal(unavailableElement);

    this.initializeChildIdsFromLocalStorage();

    // 子供写真のチェックトグル処理。
    fromEvent(document, 'click')
        .pipe(
            filter(e => {
              const target = e.target as HTMLElement;
              if (!target || !target.matches) {
                return false;
              }
              const selector = '#child-search-dialog .child-photo-list-item-content'
                  + ', #child-search-dialog .child-photo-list-item-content *';
              return target.matches(selector);
            }),
            tap(e => SearchByPhotoDialog.handleChildPhotoOnClick(e))
        )
        .subscribe();

    // 検索実行処理。
    fromEvent(document, 'click')
        .pipe(
            filter(e => {
              const target = e.target as HTMLElement;
              if (!target || !target.matches) {
                return false;
              }
              const selector = '#child-search-dialog .execute-search-button'
                  + ', #child-search-dialog .execute-search-button *';
              return target.matches(selector);
            }),
            tap(() => this.handleExecuteButtonOnClick())
        )
        .subscribe();

    // 検索用のお子様情報ID変更処理。
    this.childIds$
        .pipe(
            tap(ids => this.search(ids))
        )
        .subscribe();

    // 子供検索ダイアログオープン処理。
    fromEvent(document, 'click')
        .pipe(
            filter(e => {
              const target = e.target as HTMLElement;
              if (!target || !target.matches) {
                return false;
              }
              const selector = '.page.photo-list .search-by-photo-button'
                  + ', .page.photo-list .search-by-photo-button *';

              return target.matches(selector);
            }),
            tap(() => this.handleSearchByPhotoButtonOnClick())
        )
        .subscribe();

    // 写真検索結果の表示フラグ変更イベント。
    this.searchResultIsVisible$
        .pipe(
            tap(visible => this.setSearchResultVisibility(visible))
        )
        .subscribe();
  }

  // region API
  // #region API

  /**
   * 写真一覧画面で、検索パラメータが更新された時のコールバック。
   *
   * @param exhibitionRooms 現在の展示室一覧。
   * @param queryParameter 検索パラメータ。
   */
  updateQueryParameter(
      exhibitionRooms: ExhibitionRoom[],
      queryParameter: PhotoQueryParameterByExhibitionRoom) {
    this.exhibitionRooms = exhibitionRooms;
    this.queryParameter = queryParameter;
    this.isFaceSearchTarget = this
        .exhibitionRooms
        .filter(x => x.basicInformation.isFaceSearchTarget).length > 0;
  }

  // #endregion API
  // endregion API

  // region 初期化
  // #region 初期化

  /**
   * ローカルストレージからお子様情報IDを取得して、画面と検索パラメータに反映する。
   * @private
   */
  private initializeChildIdsFromLocalStorage() {
    const childIds = SearchByPhotoDialog.getChildIdsFromLocalStorage();
    const allChildIds = SearchByPhotoDialog.getCheckboxContainers().map(x => x.dataset.childId);
    const filtered = childIds.filter(x => allChildIds.includes(x));
    this.updateCheckboxes(filtered);
    this.childIds$.next(filtered);
  }

  // #endregion 初期化
  // endregion 初期化

  // region 画面更新関連
  // #region 画面更新関連

  /**
   * チェックボックスのコンテナ(childIdを保持する要素)を取得する。
   * @return {HTMLElement[]} チェックボックスのコンテナ一覧。
   * @private
   */
  private static getCheckboxContainers(): HTMLElement[] {
    const itemsSelector = '#child-search-dialog .child-photo-list-item-content';
    return querySelectorAll(itemsSelector).map(x => x as HTMLElement);
  }

  /**
   * 指定されたお子様情報ID配列に従って、画面のチェックボックスをON/OFFする。
   * @param {string[]} childIds お子様ID一覧。
   * @private
   */
  private updateCheckboxes(childIds: string[]) {
    SearchByPhotoDialog.getCheckboxContainers().forEach(item => {
      if (childIds.includes(item.dataset.childId)) {
        item.classList.add('checked');
      } else {
        item.classList.remove('checked');
      }
    });
  }

  // #endregion 画面更新関連
  // endregion 画面更新関連

  // region ローカルストレージ処理関連
  // #region ローカルストレージ処理関連

  /**
   * 子供IDをローカルストレージから取得する。
   * @return {string[]} お子様情報IDの配列。
   * @private
   */
  private static getChildIdsFromLocalStorage(): string[] {

    try {
      const text = localStorage.getItem(childIdListKey);
      if (text && typeof text === 'string') {
        const parsed = JSON.parse(text);
        if (Array.isArray(parsed)) {
          return parsed as string[];
        }
      }
      return [];
    } catch (err) {
      console.error('ローカルストレージからお子様情報IDを取得できません', err);
      return [];
    }
  }

  /**
   * 指定されたお子様情報ID配列で、ローカルストレージを更新する。
   * @param {string[]} childIds お子様情報ID配列。
   * @private
   */
  private static saveChildIdsToLocalStorage(childIds: string[]) {
    localStorage.setItem(childIdListKey, JSON.stringify(childIds));
  }


  // #endregion ローカルストレージ処理関連
  // endregion ローカルストレージ処理関連

  // region イベントハンドラ
  // #region イベントハンドラ


  /**
   * 写真で絞り込みボタンクリック処理 (ダイアログを開く)。
   * @private
   */
  private handleSearchByPhotoButtonOnClick() {
    if (!this.isFaceSearchTarget) {
      this.unavailableDialogElement.show();
    } else {
      this.modalElement.show();
    }
  }

  /**
   * 子供写真クリック。
   * @param {Event} e イベント。
   * @private
   */
  private static handleChildPhotoOnClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target as HTMLElement;
    const content = target?.closest('.child-photo-list-item-content');
    if (content?.classList.contains('checked')) {
      content?.classList.remove('checked');
    } else {
      content?.classList.add('checked');
    }
  }


  /**
   * 検索実行ボタンクリック。
   * @private
   */
  private handleExecuteButtonOnClick() {
    const checkedItemsSelector = '#child-search-dialog .child-photo-list-item-content.checked';
    const checkedItems = querySelectorAll(checkedItemsSelector);

    const childIds = checkedItems.map((x: HTMLElement) => x.dataset.childId).sort();
    SearchByPhotoDialog.saveChildIdsToLocalStorage(childIds);
    this.childIds$.next(childIds);
  }

  // #endregion イベントハンドラ
  // endregion イベントハンドラ

  // region 検索処理
  // #region 検索処理

  private search(ids: string[]) {
    SearchByPhotoResult.instance.search(ids);
    this.modalElement.hide();
  }

  // #endregion 検索処理
  // endregion 検索処理

  // region 検索結果表示関連
  // #region 検索結果表示関連

  /**
   * 検索結果の表示状態を設定する。
   * @param {boolean} visible 検索結果を表示するか否か。
   * @private
   */
  private setSearchResultVisibility(visible: boolean) {

  }

  // #endregion 検索結果表示関連
  // endregion 検索結果表示関連

}
