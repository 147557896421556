import {querySelector, querySelectorAll, withQuerySelectorAllAsync} from '../utilities';

const kChildInformationSelectSelector = '.customer-information #ChildInformationId';
const kChildInformationSelectedOptionSelector
    = '.customer-information #ChildInformationId option:checked';
const kChildInformationFieldSelector = '.customer-information .child-information-field';

const showInputsAsync = async (option: HTMLOptionElement) => {
  if (!option) {
    return;
  }

  const childId = option.value;
  const hasChildId = Boolean(childId);
  const schoolInfoIsRequired = (( option.dataset.schoolInfoIsRequired?.toLowerCase() ?? '' ) === 'true');
  const shouldShowInputArea = !hasChildId || schoolInfoIsRequired;

  const isOptionalParentNameInput = (input: HTMLInputElement) => {
    const isParentName = input.id === 'ParentName';
    const isRequired = input
        .parentElement
        .parentElement
        .querySelector('label')
        .classList
        .contains('required');
    return isParentName && !isRequired;
  };

  const isStudentInput = (input: HTMLInputElement) => input.id === 'StudentNumber';

  await withQuerySelectorAllAsync<HTMLElement, void>(kChildInformationFieldSelector,
      field => {
        if (shouldShowInputArea) {
          field.classList.remove('d-none');
          querySelectorAll('input', field).forEach(x => {
            const input = x as HTMLInputElement;
            input.value = '';
            if (isOptionalParentNameInput(input) || isStudentInput(input)) {
              return;
            }
            input.required = true;
          });
        } else {
          field.classList.add('d-none');
          querySelectorAll('input', field).forEach(x => {
            const input = x as HTMLInputElement;
            input.required = false;
          });
        }
      });

  console.log('test', option.dataset);

  (querySelector(kChildInformationFieldSelector + ' #ChildLastName') as HTMLInputElement)
      .value = option.dataset.lastName;
  (querySelector(kChildInformationFieldSelector + ' #ChildLastName') as HTMLInputElement)
      .readOnly = hasChildId && schoolInfoIsRequired;

  (querySelector(kChildInformationFieldSelector + ' #ChildFirstName') as HTMLInputElement)
      .value = option.dataset.firstName;
  (querySelector(kChildInformationFieldSelector + ' #ChildFirstName') as HTMLInputElement)
      .readOnly = hasChildId && schoolInfoIsRequired;

  (querySelector(kChildInformationFieldSelector + ' #ChildLastNameKana') as HTMLInputElement)
      .value = option.dataset.lastNameKana;
  (querySelector(kChildInformationFieldSelector + ' #ChildLastNameKana') as HTMLInputElement)
      .readOnly = hasChildId && schoolInfoIsRequired;

  (querySelector(kChildInformationFieldSelector + ' #ChildFirstNameKana') as HTMLInputElement)
      .value = option.dataset.firstNameKana;
  (querySelector(kChildInformationFieldSelector + ' #ChildFirstNameKana') as HTMLInputElement)
      .readOnly = hasChildId && schoolInfoIsRequired;

  (querySelector(kChildInformationFieldSelector + ' #ParentName') as HTMLInputElement)
      .value = option.dataset.parentName;
  (querySelector(kChildInformationFieldSelector + ' #ParentName') as HTMLInputElement)
      .readOnly = hasChildId && schoolInfoIsRequired;

  (querySelector(kChildInformationFieldSelector + ' #SchoolName') as HTMLInputElement)
      .value = schoolInfoIsRequired ? (option.dataset.checkoutSchoolName ?? '') :option.dataset.schoolName;

  (querySelector(kChildInformationFieldSelector + ' #SchoolYear') as HTMLInputElement)
      .value = schoolInfoIsRequired ? (option.dataset.checkoutSchoolYear ?? '') : option.dataset.schoolYear;

  (querySelector(kChildInformationFieldSelector + ' #SchoolClass') as HTMLInputElement)
      .value = schoolInfoIsRequired ? (option.dataset.checkoutSchoolClass ?? '') :option.dataset.schoolClass;

  (querySelector(kChildInformationFieldSelector + ' #StudentNumber') as HTMLInputElement)
      .value = schoolInfoIsRequired ? (option.dataset.checkoutStudentNumber ?? '') :option.dataset.studentNumber;
};

const handleChildInformationSelectOnChangeAsync = async () => {
  const selectedOptionElem = querySelector(kChildInformationSelectedOptionSelector);
  if (!selectedOptionElem) {
    return;
  }
  const selectedOption = selectedOptionElem as HTMLOptionElement;
  await showInputsAsync(selectedOption);
};


const initializeCheckoutCustomerInformationAsync = async () => {
  const childInformationElem = querySelector(kChildInformationSelectSelector);
  if (!childInformationElem) {
    return;
  }
  const childInformationSelect = childInformationElem as HTMLSelectElement;
  childInformationSelect.addEventListener('change', async () => {
    await handleChildInformationSelectOnChangeAsync();
  });
  await handleChildInformationSelectOnChangeAsync();
};


document.addEventListener('DOMContentLoaded', async () => {

  if (!querySelector('.page.checkout-customer-information')) {
    return;
  }

  await initializeCheckoutCustomerInformationAsync();
});
