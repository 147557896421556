import {querySelector, querySelectorAll} from '../../utilities';

const setPlaceHolder = (image: HTMLImageElement) => {
  image.src = '/img/icons/smile.svg';
};

document.addEventListener('DOMContentLoaded', () => {
  if (!querySelector('.my-page.account-page')) {
    return;
  }

  const images = querySelectorAll('.child-information-list-item .photo-wrapper img');
  images.forEach((image: HTMLImageElement) => {
    const fileReader = new FileReader();
    fileReader.onerror = () => {
      setPlaceHolder(image);
    };
    fileReader.onload = e => {
      const target = e.currentTarget as FileReader;
      image.src = target.result as string;
    };

    fetch(image.src)
        .then(async x => {
          if (!x.ok) {
            setPlaceHolder(image);
            return;
          }

          const blob = await x.blob();
          fileReader.readAsDataURL(blob);
        })
        .catch(_ => {
          setPlaceHolder(image);
        });
  });
});
