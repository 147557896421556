import {
  canAddToCartFroExhibitionRoom, computePriceWithFactor,
  ExhibitionRoom,
  FileNumberPriceRule,
  FlatPriceRule,
  isFileNumberPriceRule,
  isFlatPriceRule,
  Photo,
  PhotoCountRange
} from '../models';
import {closestClass, querySelector, querySelectorAll} from '../../utilities';
import {createPhotoUrl} from '../common';
import {photoSizeIndexToName, photoSizeToDisplayTexts} from '../../models';
import {BehaviorSubject, concatMap, filter, firstValueFrom, fromEvent, map, Observable, of, switchMap, tap} from 'rxjs';
import {AddToCartParam, CartFacade} from '../cart';
import {formatPrice} from '../../common';

import * as Hammer from 'hammerjs';
import {FeatureFlag} from '../../utilities/FeatureFlag';
import {Point} from '../common/Point';
import {Size} from '../common/Size';

export interface PhotoDetailParameter {
  photo: Photo,
  exhibitionRoom: ExhibitionRoom,
  currentRule: FileNumberPriceRule | FlatPriceRule | undefined,
  photoSize: number,
  photoCount: number,
  photoCountRange: { min: number, max: number },
  isInCart: boolean
}

/**
 * 写真アクセスインタフェース。
 */
export interface IPhotoAccessor {
  /**
   * 前の写真、次の写真を取得する。
   * @param photoId 写真ID。
   * @param isPrev 前の写真の場合`true`。
   */
  getPrevNextPhoto(photoId: string, isPrev: boolean): Observable<PhotoDetailParameter>;

  /**
   * 写真枚数範囲を取得する。
   * @param priceRule 対象の価格ルール。
   * @param size 写真サイズ。
   */
  getPhotoCountRange(
    priceRule: FlatPriceRule | FileNumberPriceRule,
    size?: number): PhotoCountRange | undefined;
}

/**
 * 写真詳細ダイアログ。
 */
export class PhotoDetailDialog {

  private readonly rootElement: HTMLElement;
  private readonly photoNameElement: HTMLElement;
  private readonly photoPriceElement: HTMLElement;
  private readonly photoPriceUnitElement: HTMLElement;
  private readonly photoImageElement: HTMLImageElement;
  private readonly photoContainerElement: HTMLElement;
  private readonly photoImageElementHammer: HammerManager;
  private readonly photoSizeElement: HTMLSelectElement;
  private readonly photoSizeElementContainer: HTMLElement;
  private readonly photoCountElement: HTMLSelectElement;
  private readonly photoCountElementContainer: HTMLElement;
  private readonly scaleRangeElement: HTMLInputElement;

  private parameter: PhotoDetailParameter;
  private photo: Photo;
  private exhibitionRoom: ExhibitionRoom;
  private priceRule: FileNumberPriceRule | FlatPriceRule | undefined;

  private loading$ = new BehaviorSubject(false);

  private pointerDowned = false;
  private dragging = false;
  private dragStartPoint: Point = null;
  private startTranslate: Point = null;
  private currentTranslate: Point = null;
  private currentImageSize: Size = null;
  private actualImageSize: Size = null;

  private initialScaleValue: number = null;
  private prevPointerDownTime: Date | null = null;
  private shouldUseTouchEvent = false;
  private isLimitLeft = false;
  private isLimitLeft2 = false;
  private isLimitRight = false;
  private isLimitRight2 = false;

  private get scaleValue() {
    const result = parseFloat(this.scaleRangeElement.value);
    if (Number.isNaN(result)) {
      return 1;
    }
    return result;
  }

  public get currentPhotoId() {
    return this.parameter?.photo?.photoId;
  }

  constructor(
    private photoAccessor: IPhotoAccessor,
    private userId: string,
    private sessionId: string
  ) {
    this.rootElement = querySelector('.js-photo-detail-modal') as HTMLElement;
    this.photoNameElement = querySelector('.js-photo-detail-photo-name', this.rootElement) as HTMLElement;
    this.photoPriceElement = querySelector(
        '.js-photo-detail-price-label__price-value', this.rootElement) as HTMLElement;
    this.photoPriceUnitElement = querySelector(
        '.js-photo-detail-price-label__price-unit', this.rootElement) as HTMLElement;
    this.photoImageElement = querySelector(
        '.js-photo-detail-photo',
        this.rootElement) as HTMLImageElement;

    this.photoContainerElement = this.photoImageElement.parentElement.parentElement;

    this.photoSizeElement = querySelector('#photo-detail-size-select') as HTMLSelectElement;
    this.photoSizeElementContainer = this.photoSizeElement.closest('.js-photo-detail-size-select-container');
    this.photoCountElement = querySelector('#photo-detail-count-select') as HTMLSelectElement;
    this.photoCountElementContainer = this.photoCountElement.closest('.js-photo-detail-count-select-container');

    const scaleRangeSelector = '.js-photo-detail-scale-range';
    this.scaleRangeElement = querySelector(
        scaleRangeSelector,
        this.rootElement) as HTMLInputElement;

    this.photoImageElementHammer = new Hammer(this.photoImageElement);

    // サンプル位置変更
    fromEvent(document, 'input').pipe(
        filter(x => {
          if (!x || !((x.target as HTMLElement).matches)) {
            return false;
          }
          return (x.target as HTMLElement).matches('.js-photo-detail-sample-select');
        }),
        tap(x => {
          const kindValue = (x.target as HTMLSelectElement)?.value;
          const parsed = typeof (kindValue === 'string') ? Number.parseInt(kindValue) : 0;
          const kind = Number.isNaN(parsed) ? 0 : parsed;
          this.photoImageElement.src = createPhotoUrl(this.userId, this.sessionId, this.photo, kind);
        })
    ).subscribe();

    // 次の画像、前の画像。
    fromEvent(this.rootElement, 'click')
        .pipe(
            filter(() => !this.loading$.getValue()),
            filter(e => {
              const target = e?.target as HTMLElement;
              return target?.matches('.js-photo-detail-page-left-button, .js-photo-detail-page-left-button *')
            || target?.matches('.js-photo-detail-page-right-button, .js-photo-detail-page-right-button *');
            }),
            map(e => {
              return !!closestClass(e.target as HTMLElement, 'js-photo-detail-page-left-button');
            }),
            switchMap(isPrev => this.loadNextPrevPhoto(isPrev))
        ).subscribe();
    this.initializeSwipeMove();

    // 拡縮
    this.initializePinchZoom();
    fromEvent(this.scaleRangeElement, 'change')
        .pipe(
            tap(() => this.handleScaleRangeOnChange())
        )
        .subscribe();


    // 画像の移動
    this.initializeImageMove();

    // サイズの変更
    this.initializeSizeSelect();

    // 枚数変更
    this.initializeCountSelect();

    // カートに追加
    fromEvent(this.rootElement, 'click')
        .pipe(
            filter(e => {
              const target = e?.target as HTMLElement;
              const selector = '.js-photo-detail-add-to-cart-button';
              return target.matches(selector);
            }),
            concatMap(() => this.addToCart()),
            tap(() => {
            })
        )
        .subscribe({
          error: err => {
            console.error(err);
            // TODO: エラーメッセージ表示。
            alert('カートに追加できませんでした。');
          }
        });

    // カートから削除
    fromEvent(this.rootElement, 'click')
        .pipe(
            filter(e => {
              const target = e?.target as HTMLElement;
              const selector = '.js-photo-detail-delete-button, .js-photo-detail-delete-button *';
              return target.matches(selector);
            }),
            concatMap(() => this.removeFromCart()),
            tap(() => {
            })
        )
        .subscribe({
          error: err => {
            console.error(err);
            alert('削除に失敗しました。');
          }
        });

    // noinspection JSJQueryEfficiency
    $('.modal.photo-detail').on('shown.bs.modal', () => {
      this.updateActualImageSize();
    });

    // noinspection JSJQueryEfficiency
    $('.modal.photo-detail').on('hidden.bs.modal', () => {
      const e = new CustomEvent<string>('spss.photoDetailClosed', {
        detail: this.photo.id
      });
      document.dispatchEvent(e);
    });
  }

  // region メソッド
  // #region メソッド

  updateParameter(parameter: PhotoDetailParameter) {
    if (!this.parameter) {
      return;
    }
    if (this.parameter.photo.id == parameter.photo.id) {
      this.parameter = parameter;
      this.initializeView(parameter);
    }
  }

  showPhotoDetailDialog(parameter: PhotoDetailParameter) {

    this.parameter = parameter;
    this.photo = parameter.photo;
    this.exhibitionRoom = parameter.exhibitionRoom;
    this.priceRule = parameter.currentRule;

    this.initializeView(parameter);

    $('.modal.photo-detail').modal('show');
    setTimeout(() => {
      const x = $('.modal.photo-detail .modal-content');
      x.scrollTop(0);
    }, 200);
  }

  // #endregion メソッド
  // endregion メソッド

  // region 初期化
  // #region 初期化

  private initializeImageMove() {

    // 画像のロード。
    this.photoImageElement.addEventListener('load', () => {
      this.currentImageSize = {
        width: this.photoImageElement.naturalWidth,
        height: this.photoImageElement.naturalHeight
      };
      this.clearMoveLimits();
      this.updateActualImageSize();
    });

    window.addEventListener('resize', () => {
      this.updateActualImageSize();
    });

    // リセット
    fromEvent(document, 'click')
        .pipe(
            filter(x => {
              const target = (x?.target as HTMLElement);
              return !!target?.matches('.js-photo-detail-reset-scale-button, .js-photo-detail-reset-scale-button *');
            }),
            tap(() => {
              this.resetImageTransform();
            })
        ).subscribe({
          error: console.error
        });

    this.photoContainerElement.addEventListener('touchmove', e => {
      if (this.pointerDowned) {
        e.preventDefault();
        e.stopPropagation();
      }
    });

    this.photoContainerElement.addEventListener('touchstart', (e: TouchEvent) => {
      if (!this.currentImageSize) {
        return;
      }

      if (this.processDoubleTap('touch', e)) {
        return;
      }

      if (!this.scaleValue || this.scaleValue <= 1.0) {
        return;
      }

      if (e.touches.length !== 1) {
        this.pointerDowned = false;
        this.dragging = false;
        this.dragStartPoint = undefined;
        this.startTranslate = null;
        return;
      }

      const screenX = e.touches[0].screenX;
      const screenY = e.touches[0].screenY;

      this.pointerDowned = true;
      this.dragging = false;
      this.dragStartPoint = {
        x: screenX,
        y: screenY
      };
      this.startTranslate = {
        x: this.currentTranslate?.x ?? 0,
        y: this.currentTranslate?.y ?? 0
      };
    });

    // noinspection JSUnusedLocalSymbols
    this.photoImageElement.addEventListener('pointercancel', e => {
      // e.preventDefault();
      // e.stopPropagation();
    });
    // noinspection JSUnusedLocalSymbols
    this.photoImageElement.addEventListener('pointerleave', e => {
      // e.preventDefault();
      // e.stopPropagation();
    });
    // noinspection JSUnusedLocalSymbols
    this.photoImageElement.addEventListener('pointerenter', e => {
      // e.preventDefault();
      // e.stopPropagation();
    });


    this.photoContainerElement.addEventListener('pointerdown', (e: PointerEvent) => {
      if (e.button !== 0) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      if (this.processDoubleTap('pointer', e)) {
        return;
      }

      if (!this.scaleValue || this.scaleValue <= 1.0) {
        return;
      }

      if (this.initialScaleValue != null) {
        return;
      }

      if (e.pointerType === 'touch') {
        return;
      }

      if (!this.currentImageSize) {
        return;
      }


      this.pointerDowned = true;
      this.dragging = false;
      this.dragStartPoint = {
        x: e.screenX,
        y: e.screenY
      };
      this.startTranslate = {
        x: this.currentTranslate?.x ?? 0,
        y: this.currentTranslate?.y ?? 0
      };
    });
    this.photoContainerElement.addEventListener('pointermove', e => {

      if (!this.pointerDowned) {
        return;
      }

      if (this.initialScaleValue != null) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      const diffX = (e.screenX - this.dragStartPoint.x) / this.scaleValue;
      const diffY = (e.screenY - this.dragStartPoint.y) / this.scaleValue;

      if (!this.dragging) {
        if ((diffX * diffX + diffY * diffY) > 10) {
          this.dragging = true;
        }
      }

      if (!this.dragging) {
        return;
      }

      const x = (this.startTranslate.x + diffX);
      let y = (this.startTranslate.y + diffY);

      if (this.actualImageSize) {
        const xRange = this.computeTranslateMinMax(this.photoImageElement.width, this.actualImageSize.width);
        const yRange = this.computeTranslateMinMax(this.photoImageElement.height, this.actualImageSize.height );
        if (x <= xRange.min) {
          if (diffX < 0) {
            this.isLimitLeft = true;
            return;
          }
        }
        if (x >= xRange.max) {
          if (diffX > 0) {
            this.isLimitRight = true;
            return;
          }
        }
        if (y <= yRange.min) {
          y = yRange.min;
        }
        if (y >= yRange.max) {
          y = yRange.max;
        }
      }
      this.clearMoveLimits();

      this.currentTranslate = {
        x, y
      };

      this.photoImageElement.style.transform = `translate(${x}px, ${y}px)`;
    });
    // noinspection JSUnusedLocalSymbols
    this.photoImageElement.addEventListener('pointerout', e => {
      // e.preventDefault();
      // e.stopPropagation();
    });
    // noinspection JSUnusedLocalSymbols
    this.photoImageElement.addEventListener('pointerover', e => {
      // e.preventDefault();
      // e.stopPropagation();
    });
    document.addEventListener('pointerup', () => {
      this.pointerDowned = false;
      this.dragging = false;
      this.dragStartPoint = undefined;
      this.startTranslate = null;
    });
  }

  // #endregion 初期化
  // endregion 初期化

  // region 画面表示関連
  // #region 画面表示関連
  private initializeView(
      photoDetailParameter: PhotoDetailParameter
  ) {
    this.currentImageSize = null;

    this.resetImageTransform();
    this.initializeAddedBanner(photoDetailParameter.isInCart);

    const samplePositionElement = querySelector('.js-photo-detail-sample-select') as HTMLSelectElement;
    if (samplePositionElement) {
      samplePositionElement.value = '0';
    }
    this.photoImageElement.src = createPhotoUrl(this.userId, this.sessionId, this.photo);
    this.photoNameElement.textContent = this.photo.fileNumber.toString();

    const count = this.initializeCount(
        photoDetailParameter.photoCount,
        photoDetailParameter.photoCountRange);
    const size = this.initializeSize(photoDetailParameter.photoSize);

    this.initializePrice(size, count);

    this.initializeAddToCartButton(photoDetailParameter.isInCart);
  }

  private initializeAddToCartButton(photoIsInCart: boolean) {
    const addToCartButton = querySelector('.js-photo-detail-add-to-cart-button', this.rootElement);

    const canAddToCart = canAddToCartFroExhibitionRoom(this.exhibitionRoom);
    const showAddButton = canAddToCart;
    if (this.exhibitionRoom.basicInformation.cartKind > 0 && showAddButton) {
      addToCartButton.classList.remove('invisible');
    } else {
      addToCartButton.classList.add('invisible');
    }

    const removeFromCartButton = querySelector('.js-photo-detail-delete-button', this.rootElement);
    const showRemoveButton = FeatureFlag.isEnabled('NXVO-867') && canAddToCart && photoIsInCart;
    if (showRemoveButton) {
      removeFromCartButton.classList.remove('invisible');
    } else {
      removeFromCartButton.classList.add('invisible');
    }

    if (!showAddButton && !showRemoveButton) {
      removeFromCartButton.classList.remove('d-none');
      removeFromCartButton.classList.add('invisible');
    }
  }

  private initializeAddedBanner(photoIsInCart: boolean) {
    const addedBanner = querySelector('.js-photo-detail-added-to-cart-banner', this.rootElement);
    if (!addedBanner) {
      return;
    }
    if (photoIsInCart) {
      addedBanner.classList.remove('d-none');
      return;
    }
    addedBanner.classList.add('d-none');
  }

  private initializePrice(size: number | undefined, count: number | undefined) {
    const price = this.findPrice(size);
    const priceLabel = querySelector('.js-photo-detail-price-label', this.rootElement);
    if (Number.isNaN(price)) {
      priceLabel.classList.add('invisible');
      console.warn('写真詳細価格設定:サイズから単価を取得できません');
      return;
    }

    if (typeof size !== 'number') {
      size = parseInt(this.photoSizeElement.value);
      if (Number.isNaN(size)) {
        priceLabel.classList.add('invisible');
        console.warn('写真詳細価格設定:現在のサイズを取得できません');
        return;
      }
    }

    priceLabel.classList.remove('invisible');

    const subtotal = price * (count ?? 1);
    if (this.priceRule) {
      this.photoPriceElement.classList.remove('invisible');
      this.photoPriceUnitElement.classList.remove('invisible');
      this.photoPriceElement.textContent = formatPrice(subtotal);
    } else {
      this.photoPriceElement.classList.add('invisible');
      this.photoPriceUnitElement.classList.add('invisible');
    }
  }

  private findPrice(photoSize: number): number | undefined {
    const findPriceInner = () => {
      if (isFileNumberPriceRule(this.priceRule)) {
        const sizePrice = this.priceRule.sizePrices.filter(x => x.size === photoSize)[0];
        return sizePrice.price;
      } else if (this.priceRule) {
        return this.priceRule.price;
      }
    };
    const priceBase = findPriceInner();
    return computePriceWithFactor(this.exhibitionRoom, priceBase);
  }


  private initializeSize(photoSize?: number): number | undefined {
    querySelectorAll(':scope > *', this.photoSizeElement).forEach(x => {
      x.remove();
    });

    // カートに追加が不要な場合はサイズ無し。
    if (!this.exhibitionRoom.basicInformation.cartKind) {
      if (!isFileNumberPriceRule(this.priceRule) && !isFlatPriceRule(this.priceRule)) {
        this.photoSizeElementContainer.classList.add('d-none');
        return;
      }
    }

    this.photoSizeElementContainer.classList.remove('d-none');

    if (isFileNumberPriceRule(this.priceRule) && this.priceRule.sizePrices.length) {
      this.priceRule.sizePrices.forEach(x => {
        const option = document.createElement('option');
        option.value = x.size.toString();
        option.text = photoSizeToDisplayTexts[x.size];
        this.photoSizeElement.append(option);
      });
    } else if (this.priceRule && this.exhibitionRoom.priceSetting.flatPriceRules?.length) {
      this.exhibitionRoom.priceSetting.flatPriceRules
          .sort((a, b) => a.size - b.size)
          .forEach(x => {
            const option = document.createElement('option');
            option.value = x.size.toString();
            option.text = photoSizeToDisplayTexts[x.size];
            this.photoSizeElement.append(option);
          });
    } else {
      const option = document.createElement('option');
      option.value = '0';
      option.text = photoSizeToDisplayTexts[0];
      this.photoSizeElement.append(option);
    }
    this.photoSizeElement.value = photoSize.toString();
    return photoSize;
  }

  private initializeCount(
      photoCount: number | undefined,
      photoCountRange: { min: number; max: number }
  ): number | undefined {
    querySelectorAll(':scope > *', this.photoCountElement).forEach(x => {
      x.remove();
    });

    // カートに追加が不要な場合は枚数無し。
    if (!this.exhibitionRoom.basicInformation.cartKind) {
      this.photoCountElementContainer.classList.add('d-none');
      return;
    }

    this.photoCountElementContainer.classList.remove('d-none');

    const min = (() => {
      if (typeof photoCountRange?.min === 'number') {
        return Math.max(1, photoCountRange.min);
      }
      return 1;
    })();
    const max = (() => {
      if (typeof photoCountRange?.max === 'number') {
        return Math.max(min, photoCountRange?.max);
      }
      return min + 9;
    })();

    for (let i = min; i <= max; i++) {
      const option = document.createElement('option');
      option.value = i.toString();
      option.text = i.toString();
      this.photoCountElement.append(option);
    }
    this.photoCountElement.value = photoCount.toString();
    return photoCount;
  }

  // #endregion 画面表示関連
  // endregion 画面表示関連

  // region 前後の画像に遷移
  // #region 前後の画像に遷移

  private loadNextPrevPhoto(isPrev: boolean) {
    const photoId = this.photo.id;
    return of(undefined).pipe(
        tap(() => this.loading$.next(true)),
        switchMap(() => this.photoAccessor.getPrevNextPhoto(photoId, isPrev)),
        tap(parameter => {
          if (!parameter) {
            return;
          }
          this.parameter = parameter;
          this.photo = parameter.photo;
          this.exhibitionRoom = parameter.exhibitionRoom;
          this.priceRule = parameter.currentRule;
          this.initializeView(parameter);
        }),
        tap(() => this.loading$.next(false))
    );
  }

  private initializeSwipeMove() {
    const rows = document.querySelectorAll('.js-photo-detail-photo-container');
    rows.forEach((row: HTMLElement) => {
      const mc = new Hammer(row, {
        recognizers: [
          [Hammer.Swipe, {enable: true}]
        ]
      });
      mc.on('swipeleft', async () => {
        if (this.scaleValue > 1) {
          if (!this.isLimitLeft) {
            return;
          }
          if (!this.isLimitLeft2) {
            this.isLimitLeft2 = true;
            return;
          }
        }
        await firstValueFrom(this.loadNextPrevPhoto(false));
      });
      mc.on('swiperight', async () => {
        if (this.scaleValue > 1) {
          if (!this.isLimitRight) {
            return;
          }
          if (!this.isLimitRight2) {
            this.isLimitRight2 = true;
            return;
          }
        }
        await firstValueFrom(this.loadNextPrevPhoto(true));
      });
    });

  }

  // #endregion 前後の画像に遷移
  // endregion 前後の画像に遷移

  // region 拡縮
  // #region 拡縮

  /**
   * ピンチズーム関連の初期化
   * @private
   */
  private initializePinchZoom() {
    const photoContainer = querySelector('.js-photo-detail-photo-container', this.rootElement) as HTMLElement;
    const mc = new Hammer(photoContainer, {
      recognizers: [
        [Hammer.Pinch, {enable: true}]
      ]
    });
    // noinspection JSUnusedLocalSymbols
    mc.on('pinchstart', e => {
      // ピンチ開始
      this.initialScaleValue = this.scaleValue;
    });
    // noinspection JSUnusedLocalSymbols
    mc.on('pinchmove', e => {
      if (this.initialScaleValue === null) {
        return;
      }
      const scaleValue = Math.min(6, Math.max(1, e.scale * this.initialScaleValue));
      if (Number.isNaN(scaleValue)) {
        return;
      }
      this.scaleRangeElement.value = scaleValue.toString();
      this.scaleRangeElement.dispatchEvent(new Event('change'));
    });
    // noinspection JSUnusedLocalSymbols
    mc.on('pinchend', e => {
      // ピンチ終了
      this.initialScaleValue = null;
    });
  }

  private handleScaleRangeOnChange() {
    const scaleValue = this.scaleRangeElement.value;
    this.photoImageElement.parentElement.style.transform = `scale(${scaleValue})`;
  }

  private resetImageTransform() {
    this.scaleRangeElement.value = '1';
    this.currentTranslate = null;
    this.startTranslate = null;
    this.photoImageElement.style.transform = `translate(0px, 0px)`;
    this.photoImageElement.parentElement.style.transform = `translate(0px, 0px)`;
  }

  private processDoubleTap(eventKind: string, e: Event) {
    if (!this.shouldUseTouchEvent) {
      if (eventKind === 'touch') {
        this.shouldUseTouchEvent = true;
        this.prevPointerDownTime = new Date();
        return;
      }
    }

    if (this.shouldUseTouchEvent && eventKind !== 'touch') {
      return;
    }

    if (eventKind === 'touch') {
      const touchEvent = e as TouchEvent;
      if (touchEvent.touches?.length > 1) {
        return;
      }
    }

    const now = new Date();
    if (this.prevPointerDownTime) {
      const diff = now.getTime() - this.prevPointerDownTime.getTime();

      if (diff < 320) {
        const nextScale = this.scaleValue + 1;

        const {x, y} = this.currentTranslate ?? {x: 0, y: 0};
        if (eventKind === 'pointer') {
          const pe = e as PointerEvent;
          const clientRect = this.photoContainerElement.getBoundingClientRect();
          const pX = (pe.clientX - clientRect.x) / this.scaleValue;
          const pY = (pe.clientY - clientRect.y) / this.scaleValue;
          const cx = (clientRect.x + clientRect.width) / 2 / this.scaleValue;
          const cy = (clientRect.y + clientRect.height) / 2 / this.scaleValue;

          const diffX = (cx - (pX - x));
          const diffY = (cy - (pY - y));

          const xRange = this.computeTranslateMinMax(this.photoImageElement.width, this.actualImageSize.width, nextScale);
          const yRange = this.computeTranslateMinMax(this.photoImageElement.height, this.actualImageSize.height, nextScale);
          const nextX = Math.min(Math.max(diffX, xRange.min), xRange.max);
          const nextY = Math.min(Math.max(diffY, yRange.min), yRange.max);

          this.currentTranslate = {
            x: nextX, y: nextY
          };

          this.photoImageElement.style.transform = `translate(${nextX}px, ${nextY}px)`;
        } else {
          const te = e as TouchEvent;
          const clientRect = this.photoContainerElement.getBoundingClientRect();
          const pX = (te.touches[0].clientX - clientRect.x) / this.scaleValue;
          const pY = (te.touches[0].clientY - clientRect.y) / this.scaleValue;
          const cx = (clientRect.x + clientRect.width) / 2 / this.scaleValue;
          const cy = (clientRect.y + clientRect.height) / 2 / this.scaleValue;

          const diffX = (cx - (pX - x));
          const diffY = (cy - (pY - y));

          const xRange = this.computeTranslateMinMax(this.photoImageElement.width, this.actualImageSize.width, nextScale);
          const yRange = this.computeTranslateMinMax(this.photoImageElement.height, this.actualImageSize.height, nextScale);
          const nextX = Math.min(Math.max(diffX, xRange.min), xRange.max);
          const nextY = Math.min(Math.max(diffY, yRange.min), yRange.max);

          this.currentTranslate = {
            x: nextX, y: nextY
          };

          this.photoImageElement.style.transform = `translate(${nextX}px, ${nextY}px)`;
        }

        if (nextScale > 6) {
          this.resetImageTransform();
        } else {
          this.scaleRangeElement.value = nextScale.toString();
        }
        this.scaleRangeElement.dispatchEvent(new Event('change'));
        return true;
      }
    }
    this.prevPointerDownTime = now;
    return false;
  }

  // #endregion 拡縮
  // endregion 拡縮

  // region サイズ変更
  // #region サイズ変更

  private initializeSizeSelect() {
    fromEvent(this.photoSizeElement, 'change')
        .pipe(
            tap(() => this.handleSizeOnChange())
        )
        .subscribe({
          error: console.error
        });
  }

  private handleSizeOnChange() {
    const size = parseInt(this.photoSizeElement.value);
    if (Number.isNaN(size)) {
      console.warn('写真詳細サイズ変更:サイズを数値として認識できません');
      return;
    }

    const priceRule = (() => {
      if (isFileNumberPriceRule(this.priceRule)) {
        return this.priceRule;
      }
      if (this.exhibitionRoom.priceSetting?.flatPriceRules?.length) {
        return this.exhibitionRoom.priceSetting?.flatPriceRules.filter(x => x.size === size)[0];
      }
    })();

    if (!priceRule) {
      console.warn('写真詳細サイズ変更:価格ルールが見つかりません');
      return;
    }

    this.priceRule = priceRule;
    const photoCountRange = this.photoAccessor.getPhotoCountRange(priceRule, size);
    const countRange = {
      min: photoCountRange?.minOrderCount,
      max: photoCountRange?.maxOrderCount
    };

    const count = this.initializeCount(countRange.min ?? 1, countRange);
    this.initializePrice(size, count);

    const photoIsInCart = CartFacade.doesPhotoExistInCart(this.photo.photoId);
    this.initializeAddedBanner(photoIsInCart);
    this.initializeAddToCartButton(photoIsInCart);
  }

  // #endregion サイズ変更
  // endregion サイズ変更

  // region 枚数変更
  // #region 枚数変更

  private initializeCountSelect() {
    fromEvent(this.photoCountElement, 'change')
        .pipe(
            tap(() => this.handleCountOnChange())
        )
        .subscribe({
          error: console.error
        });
  }

  private handleCountOnChange() {
    const count = parseInt(this.photoCountElement.value);
    if (Number.isNaN(count)) {
      console.warn('写真詳細枚数変更:枚数を数値として認識できません');
      return;
    }

    const size = parseInt(this.photoSizeElement.value);
    if (Number.isNaN(size)) {
      console.warn('写真詳細枚数変更:サイズを数値として認識できません');
      return;
    }

    this.initializePrice(size, count);
  }

  // #endregion 枚数変更
  // endregion 枚数変更

  // region カートに追加
  // #region カートに追加

  private addToCart() {
    const addToCartParam = this.getAddToCartParameter();
    if (!addToCartParam) {
      return of(undefined);
    }

    return CartFacade.addToCart(addToCartParam);
  }

  private removeFromCart() {
    const addToCartParam = this.getAddToCartParameter();
    if (!addToCartParam) {
      return of(undefined);
    }

    return CartFacade.removeAllSizeFromCart(addToCartParam.photoId);
  }

  private getAddToCartParameter(): AddToCartParam | undefined {
    const count = parseInt(this.photoCountElement.value, 10);
    if (Number.isNaN(count)) {
      console.warn('カートに追加:数量が取得できませんでした。');
      return undefined;
    }

    const photoSizeNumber = parseInt(this.photoSizeElement.value, 10);
    if (Number.isNaN(photoSizeNumber)) {
      console.warn('カートに追加:サイズが取得できませんでした。');
      return undefined;
    }
    const photoSize = photoSizeIndexToName[photoSizeNumber];

    return {
      photoId: this.photo.id,
      photoSize,
      count
    } as AddToCartParam;
  }

  // #endregion カートに追加
  // endregion カートに追加

  private updateActualImageSize() {
    const elementWidth = this.photoImageElement.offsetWidth;
    const elementHeight = this.photoImageElement.offsetHeight;
    if (elementWidth <= 0 || elementHeight <= 0) {
      this.actualImageSize = undefined;
      return;
    }

    if (!this.currentImageSize) {
      this.actualImageSize = undefined;
      return;
    }

    const elementRatio = elementHeight / elementWidth;

    const naturalRatio = this.currentImageSize.height / this.currentImageSize.width;

    if (elementRatio >= naturalRatio ) {
      this.actualImageSize = {
        width: elementWidth,
        height: elementWidth * naturalRatio
      };
    } else {
      this.actualImageSize = {
        width: elementHeight / naturalRatio,
        height: elementHeight
      };
    }
  }

  private computeTranslateMinMax(elementSize: number, actualSize: number, scaleValue?: number) {
    const sv = (typeof scaleValue) === 'undefined' ? this.scaleValue : scaleValue;
    const marginX = -(elementSize - actualSize * sv) / 2;
    const max = (marginX / sv) * (marginX > 0 ? 1 : -2);
    const min = -max;
    return {
      min, max
    };
  }

  private clearMoveLimits() {
    this.isLimitLeft = false;
    this.isLimitLeft2 = false;
    this.isLimitRight = false;
    this.isLimitRight2 = false;
  }
}

document.addEventListener('DOMContentLoaded', () => {
});
