import {
  BehaviorSubject,
  filter,
  from,
  fromEvent,
  map,
  Observable,
  of,
  switchMap,
  tap
} from 'rxjs';
import {
  Cart,
  ExhibitionRoom,
  FileNumberPriceRule,
  FlatPriceRule, getPriceFactor,
  isFileNumberPriceRule,
  Photo, PhotoCountRange
} from '../models';
import {
  closestClass,
  getClient,
  querySelector,
  querySelectorAll
} from '../../utilities';
import {
  IPhotoAccessor,
  PhotoDetailDialog,
  PhotoDetailParameter
} from './photo-detail-dialog';
import {AddToCartParam, CartFacade} from '../cart';
import {createPhotoUrl} from '../common';
import {photoSizeIndexToName, photoSizeToDisplayTexts} from '../../models';
import {formatPrice} from '../../common';

/**
 * 展示室一覧URL。
 */
const exhibitionRoomsUrl = '/customer/api/photoQuery/exhibitionRooms';


export interface PhotoQueryParameterBase {
  pageIndex: number;
  pageSize: number;
}

/**
 * 展示室で検索用パラメータ。
 */
export interface PhotoQueryParameterByExhibitionRoom {
  secondaryCategoryIds: string[];
  tertiaryCategoryIds: string[];
}

/**
 * 写真検索パラメータ
 */
export interface PhotoQueryParameter extends PhotoQueryParameterBase,
  PhotoQueryParameterByExhibitionRoom {
}


/**
 * 写真検索結果。
 */
export interface PhotoQueryResult {
  hasLastPage: boolean;
  items: Photo[];
  lastPage: number;
  objectItems: number;
  pageIndex: number,
  pageSize: number,
  total: number
}

/**
 * 写真一覧ページのデータ。
 */
export interface PageData {
  queryResult: PhotoQueryResult,
  exhibitionRooms: ExhibitionRoom[],
}

// region 定数
// #region 定数

const priceRuleKindFlatPrice = 1;
const priceRuleKindByFileNumber = 2;

// #endregion 定数
// endregion 定数


/**
 * 写真一覧画面の基底クラス。
 */
export abstract class PhotosIndexBase implements IPhotoAccessor {
  /**
   * ページデータ。
   * @private
   */
  protected pageData$ = new BehaviorSubject<PageData | null>(null);

  /**
   * ユーザーID。
   * @private
   */
  private readonly userId: string | null;

  protected get currentUserId() {
    return this.userId;
  }

  /**
   * 写真詳細ダイアログ。
   * @private
   */
  private photoDetailDialog: PhotoDetailDialog;

  /**
   * セッションID。
   * @private
   */
  private readonly sessionId: string | null;

  constructor() {
    this.userId = (querySelector('#user-id') as HTMLInputElement).value;
    this.sessionId = (querySelector('#session-id') as HTMLInputElement).value;
    this.photoDetailDialog = new PhotoDetailDialog(
        this,
        this.userId,
        this.sessionId);
  }

  // region 初期化
  // #region 初期化

  protected initialize() {

    CartFacade.cart$.pipe(
        tap(() => {
          if (!this.photoDetailDialog || !this.photoDetailDialog.currentPhotoId) {
            return;
          }
          const parameter = this.loadPhotoDetailParameter(this.photoDetailDialog.currentPhotoId);
          this.photoDetailDialog.updateParameter(parameter);
        })
    ).subscribe();

    // サイズ変更ボタン。
    fromEvent(document, 'input').pipe(
        filter(x => {
          if (!x || !((x.target as HTMLElement).matches)) {
            return false;
          }
          return (x.target as HTMLElement).matches('.photo-size select.photo-size');
        }),
        tap(x => this.handleSizeOnChange(x))
    ).subscribe();

    // 枚数変更ボタン。
    fromEvent(document, 'input').pipe(
        filter(x => {
          if (!x || !((x.target as HTMLElement).matches)) {
            return false;
          }
          return (x.target as HTMLElement).matches('.photo-count select.photo-count');
        }),
        tap(x => this.handleCountOnChange(x))
    ).subscribe();

    // 詳細ページ表示ボタン
    fromEvent(document, 'click').pipe(
        filter(e => {
          const target = e?.target as HTMLElement;
          const showPhotoDetailButtonSelector
          = '.page.photo-list-js .show-photo-detail-button'
          + ', .page.photo-list-js .show-photo-detail-button *';

          return target?.matches(showPhotoDetailButtonSelector);
        }),
        tap(e => this.handleShowPhotoDetailButtonOnClick(e))
    ).subscribe({
      error: err => console.error(err)
    });

    // カートに追加ボタン。
    fromEvent(document, 'click').pipe(
        filter(x => {
          if (!x || !((x.target as HTMLElement).matches)) {
            return false;
          }
          return (x.target as HTMLElement).matches('.add-to-cart .add-to-cart-button');
        }),
        switchMap(x => PhotosIndexBase.addToCartForButton(x))
    ).subscribe({
      error: err => {
        // TODO: エラーメッセージ表示。
        alert('カートに追加できませんでした。');
        console.error(err);
      }
    });

    // カートから削除ボタン
    fromEvent(document, 'click').pipe(
        filter(x => {
          if (!x || !((x.target as HTMLElement).matches)) {
            return false;
          }
          return (x.target as HTMLElement).matches('.js-photo-list-delete-photo-button, js-photo-list-delete-photo-button *');
        }),
        switchMap(x => PhotosIndexBase.removeFromCart(x))
    ).subscribe({
      error: err => {
        console.error(err);
      }
    });

    // ブラウザバックと進む時に検索結果更新
    fromEvent(window, 'popstate')
        .pipe(
            tap(() => this.initializeQueryParameter())
        )
        .subscribe();

    // カート変更
    CartFacade.cart$.pipe(
        tap(cart => {
          this.changeDeleteButtonVisibility(cart);
        }),
        tap(cart => {
          const selector = '.page .photo-list .photo-list-item';
          querySelectorAll(selector).forEach(x => {
            const li = x as HTMLElement;
            const img = querySelector('.added-to-cart-banner', li) as HTMLElement;
            if (!img) {
              return;
            }

            const photoId = li.dataset.photoId;
            if (!cart) {
              img.classList.add('d-none');
              return;
            }
            if (cart.cartItems.some(x => x.photoId === photoId)) {
              img.classList.remove('d-none');
            } else {
              img.classList.add('d-none');
            }
          });
        })
    ).subscribe();
  }

  public changeDeleteButtonVisibility(cart: Cart) {
    const items = querySelectorAll('.page .photo-list .js-photo-list-delete-photo-button');
    items.forEach(x => {
      const element = x as HTMLElement;
      const photoListItem = element.closest('.photo-list-item') as HTMLElement;
      const photoId = photoListItem.dataset.photoId;
      const isInCart = cart.cartItems.some(x => x.photoId === photoId);
      if (isInCart) {
        element.classList.remove('d-none');
      } else {

        element.classList.add('d-none');
      }
    });
  }

  // #endregion 初期化
  // endregion 初期化

  // region IPhotoAccessor
  // #region IPhotoAccessor

  public getPhotoCountRange(
      priceRule: FlatPriceRule | FileNumberPriceRule,
      size?: number): PhotoCountRange | undefined {
    if (isFileNumberPriceRule(priceRule)) {
      if (!priceRule.sizePrices.length) {
        return undefined;
      }
      if (typeof size === 'number') {
        const result = priceRule.sizePrices.filter(x => x.size === size)[0];
        if (result) {
          return result;
        }
      }
      return priceRule.sizePrices.sort((a, b) => {
        return a.size - b.size;
      })[0];
    } else if (priceRule) {
      return priceRule;
    }
  }

  abstract getPrevNextPhoto(
    photoId: string,
    isPrev: boolean): Observable<PhotoDetailParameter | undefined>;

  // #endregion IPhotoAccessor
  // endregion IPhotoAccessor

  // region 展示室のロード
  // #region 展示室のロード

  protected loadQueryParameterBaseFromUrl(urlParams: URLSearchParams): PhotoQueryParameterBase {
    const result = {
      pageIndex: 0,
      pageSize: 100
    } as PhotoQueryParameterBase;

    if (urlParams.has('pageIndex')) {
      const pageIndex = parseInt(urlParams.get('pageIndex'));
      if (!Number.isNaN(pageIndex) && pageIndex >= 0) {
        result.pageIndex = pageIndex;
      }
    }

    if (urlParams.has('pageSize')) {
      const pageSize = parseInt(urlParams.get('pageSize'));
      if (!Number.isNaN(pageSize) && pageSize > 0 && pageSize <= 100) {
        result.pageSize = pageSize;
      }
    }
    return result;
  }

  /**
   * 現在の利用者用の展示室一覧をロードする。
   * @returns {Observable<ExhibitionRoom[]>} 展示室一覧のObservable
   */
  protected loadExhibitionRoomsForCustomer(): Observable<ExhibitionRoom[]> {
    return from(getClient().get(exhibitionRoomsUrl)).pipe(
        map(x => x.data)
    );
  }

  // #endregion 展示室のロード
  // endregion 展示室のロード

  // region 検索関連
  // #region 検索関連

  protected abstract initializeQueryParameter(): void;

  // #endregion 検索関連
  // endregion 検索関連

  // region 画面からデータを取得する
  // #region 画面からデータを取得する

  /**
   * `element`を囲む要素のうち最も近くにある`.photo-list-item`を取得する。
   * @param element `.photo-slit-item`の子孫要素。
   * @returns `element`を囲む要素のうち最も近くにある`.photo-list-item`。
   * @private
   */
  private static getClosestPhotoListItem(element: HTMLElement): HTMLElement | false {
    return closestClass(element, 'photo-list-item');
  }

  // #endregion 画面からデータを取得する
  // endregion 画面からデータを取得する

  // region 画面更新系
  // #region 画面更新系

  protected updatePhotoList(pageData: PageData) {
    const photoListElement = querySelector('ul.photo-list') as HTMLElement;
    if (!photoListElement) {
      return;
    }

    querySelectorAll(':scope > *', photoListElement).forEach(x => x.remove());
    pageData.queryResult.items.forEach(photo => {
      const photoLiteItem = this.createPhotoListItem(photo, pageData);
      if (!photoLiteItem) {
        return;
      }
      photoListElement.append(photoLiteItem);
    });

    PhotosIndexBase.updatePagination(pageData);
  }

  private static updatePagination(pageData: PageData) {
    const queryResult = pageData.queryResult;

    PhotosIndexBase.setCurrentPage(queryResult);

    const paginationElement = querySelector('.photo-list-container .pagination') as HTMLElement;
    if (!paginationElement) {
      console.warn('ページネーション更新:ページネーション要素が見つかりません。');
      return;
    }

    const leftElement = querySelector('.page-link.left', paginationElement) as HTMLElement;

    // 一番左の←
    if (queryResult.pageIndex > 0) {
      leftElement?.parentElement.classList.remove('disabled');
    } else {
      leftElement?.parentElement.classList.add('disabled');
    }
    if (leftElement) {
      leftElement.dataset.pageIndex = Math.max(0, queryResult.pageIndex - 1).toString();
    }

    const pageSelect = querySelector('.pagination-page-select') as HTMLSelectElement;
    querySelectorAll(':scope > *', pageSelect).forEach(x => x.remove());
    for (let i = 0; i <= queryResult.lastPage; i++) {
      const option = document.createElement('option');
      option.value = i.toString();
      option.text = (i + 1).toString();
      option.selected = queryResult.pageIndex === i;
      pageSelect.add(option);
    }

    // 一番右の→
    const rightElement = querySelector('.page-link.right', paginationElement) as HTMLElement;
    if (queryResult.pageIndex < queryResult.lastPage) {
      rightElement?.parentElement.classList.remove('disabled');
    } else {
      rightElement?.parentElement.classList.add('disabled');
    }
    if (rightElement) {
      rightElement.dataset.pageIndex = (queryResult.pageIndex + 1).toString();
    }
  }

  /**
   * ページネーションに表示するページのインデックス列を算出する。
   * 全部で最大5個数字を含むようにする。
   * @param queryResult クエリ結果。
   * @returns ページインデックスの一覧。
   * @private
   */
  private static computePageIndices(queryResult: PhotoQueryResult): number[] {
    const pageIndices = [queryResult.pageIndex];
    let offset = 1;
    while (pageIndices.length < 5) {
      const left = queryResult.pageIndex - offset;
      const hasLeft = left >= 0;
      if (hasLeft) {
        pageIndices.unshift(left);
      }
      const right = queryResult.pageIndex + offset;
      const hasRight = right <= queryResult.lastPage;
      if (hasRight) {
        pageIndices.push(right);
      }
      offset += 1;
      if (!hasLeft && !hasRight) {
        break;
      }
    }
    return pageIndices;
  }

  private static setCurrentPage(queryResult: PhotoQueryResult) {
    const rootElement = querySelector('.customer-list-pagination-wrapper .current-page');
    const currentPageElement = rootElement as HTMLElement;
    if (!currentPageElement) {
      console.warn('ページネーション更新:現在のページ表示用の要素が見つかりません。');
      return;
    }

    if (!queryResult.items || !queryResult.items.length) {
      currentPageElement.textContent = 'データが1件も見つかりませんでした';
      return;
    }

    const firstItemIndex = queryResult.pageIndex * queryResult.pageSize;
    const lastItemIndex = firstItemIndex + queryResult.items.length;
    currentPageElement.textContent
      = `${firstItemIndex + 1}-${lastItemIndex}件`
      + `/${queryResult.total}件中`;
  }

  private createPhotoListItem(photo: Photo, pageData: PageData): HTMLElement | undefined {
    const template = querySelector('#photo-list-item-template > li');
    if (!template) {
      return;
    }

    const li = template.cloneNode(true) as HTMLElement;
    li.dataset.photoId = photo.id;
    li.id = `photo-list-item-${photo.id}`;

    this.insertPhotoImages(li, photo);
    this.setPhotoProperties(li, photo, pageData);
    return li;
  }

  /**
   * 今回追加するリスト項目要素(`li`)の写真関連プロパティをセットする。
   * @param li 今回追加するリスト項目。
   * @param photo 写真。
   * @param pageData ページ全体のデータ。
   *
   * @private
   */
  private setPhotoProperties(li: HTMLElement, photo: Photo, pageData: PageData) {
    const photoProperties = querySelector('.photo-properties', li) as HTMLElement;

    this.setFileNumber(photoProperties, photo);

    const exhibitionRoom = pageData.exhibitionRooms.filter(x => x.id === photo.saleEventId)[0];
    const priceRule = this.findPriceRule(exhibitionRoom, photo);

    const count = this.setPhotoCount(photoProperties, priceRule);
    this.setPhotoSizes(photoProperties, exhibitionRoom, priceRule);

    const price = this.findPrice(exhibitionRoom, photo);
    this.setPrice(photoProperties, price, count);

    PhotosIndexBase.setCartVisibility(photoProperties, exhibitionRoom);
  }

  private static setCartVisibility(
      parent: HTMLElement,
      exhibitionRoom: ExhibitionRoom) {
    if (!parent) {
      return;
    }

    const element = querySelector(':scope .add-to-cart', parent) as HTMLElement;
    if (!element) {
      return;
    }

    if (exhibitionRoom.basicInformation.cartKind === 0) {
      element.classList.add('d-none');
      return;
    }
    element.classList.remove('d-none');

  }

  private setPhotoSizes(
      parent: HTMLElement,
      exhibitionRoom: ExhibitionRoom,
      priceRule: FlatPriceRule | FileNumberPriceRule): number | undefined {
    if (!parent) {
      return;
    }

    const photoSizeElement = querySelector(':scope  .property-row > .photo-size', parent);
    if (!photoSizeElement) {
      return;
    }

    const selectElement = querySelector(':scope select.photo-size', photoSizeElement);
    if (selectElement) {
      querySelectorAll(':scope > *', selectElement).forEach(x => x.remove());
    }

    if (!exhibitionRoom || !priceRule) {
      photoSizeElement.classList.add('d-none');
      return;
    }

    photoSizeElement.classList.remove('d-none');
    const addOption = (sorted: any[]) => {
      sorted.forEach(x => {
        const option = document.createElement('option');
        option.value = (x as any).size.toString();
        option.textContent = this.photoSizeToText((x as any).size);
        selectElement.append(option);
      });
    };

    if (isFileNumberPriceRule(priceRule)) {
      const sorted = priceRule.sizePrices.sort((a, b) => a.size - b.size);
      addOption(sorted);
      return sorted[0]?.size;
    } else {
      const sorted = exhibitionRoom.priceSetting.flatPriceRules.sort((a, b) => a.size - b.size);
      addOption(sorted);
      return sorted[0]?.size;
    }
  }

  private setPhotoCount(
      parent: HTMLElement,
      priceRule: FlatPriceRule | FileNumberPriceRule,
      size?: number): number | undefined {
    const element = querySelector(':scope .property-row > .photo-count', parent) as HTMLElement;
    if (!element) {
      console.warn('写真枚数変更:対象要素が見つからない');
      return;
    }

    const selectElement = querySelector('select.photo-count', element);
    if (!selectElement) {
      console.warn('写真枚数変更:枚数selectが見つからない');
      return;
    }

    querySelectorAll(':scope > *', selectElement).forEach(x => x.remove());

    const photoCountRange = this.getPhotoCountRange(priceRule, size);
    if (!photoCountRange) {
      element.classList.add('d-none');
      return;
    }

    element.classList.remove('d-none');
    const getNumberOrDefault = (x: any, defaultValue: number): number => {
      if (!x) {
        return defaultValue;
      }
      if (typeof x !== 'number') {
        return defaultValue;
      }
      if (Number.isNaN(x)) {
        return defaultValue;
      }
      return x;
    };
    const min = getNumberOrDefault((photoCountRange as any).minOrderCount, 1);
    const max = getNumberOrDefault((photoCountRange as any).maxOrderCount, min + 9);

    for (let i = min; i <= max; i++) {
      const option = document.createElement('option');
      option.value = i.toString();
      option.textContent = i.toString();
      selectElement.append(option);
    }
    return min;
  }


  private setPrice(
      photoProperties: HTMLElement,
      price: number,
      count?: number) {

    const actualCount = count ?? (() => {
      const countSelect = querySelector('.photo-count select.photo-count') as HTMLSelectElement;
      if (!countSelect) {
        console.warn('写真価格表示:写真枚数セレクトが見つかりません');
        return 1;
      }
      const count = parseInt(countSelect.value);
      if (Number.isNaN(count)) {
        console.warn('写真価格表示:写真枚数を数値に変換できません');
        return 1;
      }
      return count;
    })();

    console.log('現在の枚数 外部指定:', count, '最終', actualCount);

    querySelectorAll(':scope .photo-price .price', photoProperties).forEach(x => {
      const valueElement = x as HTMLElement;
      const subtotal = price * actualCount;
      if (subtotal) {
        valueElement.parentElement.classList.remove('d-none');
        valueElement.textContent = formatPrice(subtotal) + '円';
      } else {
        valueElement.parentElement.classList.add('d-none');
      }
    });
  }

  private setFileNumber(photoProperties: HTMLElement, photo: Photo) {
    querySelectorAll(':scope .photo-name .value', photoProperties).forEach(x => {
      const valueElement = x as HTMLElement;
      valueElement.textContent = photo.fileNumber.toString();
    });
  }

  /**
   * 今回追加するリスト項目要素(`li`)に写真<img>を追加する。
   * @param li リスト項目。
   * @param photo 写真。
   * @private
   */
  private insertPhotoImages(li: HTMLElement, photo: Photo) {
    querySelectorAll('.photo-image-placeholder', li).forEach(x => {
      this.insertPhotoImg(x, photo);
    });
  }

  private insertPhotoImg(placeholderElement: Element, photo: Photo) {
    const placeholder = placeholderElement as HTMLElement;

    const img = document.createElement('img');
    img.src = createPhotoUrl(this.userId, this.sessionId, photo);
    img.alt = `思い出の写真 - ${photo.fileNumber}`;

    placeholder.parentElement.insertBefore(img, placeholder);

    const cartAdded = document.createElement('img');
    cartAdded.src = '/img/icons/cart-added.svg';
    cartAdded.alt = 'カートに追加済み';
    cartAdded.classList.add('added-to-cart-banner');

    const photoSize = this.getPhotoSizeValue(photo.id);
    const isInCart = CartFacade.doesPhotoExistInCart(photo.id, photoSize);
    if (!isInCart) {
      cartAdded.classList.add('d-none');
    }

    placeholder.parentElement.insertBefore(cartAdded, placeholder);

    const deleteButton = placeholderElement.closest('.photo-list-item').querySelector('.js-photo-list-delete-photo-button');
    if (isInCart) {
      deleteButton.classList.remove('d-none');
    } else {
      deleteButton.classList.add('d-none');
    }

    placeholder.remove();
  }

  // #endregion 画面更新系
  // endregion 画面更新系


  // region 写真詳細画面
  // #region 写真詳細画面

  protected loadPhotoDetailParameter(photoId: string) {
    if (!photoId) {
      console.warn('写真詳細表示:対象の写真IDが取得できません');
      return;
    }

    const li = querySelector(`.photo-list-item[data-photo-id="${photoId}"]`) as HTMLElement;
    if (!li) {
      console.warn('写真詳細表示:写真項目を取得できません。');
      return;
    }

    const pageData = this.pageData$.getValue();
    const photo = pageData.queryResult.items.filter(x => x.photoId === photoId)[0];
    if (!photo) {
      console.warn('写真詳細表示:対象の写真データが見つかりません');
      return;
    }
    const exhibitionRoom = pageData.exhibitionRooms.filter(x => x.id === photo.saleEventId)[0];
    if (!exhibitionRoom) {
      console.warn('写真詳細表示:対象の写真データに紐付く展示室が見つかりません');
      return;
    }

    const photoSize = this.getPhotoSizeValue(photoId);
    const photoCount = parseInt(
        (querySelector('select.photo-count', li) as HTMLSelectElement)?.value);

    const priceRule = this.findPriceRule(exhibitionRoom, photo, photoSize);

    const rangeByRule = this.getPhotoCountRange(priceRule, photoSize);
    const photoCountRange = {
      min: rangeByRule?.minOrderCount,
      max: rangeByRule?.maxOrderCount
    };

    return {
      photo,
      exhibitionRoom,
      currentRule: priceRule,
      photoSize,
      photoCount,
      photoCountRange,
      isInCart: CartFacade.doesPhotoExistInCart(photo.id)
    } as PhotoDetailParameter;
  }

  private getPhotoSizeValue(photoId: string): number | undefined {
    const li = querySelector(`.photo-list-item[data-photo-id="${photoId}"]`) as HTMLElement;
    if (!li) {
      return;
    }

    return parseInt(
        (querySelector('select.photo-size', li) as HTMLSelectElement)?.value);
  }

  private handleShowPhotoDetailButtonOnClick(e: Event) {
    const target = e.target as HTMLElement;
    const li = closestClass(target, 'photo-list-item') as HTMLElement;
    const photoId = li.dataset.photoId;

    const photoDetailParameter = this.loadPhotoDetailParameter(photoId);
    if (!photoDetailParameter) {
      return;
    }

    this.photoDetailDialog.showPhotoDetailDialog(photoDetailParameter);
  }

  // #endregion 写真詳細画面
  // endregion 写真詳細画面

  // region 価格ルール
  // #region 価格ルール

  private findPrice(
      exhibitionRoom: undefined | null | ExhibitionRoom,
      photo: Photo,
      size?: number): undefined | number {

    const factor = getPriceFactor(exhibitionRoom);

    const getPrice = () => {
      const rule = this.findPriceRule(exhibitionRoom, photo, size);
      if (!rule) {
        return;
      }
      if (isFileNumberPriceRule(rule)) {
        if (!rule.sizePrices || !rule.sizePrices.length) {
          return;
        }
        const sizePrice = rule.sizePrices.filter(x => x.size === size)[0];
        return sizePrice ? sizePrice.price : rule.sizePrices[0].price;
      }
      return rule.price;
    };

    const price = getPrice();
    if (price) {
      return Math.floor(price * factor);
    }
  }

  private findPriceRule(
      exhibitionRoom: undefined | null | ExhibitionRoom,
      photo: Photo,
      size?: number): undefined | FlatPriceRule | FileNumberPriceRule {
    if (!exhibitionRoom) {
      return;
    }
    if (exhibitionRoom.priceSetting.priceRuleKind === priceRuleKindFlatPrice) {
      return this.findPriceForFlatPriceRule(exhibitionRoom.priceSetting.flatPriceRules, size);
    } else if (exhibitionRoom.priceSetting.priceRuleKind === priceRuleKindByFileNumber) {
      return this.findPriceRuleForFileNumber(
          exhibitionRoom.priceSetting.fileNumberPriceRules, photo);
    }
  }

  private findPriceForFlatPriceRule(
      rules: FlatPriceRule[],
      size?: number): undefined | FlatPriceRule {

    if (!rules || !rules.length) {
      console.warn('一律価格検索:価格設定が存在しません。');
      return;
    }

    rules = rules.sort((a, b) => a.size - b.size);
    const rule = rules.filter(x => x.size === size)[0];
    if (rule) {
      return rule;
    }

    return rules[0];
  }

  private findPriceRuleForFileNumber(
      rules: FileNumberPriceRule[], photo: Photo): undefined | FileNumberPriceRule {
    const sorted = rules.sort((a, b) => {
      if (a.firstFileNumber < b.firstFileNumber) {
        return -1;
      }
      if (a.firstFileNumber === b.firstFileNumber) {
        return 0;
      }
      return 1;
    }).reverse();

    const rule = sorted.reduce((r, c) => {
      if (r) {
        return r;
      }
      if (c.firstFileNumber <= photo.fileNumber) {
        return c;
      }
      return undefined;
    }, undefined);


    if (!rule || !rule.sizePrices || !rule.sizePrices.length) {
      return;
    }

    return rule;
  }

  // #endregion 価格ルール
  // endregion 価格ルール

  // region カートに追加
  // #region カートに追加

  private static addToCartForButton(x: Event) {
    const element = x.target as HTMLElement;
    if (!element) {
      return of(undefined);
    }

    const photoListItem = PhotosIndexBase.getClosestPhotoListItem(element);
    if (!photoListItem) {
      return of(undefined);
    }

    const addToCartParam = PhotosIndexBase.getAddToCartParam(photoListItem);
    if (!addToCartParam) {
      return of(undefined);
    }
    return CartFacade.addToCart(addToCartParam);
  }

  private static getAddToCartParam(photoListItem: HTMLElement): AddToCartParam | undefined {
    const photoId = photoListItem.dataset.photoId;
    if (!photoId) {
      console.error('写真IDが取得できません。');
      return;
    }

    const photoSizeSelect = querySelector('select.photo-size', photoListItem) as HTMLSelectElement;
    if (!photoSizeSelect) {
      console.error('写真サイズが見つかりません。');
      return;
    }
    const photoSizeNumber = parseInt(photoSizeSelect.value, 10);
    if (Number.isNaN(photoSizeNumber)
      || photoSizeNumber < 0
      || photoSizeNumber >= photoSizeIndexToName.length) {
      console.error('写真サイズが取得できません。');
      return;
    }
    const photoSize = photoSizeIndexToName[photoSizeNumber];


    const countSelect = querySelector('select.photo-count', photoListItem) as HTMLSelectElement;
    if (!countSelect) {
      console.error('枚数が見つかりません。');
      return;
    }
    const count = parseInt(countSelect.value, 10);
    if (Number.isNaN(count)) {
      console.error('枚数が取得できません。');
      return;
    }

    return {
      photoId,
      photoSize,
      count
    };
  }

  private static removeFromCart(x: Event) {
    const element = x.target as HTMLElement;
    if (!element) {
      return of(undefined);
    }

    const photoListItem = PhotosIndexBase.getClosestPhotoListItem(element);
    if (!photoListItem) {
      return of(undefined);
    }

    const addToCartParam = PhotosIndexBase.getAddToCartParam(photoListItem);
    if (!addToCartParam) {
      return of(undefined);
    }
    return CartFacade.removeAllSizeFromCart(addToCartParam.photoId);
  }

  // #endregion カートに追加
  // endregion カートに追加

  // region 写真サイズ
  // #region 写真サイズ

  photoSizeToText(size: number) {
    if (typeof size !== 'number') {
      return '';
    }
    if (size >= 0 && size < photoSizeToDisplayTexts.length) {
      return photoSizeToDisplayTexts[size];
    }
    return '';
  }

  private handleSizeOnChange(e: Event) {
    const element = e.target as HTMLSelectElement;
    if (!element || !element.value) {
      return;
    }

    const size = parseInt(element.value);
    if (Number.isNaN(size)) {
      console.error('サイズ変更:サイズの値が不正です。', element.value);
      return;
    }

    const pageData = this.pageData$.getValue();
    if (!pageData) {
      console.error('サイズ変更:ページデータが存在しません。');
      return;
    }

    const photoListItem = PhotosIndexBase.getClosestPhotoListItem(element);
    if (!photoListItem) {
      console.error('サイズ変更:写真リスト項目が見つかりません。');
      return;
    }

    const photoId = photoListItem.dataset.photoId;
    const photo = pageData.queryResult.items.filter(x => x.photoId === photoId)[0];
    if (!photo) {
      console.error('サイズ変更:写真が見つかりません。');
      return;
    }

    const exhibitionRoom = pageData.exhibitionRooms.filter(x => x.id === photo.saleEventId)[0];
    if (!exhibitionRoom) {
      console.error('サイズ変更:展示室が見つかりません。');
      return;
    }


    const rule = this.findPriceRule(exhibitionRoom, photo, size);
    let count = undefined;
    if (rule) {
      count = this.setPhotoCount(photoListItem, rule, size);
    } else {
      console.warn('サイズ変更:価格ルールが見つかりません。');
    }

    const price = this.findPrice(exhibitionRoom, photo, size);
    this.setPrice(photoListItem, price, count);
  }


  // #endregion 写真サイズ
  // endregion 写真サイズ

  // region 写真枚数
  // #region 写真枚数

  private handleCountOnChange(e: Event) {
    console.log('サイズ変更!', e.target);
    const element = e.target as HTMLSelectElement;
    const li = PhotosIndexBase.getClosestPhotoListItem(element);
    if (!li) {
      console.warn('写真枚数変更:写真コンテナが見つかりません');
      return;
    }

    const count = parseInt(element.value);
    if (Number.isNaN(count)) {
      console.warn('写真枚数変更:枚数を取得できません。');
      return;
    }

    const photoId = li.dataset.photoId;
    const pageData = this.pageData$.getValue();

    const photo = pageData.queryResult.items.filter(x => x.id === photoId)[0];
    if (!photo) {
      console.warn('写真枚数変更:写真が見つかりません');
      return;
    }

    const exhibitionRoom = pageData.exhibitionRooms.filter(x => x.id === photo.saleEventId)[0];

    const sizeSelect = querySelector('.photo-size select.photo-size', li) as HTMLSelectElement;
    if (!sizeSelect) {
      console.warn('写真枚数変更:サイズ選択が見つかりません。');
      return;
    }
    console.log('現在のサイズ:', sizeSelect.value, sizeSelect);
    const size = parseInt(sizeSelect.value);
    if (Number.isNaN(size)) {
      console.warn('写真枚数変更:サイズを取得できません。');
      return;
    }

    const price = this.findPrice(exhibitionRoom, photo, size);
    console.log('現在の単価:', price);

    const photoProperties = querySelector('.photo-properties', li) as HTMLElement;
    if (!photoProperties) {
      console.warn('写真枚数変更:写真プロパティコンテナが見つかりません。');
      return;
    }

    this.setPrice(photoProperties, price, count);
  }

  // #endregion 写真枚数
  // endregion 写真枚数

}

