document.addEventListener('DOMContentLoaded', () => {
  const button = document.querySelector<HTMLButtonElement>('.js-place-order-button');
  if (button == null) {
    return;
  }

  const form = button.closest<HTMLFormElement>('form');
  if (form == null) {
    console.error('注文確定フォームが見つかりません');
    return;
  }

  form.addEventListener('submit', () => {
    button.disabled = true;
  });
});
