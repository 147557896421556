import {fromEvent, tap} from 'rxjs';

import {Point} from '../common/Point';
import {Size} from '../common/Size';

export class CartItemScale {

  private containerElement: HTMLElement;
  private wrapperElement: HTMLElement;
  private photoElement: HTMLImageElement;
  private scaleElement: HTMLInputElement;
  private resetButtonElement: HTMLButtonElement;

  private pointerDowned = false;
  private dragging = false;
  private dragStartPoint: Point = null;
  private startTranslate: Point = null;
  private currentTranslate: Point = null;
  private currentImageSize: Size = null;
  private actualImageSize: Size = null;

  private initialScaleValue: number = null;
  private shouldUseTouchEvent = false;
  private prevPointerDownTime: Date | null = null;
  private loadNextPrevPhoto: (isNext: boolean) => void;
  private photoElementHammer: any;
  private isLimitLeft = false;
  private isLimitLeft2 = false;
  private isLimitRight = false;
  private isLimitRight2 = false;


  private get scaleValue() {
    const result = parseFloat(this.scaleElement.value);
    if (Number.isNaN(result)) {
      return 1;
    }
    return result;
  }


  bindToDom(moveViewDta: (isNext: boolean) => void) {
    this.loadNextPrevPhoto = moveViewDta;

    this.containerElement = document.querySelector('.js-cart-detail-photo-container') as HTMLElement;
    this.wrapperElement = document.querySelector('.js-cart-detail-photo-wrapper') as HTMLElement;
    this.photoElement = document.querySelector('.js-cart-detail-image') as HTMLImageElement;
    this.photoElement.addEventListener('load', () => {
      this.currentImageSize = {
        width: this.photoElement.naturalWidth,
        height: this.photoElement.naturalHeight
      };
      this.clearMoveLimits();
      this.updateActualImageSize();
    });

    window.addEventListener('resize', () => {
      this.updateActualImageSize();
    });

    this.photoElementHammer = new Hammer(this.photoElement);

    this.scaleElement = document.querySelector('.js-cart-detail-scale-range') as HTMLInputElement;
    this.resetButtonElement = document.querySelector('.js-cart-detail-reset-button') as HTMLButtonElement;

    fromEvent(this.scaleElement, 'input')
        .pipe(tap(() => this.scale()))
        .subscribe();
    fromEvent(this.scaleElement, 'change')
        .pipe(tap(() => this.scale()))
        .subscribe();
    fromEvent(this.resetButtonElement, 'click')
        .pipe(
            tap(() => this.resetScale())
        )
        .subscribe();

    this.initializeSwipeMove();
    this.initMouseMove();
    this.initializePinchZoom();
  }


  reset() {
    this.resetScale();
  }
  /**
   * ピンチズーム関連の初期化
   * @private
   */
  private initializePinchZoom() {
    const mc = new Hammer(this.containerElement, {
      recognizers: [
        [Hammer.Pinch, {enable: true}]
      ]
    });
    // noinspection JSUnusedLocalSymbols
    mc.on('pinchstart', e => {
      // ピンチ開始
      this.initialScaleValue = this.scaleValue;
    });
    // noinspection JSUnusedLocalSymbols
    mc.on('pinchmove', e => {
      if (this.initialScaleValue === null) {
        return;
      }
      const scaleValue = Math.min(6, Math.max(1, e.scale * this.initialScaleValue));
      if (Number.isNaN(scaleValue)) {
        return;
      }
      this.scaleElement.value = scaleValue.toString();
      this.scaleElement.dispatchEvent(new Event('change'));
    });
    // noinspection JSUnusedLocalSymbols
    mc.on('pinchend', e => {
      // ピンチ終了
      this.initialScaleValue = null;
    });
  }

  private resetScale() {
    this.photoElement.style.transform = 'translate(0, 0)';
    this.currentTranslate = null;
    this.startTranslate = null;

    this.scaleElement.value = '1';
    this.scaleElement.dispatchEvent(new InputEvent('input', {
      bubbles: true
    }));
  }

  private scale() {
    const value = Number.parseFloat(this.scaleElement.value);
    if (Number.isNaN(value)) {
      return;
    }

    this.wrapperElement.style.transform = `scale(${value})`;
  }


  private initMouseMove() {

    this.containerElement.addEventListener('touchmove', e => {
      if (this.pointerDowned) {
        e.preventDefault();
        e.stopPropagation();
      }
    });

    this.containerElement.addEventListener('touchstart', (e: TouchEvent) => {
      if (!this.currentImageSize) {
        return;
      }

      if (this.processDoubleTap('touch', e)) {
        return;
      }

      if (!this.scaleValue || this.scaleValue <= 1.0) {
        return;
      }

      if (e.touches.length !== 1) {
        this.pointerDowned = false;
        this.dragging = false;
        this.dragStartPoint = undefined;
        this.startTranslate = null;
        return;
      }

      const screenX = e.touches[0].screenX;
      const screenY = e.touches[0].screenY;

      this.pointerDowned = true;
      this.dragging = false;
      this.dragStartPoint = {
        x: screenX,
        y: screenY
      };
      this.startTranslate = {
        x: this.currentTranslate?.x ?? 0,
        y: this.currentTranslate?.y ?? 0
      };
    });

    // noinspection JSUnusedLocalSymbols
    this.photoElement.addEventListener('pointercancel', e => {
      // e.preventDefault();
      // e.stopPropagation();
    });
    // noinspection JSUnusedLocalSymbols
    this.photoElement.addEventListener('pointerleave', e => {
      // e.preventDefault();
      // e.stopPropagation();
    });
    // noinspection JSUnusedLocalSymbols
    this.photoElement.addEventListener('pointerenter', e => {
      // e.preventDefault();
      // e.stopPropagation();
    });


    this.containerElement.addEventListener('pointerdown', (e: PointerEvent) => {
      if (e.button !== 0) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      if (this.processDoubleTap('pointer', e)) {
        return;
      }

      if (!this.scaleValue || this.scaleValue <= 1.0) {
        return;
      }

      if (this.initialScaleValue != null) {
        return;
      }

      if (e.pointerType === 'touch') {
        return;
      }

      if (!this.currentImageSize) {
        return;
      }


      this.pointerDowned = true;
      this.dragging = false;
      this.dragStartPoint = {
        x: e.screenX,
        y: e.screenY
      };
      this.startTranslate = {
        x: this.currentTranslate?.x ?? 0,
        y: this.currentTranslate?.y ?? 0
      };
    });
    this.containerElement.addEventListener('pointermove', e => {

      if (!this.pointerDowned) {
        return;
      }

      if (this.initialScaleValue != null) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      const diffX = (e.screenX - this.dragStartPoint.x) / this.scaleValue;
      const diffY = (e.screenY - this.dragStartPoint.y) / this.scaleValue;


      if (!this.dragging) {
        if ((diffX * diffX + diffY * diffY) > 10) {
          this.dragging = true;
        }
      }

      if (!this.dragging) {
        return;
      }

      const x = (this.startTranslate.x + diffX);
      let y = (this.startTranslate.y + diffY);

      if (this.actualImageSize) {
        const xRange = this.computeTranslateMinMax(this.photoElement.width, this.actualImageSize.width);
        const yRange = this.computeTranslateMinMax(this.photoElement.height, this.actualImageSize.height);
        if (x <= xRange.min) {
          this.isLimitLeft = true;
          return;
        }
        if (x >= xRange.max) {
          this.isLimitRight = true;
          return;
        }
        if (y <= yRange.min) {
          y = yRange.min;
        }
        if (y >= yRange.max) {
          y = yRange.max;
        }
      }

      this.currentTranslate = {
        x, y
      };

      this.photoElement.style.transform = `translate(${x}px, ${y}px)`;
    });
    // noinspection JSUnusedLocalSymbols
    this.photoElement.addEventListener('pointerout', e => {
      // e.preventDefault();
      // e.stopPropagation();
    });
    // noinspection JSUnusedLocalSymbols
    this.photoElement.addEventListener('pointerover', e => {
      // e.preventDefault();
      // e.stopPropagation();
    });
    document.addEventListener('pointerup', () => {
      this.pointerDowned = false;
      this.dragging = false;
      this.dragStartPoint = undefined;
      this.startTranslate = null;
    });
  }

  private processDoubleTap(eventKind: string, e: Event) {
    if (!this.shouldUseTouchEvent) {
      if (eventKind === 'touch') {
        this.shouldUseTouchEvent = true;
        this.prevPointerDownTime = new Date();
        return;
      }
    }

    if (this.shouldUseTouchEvent && eventKind !== 'touch') {
      return;
    }

    if (eventKind === 'touch') {
      const touchEvent = e as TouchEvent;
      if (touchEvent.touches?.length > 1) {
        return;
      }
    }

    const now = new Date();
    if (this.prevPointerDownTime) {
      const diff = now.getTime() - this.prevPointerDownTime.getTime();

      if (diff < 320) {
        const nextScale = this.scaleValue + 1;

        const {x, y} = this.currentTranslate ?? {x: 0, y: 0};
        if (eventKind === 'pointer') {
          const pe = e as PointerEvent;
          const clientRect = this.containerElement.getBoundingClientRect();
          const pX = (pe.clientX - clientRect.x) / this.scaleValue;
          const pY = (pe.clientY - clientRect.y) / this.scaleValue;
          const cx = (clientRect.x + clientRect.width) / 2 / this.scaleValue;
          const cy = (clientRect.y + clientRect.height) / 2 / this.scaleValue;

          const diffX = (cx - (pX - x));
          const diffY = (cy - (pY - y));

          const xRange = this.computeTranslateMinMax(this.photoElement.width, this.actualImageSize.width, nextScale);
          const yRange = this.computeTranslateMinMax(this.photoElement.height, this.actualImageSize.height, nextScale);
          const nextX = Math.min(Math.max(diffX, xRange.min), xRange.max);
          const nextY = Math.min(Math.max(diffY, yRange.min), yRange.max);

          this.currentTranslate = {
            x: nextX, y: nextY
          };

          this.photoElement.style.transform = `translate(${nextX}px, ${nextY}px)`;
        } else {
          const te = e as TouchEvent;
          const clientRect = this.containerElement.getBoundingClientRect();
          const pX = (te.touches[0].clientX - clientRect.x) / this.scaleValue;
          const pY = (te.touches[0].clientY - clientRect.y) / this.scaleValue;
          const cx = (clientRect.x + clientRect.width) / 2 / this.scaleValue;
          const cy = (clientRect.y + clientRect.height) / 2 / this.scaleValue;

          const diffX = (cx - (pX - x));
          const diffY = (cy - (pY - y));

          const xRange = this.computeTranslateMinMax(this.photoElement.width, this.actualImageSize.width, nextScale);
          const yRange = this.computeTranslateMinMax(this.photoElement.height, this.actualImageSize.height, nextScale);
          const nextX = Math.min(Math.max(diffX, xRange.min), xRange.max);
          const nextY = Math.min(Math.max(diffY, yRange.min), yRange.max);

          this.currentTranslate = {
            x: nextX, y: nextY
          };

          this.photoElement.style.transform = `translate(${nextX}px, ${nextY}px)`;
        }

        if (nextScale > 6) {
          this.resetScale();
        } else {
          this.scaleElement.value = nextScale.toString();
        }
        this.scaleElement.dispatchEvent(new Event('change'));
        return true;
      }
    }
    this.prevPointerDownTime = now;
    return false;
  }

  private initializeSwipeMove() {
    const mc = new Hammer(this.containerElement, {
      recognizers: [
        [Hammer.Swipe, {enable: true}]
      ]
    });
    mc.on('swipeleft', () => {
      if (this.scaleValue > 1) {
        if (!this.isLimitLeft) {
          return;
        }
        if (!this.isLimitLeft2) {
          this.isLimitLeft2 = true;
          return;
        }
      }
      this.loadNextPrevPhoto(true);
    });
    mc.on('swiperight', () => {
      if (this.scaleValue > 1) {
        if (!this.isLimitRight) {
          return;
        }
        if (!this.isLimitRight2) {
          this.isLimitRight2 = true;
          return;
        }
      }
      this.loadNextPrevPhoto(false);
    });
  }

  handleOnShown() {
    this.updateActualImageSize();
  }

  private updateActualImageSize() {
    const elementWidth = this.photoElement.offsetWidth;
    const elementHeight = this.photoElement.offsetHeight;
    if (elementWidth <= 0 || elementHeight <= 0) {
      this.actualImageSize = undefined;
      return;
    }

    if (!this.currentImageSize) {
      this.actualImageSize = undefined;
      return;
    }

    const elementRatio = elementHeight / elementWidth;

    const naturalRatio = this.currentImageSize.height / this.currentImageSize.width;

    if (elementRatio >= naturalRatio) {
      this.actualImageSize = {
        width: elementWidth,
        height: elementWidth * naturalRatio
      };
    } else {
      this.actualImageSize = {
        width: elementHeight / naturalRatio,
        height: elementHeight
      };
    }
  }

  private computeTranslateMinMax(elementSize: number, actualSize: number, scaleValue?: number) {
    const sv = (typeof scaleValue) === 'undefined' ? this.scaleValue : scaleValue;
    const marginX = -(elementSize - actualSize * sv) / 2;
    const max = (marginX / sv) * (marginX > 0 ? 1 : -2);
    const min = -max;
    return {
      min, max
    };
  }

  private clearMoveLimits() {
    this.isLimitLeft = false;
    this.isLimitLeft2 = false;
    this.isLimitRight = false;
    this.isLimitRight2 = false;
  }
}
