import {FeatureFlag} from '../../utilities/FeatureFlag';
import {catchError, concatMap, filter, fromEvent, of, tap} from 'rxjs';
import {getClickEventTarget} from '../../common/click-event-target';
import {getClient} from '../../utilities';
import {CART_ITEM_DELETED_EVENT, CartItemDeletedEvent} from './CartItemDeletedEvent';
import {hideGlobalSpinner, showGlobalSpinner} from '../../common';
import {CartFacade} from './_cart-facade';

export class CartItemDeleteButton {

  private static instance: CartItemDeleteButton;

  static init() {
    this.instance = new CartItemDeleteButton();
    this.instance.initialize();
  }

  private initialize() {
    fromEvent(document, 'click')
        .pipe(
            filter(event => {
              return this.filterClickEvent(event);
            }),
            tap(() => showGlobalSpinner()),
            concatMap(event => {
              return this.handleDeleteButtonOnClick(event);
            }),
            tap(() => hideGlobalSpinner()),
            catchError(err => {
              hideGlobalSpinner();
              console.error(err);
              return of(undefined);
            })
        )
        .subscribe();
  }

  private filterClickEvent(event: Event) {
    const target = event.target as HTMLElement;
    const element = target.tagName === 'BUTTON'
      ? target
      : (target.tagName === 'I' ? target.parentElement : undefined);
    return element?.classList?.contains('js-delete-cart-item-button') ?? false;
  }

  private handleDeleteButtonOnClick(event: Event) {
    const target = getClickEventTarget(event);
    const itemId = target.dataset.itemId;
    const photoId = target.dataset.photoId;

    return CartFacade.removeFromCartByItemId(itemId)
        .then(() => this.raiseDeletedEvent(itemId, photoId));
  }

  private raiseDeletedEvent(itemId: string, photoId: string) {
    const e = new CustomEvent<CartItemDeletedEvent>(CART_ITEM_DELETED_EVENT, {
      detail: {
        itemId, photoId
      }
    });

    document.dispatchEvent(e);
  }
}


if (FeatureFlag.isEnabled('NXVO-871')) {
  document.addEventListener('DOMContentLoaded', () => {
    CartItemDeleteButton.init();
  });
}
