/**
 * 写真サイズ(数値)を文字列に変換するデータ。
 */
export const photoSizeIndexToName = [
  'None',
  'L',
  'LSchool',
  'KG',
  'HL',
  'TwoL',
  'TwoLW',
  'TwoLL',
  'CutInEight',
  'CutInEightWide',
  'CutInSix',
  'CutInSixWide',
  'CutInFour',
  'CutInFourWide',
  'Calendar',
  'Other',
  'Album',
  'Data',
  'IdPhoto'
];


/**
 * 写真サイズ(数値)を表示文字列に変換するデータ。
 */
export const photoSizeToDisplayTexts = [
  '',
  'Ｌ',
  'L 学校撮影',
  'ＫＧ',
  'ＨＬ',
  '２Ｌ',
  '２ＬＷ',
  '２ＬＬ',
  '八切',
  '八切ワイド',
  '六切',
  '六切ワイド',
  '四切',
  '四切ワイド',
  'カレンダー',
  'その他',
  'アルバム',
  'データ',
  '証明写真'
];
