import {querySelectorAll} from '../utilities';

/**
 * 確認付きフォームの`submit`イベント。
 * @param e イベント。
 * @returns 処理しない場合は`false`、それ以外の場合は`undefined`。
 */
export const handleFormWithConfirmOnSubmit = (e: Event) => {
  const form = e.target as HTMLFormElement;
  const message = (() => {
    if (form.dataset.confirmMessage) {
      return form.dataset.confirmMessage;
    }
    return '実行してよろしいですか？';
  })();
  if (!confirm(message)) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    return false;
  }
};


document.addEventListener('DOMContentLoaded', () => {
  const forms = querySelectorAll('form.with-confirm');

  forms.forEach((form: HTMLFormElement) => {
    form.addEventListener('submit', e => {
      return handleFormWithConfirmOnSubmit(e);
    });
  });
});
