export const getClickEventTarget = (e: Event) => {
  if (!e || !e.target) {
    return null;
  }

  const element = e.target as HTMLElement;
  if (!element.tagName) {
    return null;
  }

  let current = element;
  while (current && !current.matches('button')) {
    current = current.parentElement;
  }
  return current;
};
