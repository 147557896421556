import {querySelector} from '../utilities';

export const showGlobalSpinner = () => {
  querySelector('.global-spinner-wrapper').classList.add('show');
};

export const hideGlobalSpinner = () => {
  querySelector('.global-spinner-wrapper').classList.remove('show');
};

(window as any).globalSpinner = {
  show: showGlobalSpinner,
  hide: hideGlobalSpinner
};

