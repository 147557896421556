/**
 * カート。
 */
export interface Cart {
  /**
   * カートID。
   */
  id: string;

  /**
   * ユーザーID。
   */
  customerId: string;

  /**
   * カート項目。
   */
  cartItems: CartItem[];

  /**
   * 合計金額。
   */
  totalPrice: number;
}

/**
 * カート項目。
 */
export interface CartItem {
  /**
   * カート項目ID。
   */
  id: string;

  /**
   * カートID。
   */
  cartId: string;

  /**
   * 写真ID。
   */
  photoId: string;

  /**
   * 写真サイズ。
   */
  photoSize: number;

  /**
   * イベントID。
   */
  saleEventId: string;

  fileNumber: number;

  primaryCategoryName: string;

  secondaryCategoryId: string;

  secondaryCategoryName: string;

  tertiaryCategoryId: string;

  tertiaryCategoryName: string;

  unitPrice: number;

  /**
   * 枚数。
   */
  count: number;
}

/**
 * `x`がカートか否か判定する。
 * @param x 判定対象。
 * @returns カートの場合`true`。
 */
export function isCart(x?: any): x is Cart {
  if (x === null) {
    return false;
  }

  if (typeof x !== 'object') {
    return false;
  }

  if (typeof x.customerId !== 'string') {
    return false;
  }

  if (typeof x.totalPrice !== 'number') {
    return false;
  }

  if (!Array.isArray(x.cartItems)) {
    return false;
  }

  return x.cartItems.every((item: any) => isCartItem(item));
}

function isCartItem(x?: any): x is CartItem {
  if (x === null) {
    return false;
  }
  if (typeof x !== 'object') {
    return false;
  }

  if (typeof x.id !== 'string') {
    return false;
  }
  if (typeof x.cartId !== 'string') {
    return false;
  }
  if (typeof x.photoId !== 'string') {
    return false;
  }
  if (typeof x.photoSize !== 'number') {
    return false;
  }
  if (typeof x.count !== 'number') {
    return false;
  }
  return true;
}
