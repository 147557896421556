import {closestClass, querySelector, querySelectorAll} from '../../utilities';
import {
  filter,
  fromEvent,
  tap
} from 'rxjs';
import {ExhibitionRoom, SecondaryCategory, TertiaryCategory} from '../models';

/**
 * 検索パラメータの内、展示室関連のもの。
 * ※ 中項目IDと小項目IDが該当する。
 */
export interface QueryParameterExhibitionRoomPart {
  secondaryCategoryIds: string[];
  tertiaryCategoryIds: string[];
}

/**
 * ページ変更イベント。
 */
export interface PhotoSearchPageChangedDetail {
  pageIndex: number;
  photoId: string;
}

/**
 * 「展示室で検索」ダイアログ。
 */
export class SearchByExhibitionRoomDialog {

  private rootElement: HTMLElement;
  private exhibitionRoomListElement: HTMLElement;

  private exhibitionRooms = [] as ExhibitionRoom[];
  private queryParameter: QueryParameterExhibitionRoomPart = {
    secondaryCategoryIds: [],
    tertiaryCategoryIds: []
  };

  private get IsAnyCategorySelected() {
    return this.queryParameter.secondaryCategoryIds.length > 0
      || this.queryParameter.tertiaryCategoryIds.length > 0;
  }

  constructor() {
    // 「展示室で絞り込む」ボタンクリック。
    fromEvent(document, 'click')
        .pipe(
            filter(x => {
              if (!x.target) {
                return false;
              }
              const target = x.target as HTMLElement;
              if (!target.matches) {
                return false;
              }
              const selector1 = '.page.photo-list-js .search-by-event-button';
              const selector = selector1 + ', ' + selector1 + ' *';
              return target.matches(selector);
            }),
            tap(() => SearchByExhibitionRoomDialog.handleOpenDialogButtonOnClick())
        )
        .subscribe({
          error: err => console.error(err)
        });

    // 大項目クリック。
    fromEvent(document, 'input')
        .pipe(
            filter(e => {
              const target = e?.target as HTMLElement;
              return target?.matches(
                  '.event-search-dialog input[type="checkbox"].primary-category');
            }),
            tap(e => this.handlePrimaryCategoryOnClick(e))
        )
        .subscribe({
          error: err => console.error(err)
        });

    // 中項目クリック。
    fromEvent(document, 'input')
        .pipe(
            filter(e => {
              const target = e?.target as HTMLElement;
              return target?.matches(
                  '.event-search-dialog input[type="checkbox"].secondary-category');
            }),
            tap(e => this.handleSecondaryCategoryOnClick(e))
        )
        .subscribe({
          error: err => console.error(err)
        });

    // 小項目クリック。
    fromEvent(document, 'input')
        .pipe(
            filter(e => {
              const target = e?.target as HTMLElement;
              return target?.matches(
                  '.event-search-dialog input[type="checkbox"].tertiary-category');
            }),
            tap(e => SearchByExhibitionRoomDialog.handleTertiaryCategoryOnClick(e))
        )
        .subscribe({
          error: err => console.error(err)
        });

    // 全て見る
    fromEvent(document, 'click')
        .pipe(
            filter(e => {
              const target = e?.target as HTMLElement;
              return target?.matches(
                  '.js-show-all-exhibition-room-button');
            }),
            tap(() => this.handleShowAllButtonOnClick())
        )
        .subscribe({
          error: err => console.error(err)
        });

    // 全て見る
    fromEvent(document, 'click')
        .pipe(
            filter(e => {
              const target = e?.target as HTMLElement;
              return target?.matches(
                  '.js-clear-all-exhibition-room-check-button');
            }),
            tap(() => this.handleClearAllCheckButtonOnClick())
        )
        .subscribe({
          error: err => console.error(err)
        });

    // 検索
    fromEvent(document, 'click')
        .pipe(
            filter(e => {
              const target = e?.target as HTMLElement;
              const selector1 = '.event-search-dialog .execute-search-button';
              const selector = `${selector1}, ${selector1} *`;
              return target?.matches(selector);
            }),
            tap(() => this.handleExecuteSearchButtonOnClick())
        )
        .subscribe({
          error: err => console.error(err)
        });
  }

  // region API
  // #region API

  /**
   * 初期化。
   *
   * @param exhibitionRooms 展示室一覧。
   * @param parameter 検索パラメータ。
   */
  initialize(
      exhibitionRooms: ExhibitionRoom[],
      parameter: QueryParameterExhibitionRoomPart) {

    this.setDomElements();
    if (!this.rootElement || !this.exhibitionRoomListElement) {
      console.error('展示室で検索ダイアログ：必要なDOM要素ガ見つからないため初期化に失敗しました。');
      return;
    }

    this.exhibitionRooms = exhibitionRooms;
    this.exhibitionRooms.forEach(room => {
      room.secondaryCategories.forEach(sc => {
        sc.tertiaryCategories = sc.tertiaryCategories.sort((a, b) => {
          return a.firstFileNumber - b.firstFileNumber;
        });
      });

      room.secondaryCategories = room.secondaryCategories.sort((a, b) => {
        if (a.tertiaryCategories.length && b.tertiaryCategories.length) {
          const aMin = a.tertiaryCategories[0].firstFileNumber;
          const bMin = b.tertiaryCategories[0].firstFileNumber;
          return aMin - bMin;
        } else if (a.tertiaryCategories.length) {
          return -1;
        }
        return 1;
      });
    });
    this.queryParameter = {
      secondaryCategoryIds: [...parameter.secondaryCategoryIds],
      tertiaryCategoryIds: [...parameter.tertiaryCategoryIds]
    };

    this.updateView();
  }

  // #endregion API
  // endregion API

  // region DOM関連の初期化
  // #region DOM関連の初期化

  private setDomElements() {
    this.rootElement = querySelector('.photo-search-dialog.event-search-dialog') as HTMLElement;
    this.exhibitionRoomListElement = querySelector('.modal-body .event-list') as HTMLElement;
    this.clearExhibitionRoomList();
  }

  // #endregion DOM関連の初期化
  // endregion DOM関連の初期化

  // region 画面の更新関連
  // #region 画面の更新関連

  private clearExhibitionRoomList() {
    if (!this.exhibitionRoomListElement) {
      return;
    }
    querySelectorAll(':scope > *', this.exhibitionRoomListElement).forEach(x => {
      x.remove();
    });
  }

  private updateView() {
    if (!this.exhibitionRoomListElement) {
      return;
    }
    this.clearExhibitionRoomList();
    this.exhibitionRooms.forEach(exhibitionRoom => {
      const exhibitionRoomItemElement = this.createRoomItemElement(exhibitionRoom);

      const secondaryCategoryList = exhibitionRoomItemElement.querySelector('.exhibition-room-item__secondary-category-list') as HTMLUListElement;
      exhibitionRoom.secondaryCategories.forEach(secondaryCategory => {
        const secondaryCategoryItem
          = this.createEventListItemElement(exhibitionRoom, secondaryCategory);

        secondaryCategory.tertiaryCategories.forEach(tertiaryCategory => {
          this.addTertiaryCategory(secondaryCategoryItem, tertiaryCategory);
        });
        secondaryCategoryList.append(secondaryCategoryItem);
      });
      this.exhibitionRoomListElement.append(exhibitionRoomItemElement);
    });

    SearchByExhibitionRoomDialog.updateRoomItemElement();

  }

  private createRoomItemElement(exhibitionRoom: ExhibitionRoom) {
    const exhibitionRoomItemElement = querySelector('.exhibition-room-item-template > .exhibition-room-item')
        .cloneNode(true) as HTMLElement;

    const primaryCategoryCheckbox = exhibitionRoomItemElement.querySelector('.primary-category');
    primaryCategoryCheckbox.id = `primary-category-check-${exhibitionRoom.id}`;

    const exhibitionRoomNameElement = exhibitionRoomItemElement.querySelector('.exhibition-room-item__name') as HTMLLabelElement;
    exhibitionRoomNameElement.textContent = exhibitionRoom.basicInformation.primaryCategory;
    exhibitionRoomNameElement.htmlFor = primaryCategoryCheckbox.id;

    return exhibitionRoomItemElement;
  }

  private createEventListItemElement(
      exhibitionRoom: ExhibitionRoom,
      secondaryCategory: SecondaryCategory) {

    const eventListItemElement = querySelector('.event-list-item-template > .event-list-item')
        .cloneNode(true) as HTMLElement;

    const checkbox = querySelector(
        'input[type="checkbox"].secondary-category',
        eventListItemElement) as HTMLInputElement;
    checkbox.id = `checkbox-${secondaryCategory.id}`;
    checkbox.dataset.secondaryCategoryId = secondaryCategory.id;
    checkbox.checked
      = !this.IsAnyCategorySelected
      || this.queryParameter.secondaryCategoryIds.some(x => x === secondaryCategory.id);

    const label = querySelector(
        '.checkbox-label.secondary-category',
        eventListItemElement) as HTMLLabelElement;
    label.htmlFor = checkbox.id;
    label.textContent
      = `${exhibitionRoom.basicInformation.eventName} /`
      + ` ${secondaryCategory.secondaryCategoryName}`;

    return eventListItemElement;
  }

  private addTertiaryCategory(
      eventListItemElement: HTMLElement,
      tertiaryCategory: TertiaryCategory) {

    const tertiaryCategoryListItemElement = querySelector(
        '.tertiary-category-list-item-template > .tertiary-category-list-item')
        .cloneNode(true) as HTMLElement;

    const checkbox = querySelector(
        'input[type="checkbox"].tertiary-category',
        tertiaryCategoryListItemElement) as HTMLInputElement;
    checkbox.id = `checkbox-${tertiaryCategory.id}`;
    checkbox.dataset.tertiaryCategoryId = tertiaryCategory.id;

    checkbox.checked = !this.IsAnyCategorySelected
      || this.queryParameter.tertiaryCategoryIds.some(x => x === tertiaryCategory.id);

    const label = querySelector(
        'label.tertiary-category',
        tertiaryCategoryListItemElement) as HTMLLabelElement;
    label.textContent = tertiaryCategory.tertiaryCategoryName;
    label.htmlFor = checkbox.id;

    const tertiaryCategoryListElement = querySelector(
        '.tertiary-category-list', eventListItemElement);

    tertiaryCategoryListElement.append(tertiaryCategoryListItemElement);
  }

  // #endregion 画面の更新関連
  // endregion 画面の更新関連

  // region イベントハンドラ
  // #region イベントハンドラ

  /**
   * ダイアログを表示するボタンクリック。
   */

  private static handleOpenDialogButtonOnClick() {
    $('.event-search-dialog').modal('show');
  }

  private static updateRoomItemElement() {
    const roomItemElements = document.querySelectorAll('.event-list .exhibition-room-item');
    roomItemElements.forEach((roomItemElement: HTMLElement) => {
      const secondaryItems = Array.from(roomItemElement.querySelectorAll('input.secondary-category'));

      const isAllSecondaryItemChecked = secondaryItems.every((secondaryItem: HTMLInputElement) => {
        return secondaryItem.checked;
      });

      const primaryCheckbox = roomItemElement.querySelector('input.primary-category') as HTMLInputElement;
      primaryCheckbox.checked = isAllSecondaryItemChecked;
    });
  }

  private handlePrimaryCategoryOnClick(e: Event) {
    const target = e.target as HTMLInputElement;
    const roomItem = target.closest('.exhibition-room-item');
    if (!roomItem) {
      console.warn('roomItem not found');
      return;
    }

    const checked = target.checked;
    const secondaryCheckboxes = roomItem.querySelectorAll('input.secondary-category');
    secondaryCheckboxes.forEach((sc: HTMLInputElement) => {
      sc.checked = checked;
      const e = new Event('input', {
        bubbles: true,
        cancelable: true
      });
      sc.dispatchEvent(e);
    });
  }

  private handleSecondaryCategoryOnClick(e: Event) {
    const target = e.target as HTMLInputElement;
    const tertiaryCategorySelector
      = ':scope .tertiary-category-list input[type="checkbox"].tertiary-category';

    const container = closestClass(target, 'event-list-item-content');
    const tertiaryCategories = querySelectorAll(tertiaryCategorySelector, container);
    tertiaryCategories.forEach((tc: HTMLInputElement) => {
      tc.checked = target.checked;
    });
    SearchByExhibitionRoomDialog.updateRoomItemElement();
  }

  private static handleTertiaryCategoryOnClick(e: Event) {
    const target = e.target as HTMLInputElement;
    const tertiaryList = closestClass(target, 'tertiary-category-list');
    if (!tertiaryList) {
      console.warn('展示室で検索:小項目選択:小項目リストが見つかりません。');
      return;
    }

    const allTertiaries
      = querySelectorAll('input[type="checkbox"].tertiary-category', tertiaryList);

    const exhibitionRoomItem = closestClass(target, 'event-list-item');
    if (!exhibitionRoomItem) {
      console.warn('展示室で検索:小項目選択:中項目要素が見つかりません。');
      return;
    }
    const secondaryCategory = querySelector(
        'input[type="checkbox"].secondary-category',
        exhibitionRoomItem) as HTMLInputElement;
    if (!secondaryCategory) {
      console.warn('展示室で検索:小項目選択:中項目チェックボックスが見つかりません。');
      return;
    }

    secondaryCategory.checked = allTertiaries.every((x: HTMLInputElement) => x.checked);
    SearchByExhibitionRoomDialog.updateRoomItemElement();
  }

  private handleShowAllButtonOnClick() {
    const secondaryCategories = querySelectorAll(
        'input[type="checkbox"].secondary-category', this.rootElement);
    secondaryCategories.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = true;
      const e = new Event('input', {
        bubbles: true,
        cancelable: true
      });
      checkbox.dispatchEvent(e);
    });
  }

  private handleClearAllCheckButtonOnClick() {
    const secondaryCategories = querySelectorAll(
        'input[type="checkbox"].secondary-category', this.rootElement);
    secondaryCategories.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = false;
      const e = new Event('input', {
        bubbles: true,
        cancelable: true
      });
      checkbox.dispatchEvent(e);
    });
  }

  private handleExecuteSearchButtonOnClick() {
    const secondarySelector = ':scope input[type="checkbox"].secondary-category';

    const allSecondaryCategoryCount = querySelectorAll(secondarySelector, this.rootElement).length;
    const secondaryCategoryIds = querySelectorAll(secondarySelector,
        this.rootElement)
        .filter((x: HTMLInputElement) => x.checked)
        .map((x: HTMLElement) => x.dataset.secondaryCategoryId);

    const tertiarySelector = ':scope input[type="checkbox"].tertiary-category';
    const tertiaryCategoryIds = querySelectorAll(tertiarySelector,
        this.rootElement)
        .filter((x: HTMLInputElement) => x.checked)
        .map((x: HTMLElement) => x.dataset.tertiaryCategoryId);

    const detail = allSecondaryCategoryCount === secondaryCategoryIds.length ? {
      secondaryCategoryIds: [],
      tertiaryCategoryIds: []
    } : {
      secondaryCategoryIds,
      tertiaryCategoryIds
    } as QueryParameterExhibitionRoomPart;
    const e = new CustomEvent('spss.photoSearchExecuted', {
      detail
    });

    document.dispatchEvent(e);
    $('.event-search-dialog').modal('hide');

  }

  // #endregion イベントハンドラ
  // endregion イベントハンドラ


}
