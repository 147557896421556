import {fromEvent, tap} from 'rxjs';
import {CART_ITEM_DELETED_EVENT, CartItemDeletedEvent} from './CartItemDeletedEvent';
import {FeatureFlag} from '../../utilities/FeatureFlag';

export class CartItemDeletedEventHandler {
  private static instance = new CartItemDeletedEventHandler();

  static init() {
    this.instance = new CartItemDeletedEventHandler();
    this.instance.initialize();
  }

  private initialize() {
    fromEvent<CustomEvent<CartItemDeletedEvent>>(document, CART_ITEM_DELETED_EVENT)
        .pipe(
            tap(event => this.handleCartItemDeletedEvent(event))
        )
        .subscribe();
  }

  private handleCartItemDeletedEvent(event: CustomEvent<CartItemDeletedEvent>) {
    const eventData = event.detail;

    Array.from(document.querySelectorAll('.js-cart-list-item'))
        .filter((x: HTMLElement) => x.dataset.itemId === eventData.itemId)
        .forEach(x => {
          const listItem = x.closest('.js-cart-list-item');
          const cartListInEvent = x.closest('.js-cart-list-in-event');
          const cartListItemPerEvent = x.closest('.js-cart-list-item-per-event');

          // 対象のカート項目を削除
          listItem?.remove();

          // 同じイベント内の項目が0件の場合は、イベント毎削除する
          const itemInSameEvent = cartListInEvent?.querySelectorAll('.js-cart-list-item').length ?? 0;
          if (itemInSameEvent <= 0) {
            cartListItemPerEvent?.remove();
          }

          location.reload();

          // ミニカートの変更イベントを発行。
          document.dispatchEvent(new Event('spss.cartChanged'));
        });
  }
}

if (FeatureFlag.isEnabled('NXVO-871')) {
  document.addEventListener('DOMContentLoaded', () => {
    CartItemDeletedEventHandler.init();
  });
}
