import {FeatureFlag} from '../../utilities/FeatureFlag';
import {catchError, concatMap, filter, fromEvent, of, tap} from 'rxjs';
import {getClickEventTarget} from '../../common/click-event-target';
import {getClient} from '../../utilities';
import {hideGlobalSpinner, showGlobalSpinner} from '../../common';

export class ExhibitionRoomDeleteButton {
  private static readonly instance = new ExhibitionRoomDeleteButton();

  static init() {
    this.instance.bindToDom();
  }

  private bindToDom() {
    fromEvent(document, 'click')
        .pipe(
            filter(event => {
              const target = event.target as HTMLElement;
              if (target.tagName === 'BUTTON') {
                return target.classList.contains('js-delete-exhibition-room-button');
              }
              if (target.tagName === 'I') {
                return target.parentElement?.classList.contains('js-delete-exhibition-room-button');
              }
              return false;
            }),
            tap(() => showGlobalSpinner()),
            concatMap(event => {
              return this.handleExhibitionRoomDeleteButtonClickEvent(event);
            }),
            tap(exhibitionRoomId => {
              this.removeDomElement(exhibitionRoomId);
            }),
            tap(() => hideGlobalSpinner())
        )
        .subscribe();
  }

  private handleExhibitionRoomDeleteButtonClickEvent(event: Event): Promise<string> {
    const target = getClickEventTarget(event);
    const listItem = target.closest('.js-exhibition-room-list-item') as HTMLElement;
    if (!listItem) {
      alert('削除に失敗しました。');
      return Promise.resolve(undefined);
    }

    const ok = confirm('展示室を削除してよろしいですか？\n※ 削除対象展示室の写真がカートに入っている場合は削除されます。'
      + '\n※ 削除した展示室は、再度「展示室コード入力」を行うと表示されます。');
    if (!ok) {
      return Promise.resolve(undefined);
    }

    const exhibitionRoomId = listItem.dataset.exhibitionRoomId;
    return getClient().delete(`/Customer/api/ExhibitionRoom/${exhibitionRoomId}`)
        .then(() => exhibitionRoomId)
        .catch(err => {
          console.error('room delete error', err);
          alert('削除に失敗しました');
          return Promise.resolve(undefined);
        });
  }

  private removeDomElement(exhibitionRoomId: string) {
    if (!exhibitionRoomId) {
      return;
    }
    const items = document.querySelectorAll(
        `.js-exhibition-room-list-item[data-exhibition-room-id="${exhibitionRoomId}"]`);
    items.forEach(x => x.remove());

    document.dispatchEvent(new Event('spss.cartChanged'));
  }
}

if (FeatureFlag.isEnabled('NXVO-869')) {
  document.addEventListener('DOMContentLoaded', () => {
    ExhibitionRoomDeleteButton.init();
  });
}
