import {querySelector} from '../../utilities';

document.addEventListener('DOMContentLoaded', () => {
  if (!querySelector('.my-page.my-page-child-information')) {
    return;
  }

  const inputElement = (querySelector('#input-for-face-recognition-photo') as HTMLInputElement);
  const closeButton = querySelector('.face-recognition-notes-dialog .confirmed-button');
  closeButton.addEventListener('click', () => {
    inputElement.click();
  });
});
