import 'jquery';
import 'jquery-ui-dist/jquery-ui.min';
import {querySelector} from '../utilities';


document.addEventListener('DOMContentLoaded', () => {
  const $datePickers = $('.my-date-picker');
  const minDate = querySelector('.page.sale-event-add')
      ? 0
      : undefined;
  $datePickers.datepicker({
    minDate: minDate,
    prevText: '前月',
    nextText: '次月',
    monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    monthNamesShort: ['１月', '２月', '３月', '４月', '５月', '６月', '７月', '８月', '９月', '１０月', '１１月', '１２月'],
    dayNames: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
    dayNamesShort: ['日曜', '月曜', '火曜', '水曜', '木曜', '金曜', '土曜'],
    dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
    weekHeader: '週',
    showWeek: false,
    yearSuffix: '年',
    closeText: '閉じる',
    currentText: '今月',
    showMonthAfterYear: true,
    showButtonPanel: true,
    dateFormat: 'yy/mm/dd'
  });
});
