import {querySelector} from '../../utilities';

const downloadUrl = '/Customer/Api/PhotoDownload';

const handleDownloadButtonOnClick = (e: Event) => {
  const target = e.target as HTMLElement;
  if (!target) {
    console.warn('ダウンロードボタンが見つかりません');
    return;
  }
  const button = target.closest('.download-photo-archive-button') as HTMLElement;
  if (!button) {
    console.warn('ダウンロードボタンを取得できません');
  }
  const orderId = button.dataset.orderId;
  if (!orderId) {
    console.warn('注文IDを取得できません。');
    return;
  }

  const url = `${downloadUrl}/${orderId}`;
  window.open(url, '_blank', 'noopener,noreferrer');
};


document.addEventListener('DOMContentLoaded', () => {
  const page = querySelector('.page.my-page.order-detail');
  if (!page) {
    return;
  }

  const button = querySelector('.download-photo-archive-button');
  button.addEventListener('click', e => handleDownloadButtonOnClick(e));
});
