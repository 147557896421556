import {querySelector, querySelectorAll} from '../../utilities';

const enableDialogAgreeButton = (dialogAgreeButton: HTMLButtonElement) => {
  dialogAgreeButton.disabled = false;
};

const disableDialogAgreeButton = (dialogAgreeButton: HTMLButtonElement) => {
  dialogAgreeButton.disabled = true;
};

const toggleDialogAgreeButton = (dialogBody: HTMLElement, dialogAgreeButton: HTMLButtonElement) => {
  // ブラウザによっては一番下までスクロールしたことが検知されないことがあるため、
  // 一番下までスクロールしなくても所定の動作をするように10px分ゆとりを持たせる
  const marginForScroll = 10;
  if (dialogBody.scrollHeight - dialogBody.offsetHeight <= dialogBody.scrollTop + marginForScroll) {
    enableDialogAgreeButton(dialogAgreeButton);
    return;
  }

  disableDialogAgreeButton(dialogAgreeButton);
};

const initializeLinks = () => {
  const links = querySelectorAll('#terms-of-use-dialog a');
  links.forEach((x: HTMLAnchorElement) => {
    x.target = '_blank';
  });
};

document.addEventListener('DOMContentLoaded', () => {
  if (!querySelector('.page.registration')) {
    return;
  }

  initializeLinks();

  const checkbox = querySelector('#AgreeTermsOfUse') as HTMLInputElement;
  const termsOfUseDialog = querySelector('#terms-of-use-dialog') as HTMLElement;
  const dialogBody = querySelector('.modal-body', termsOfUseDialog) as HTMLElement;
  const dialogAgreeButton = querySelector('.agree-button', termsOfUseDialog) as HTMLButtonElement;

  checkbox.addEventListener('click', e => {
    if (checkbox.checked) {
      $(termsOfUseDialog).modal('show');
      e.preventDefault();
    }
  });

  // termsOfUseDialog.addEventListener('shown.bs.modal', () => {
  //   // toggleDialogAgreeButton(dialogBody, dialogAgreeButton);
  // });
  //
  // termsOfUseDialog.addEventListener('hidden.bs.modal', () => {
  //   disableDialogAgreeButton(dialogAgreeButton);
  // });
  //
  // dialogBody.addEventListener('scroll', () => {
  //   toggleDialogAgreeButton(dialogBody, dialogAgreeButton);
  // });

  dialogAgreeButton.addEventListener('click', () => {
    if (dialogAgreeButton.disabled) {
      return;
    }

    $(termsOfUseDialog).modal('hide');
    checkbox.checked = true;
  });
});
