import {FeatureFlag} from '../../utilities/FeatureFlag';
import {createPhotoUrl2} from '../common';
import {PhotoViewData} from './PhotoViewData';
import {formatPrice, hideGlobalSpinner, showGlobalSpinner} from '../../common';
import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  fromEvent,
  map,
  Subscription,
  tap
} from 'rxjs';
import {getClickEventTarget} from '../../common/click-event-target';
import {CartItemCountSelect} from './CartItemCountSelect';
import {CartItemScale} from './CartItemScale';
import {CartFacade} from './_cart-facade';
import {CART_ITEM_DELETED_EVENT, CartItemDeletedEvent} from './CartItemDeletedEvent';

const SHOW_CART_ITEM_BUTTON_SELECTOR = '.js-show-cart-detail-button';


export class CartItemDetail {
  private readonly finished$ = new BehaviorSubject<boolean>(false);

  private readonly isOpened$ = new BehaviorSubject<boolean>(false);

  private static instance: CartItemDetail;

  private cartItemCountSelect: CartItemCountSelect = new CartItemCountSelect(x => this.handlePhotoCountOnChanged(x));
  private cartItemScale = new CartItemScale();
  private samplePositionSelect: HTMLSelectElement;

  private $modal: JQuery;


  private subscriptions = [] as Subscription[];

  private viewDataList = [] as PhotoViewData[];
  private currentViewDataIndex$ = new BehaviorSubject<number>(undefined);
  private viewData$ = this.currentViewDataIndex$.pipe(
      filter(x => typeof x === 'number'),
      map(x => this.viewDataList[x])
  );

  constructor() {
  }

  static init() {
    CartItemDetail.instance = new CartItemDetail();
    const instance = CartItemDetail.instance;
    instance.initSubscribers();
    instance.bindToDom();
  }

  static close(): Promise<undefined> {
    CartItemDetail.instance.$modal.modal('hide');
    CartItemDetail.instance.cleanup();
    return firstValueFrom(CartItemDetail.instance.finished$.pipe(
        filter(x => x),
        map(() => undefined)
    ));
  }

  static async waitToOpen(): Promise<void> {
    await firstValueFrom(CartItemDetail.instance.isOpened$.pipe(
        filter(x => x)
    ));
  }

  private initSubscribers() {
    this.subscriptions.push(
        this.viewData$
            .pipe(tap(x => {
              this.showViewData(x);
              this.ensureDialogIsOpen();
            }))
            .subscribe({
              error: err => {
                console.error(err);
              }
            })
    );
  }

  private bindToDom() {
    this.samplePositionSelect = document.querySelector('.js-cart-detail-sample-select');
    const photoElement = document.querySelector('.js-cart-detail-image') as HTMLImageElement;
    this.samplePositionSelect.addEventListener('change', () => {
      const kindValue = this.samplePositionSelect.value;
      const parsed = typeof (kindValue === 'string') ? Number.parseInt(kindValue) : 0;
      const kind = Number.isNaN(parsed) ? 0 : parsed;
      const viewData = this.viewDataList[this.currentViewDataIndex$.getValue()];
      photoElement.src = createPhotoUrl2(viewData.userId, viewData.sessionId, viewData.exhibitionRoomId, viewData.photoId, kind);
    });

    const buttons = document.querySelectorAll(SHOW_CART_ITEM_BUTTON_SELECTOR);
    buttons.forEach(button => {
      try {
        const viewData = this.createPhotoViewDataForButton(button as HTMLButtonElement);
        const viewDataIndex = this.viewDataList.length;
        this.viewDataList.push(viewData);

        button.addEventListener('click', () => {
          this.currentViewDataIndex$.next(viewDataIndex);
        });

      } catch (e) {
        console.error('bind HTMLElement to CartItemDetail failed', e);
      }
    });

    document.querySelectorAll('.js-cart-detail-next, .js-cart-detail-prev')
        .forEach((button: HTMLElement) => {
          this.subscriptions.push(
              fromEvent(button, 'click')
                  .pipe(
                      tap(event => {
                        this.handlePrevNextEvent(event);
                      })
                  )
                  .subscribe()
          );
        });

    this.cartItemCountSelect.bindToDom();
    this.cartItemScale.bindToDom((isNext: boolean) => this.moveViewDta(isNext));

    document.querySelectorAll('.js-cart-detail-delete-button').forEach(x => {
      const elem = x as HTMLButtonElement;
      elem.addEventListener('click', () => {
        const index = this.currentViewDataIndex$.getValue();
        const item = this.viewDataList[index];
        if (!item) {
          return;
        }

        try {
          showGlobalSpinner();
          CartFacade.removeFromCartByItemId(item.itemId)
              .then(() => {
                const e = new CustomEvent<CartItemDeletedEvent>(CART_ITEM_DELETED_EVENT, {
                  detail: {
                    itemId: item.itemId,
                    photoId: item.photoId
                  }
                });

                document.dispatchEvent(e);
                CartItemDetail.close().then();
              }) .catch(err => {
                console.error(err);
                alert('削除に失敗しました');
              }).finally(() => hideGlobalSpinner());
        } catch (err) {
          console.error(err);
          alert('削除に失敗しました');
        }
      });
    });
  }

  private createPhotoViewDataForButton(button: HTMLElement): PhotoViewData | undefined {
    const container = button.closest('.js-photo-cell') as HTMLElement;

    return new PhotoViewData(
        container?.dataset.itemId,
        container?.dataset.sessionId,
        container?.dataset.userId,
        container?.dataset.exhibitionRoomId,
        container?.dataset.photoId,
        container?.dataset.photoSize,
        container?.dataset.photoFileNumber,
        container?.dataset.photoCount,
        container?.dataset.minPhotoCount,
        container?.dataset.maxPhotoCount,
        container?.dataset.photoUnitPrice
    );
  }

  private ensureDialogIsOpen() {
    if (this.isOpened$.getValue()) {
      return;
    }

    this.$modal = $('#photo-detail-dialog');

    this.$modal.on('shown.bs.modal', () => {
      this.cartItemScale.handleOnShown();
    });

    this.$modal.on('hidden.bs.modal', () => {
      this.cleanup();
    });

    this.$modal.modal('show');
    this.isOpened$.next(true);
  }

  private cleanup() {
    this.isOpened$.next(false);
    this.finished$.next(true);
    this.$modal = undefined;
  }


  private showViewData(viewData: PhotoViewData) {
    const url = createPhotoUrl2(viewData.userId, viewData.sessionId, viewData.exhibitionRoomId, viewData.photoId);
    const img = document.querySelector('.js-cart-detail-image') as HTMLImageElement;
    img.src = url;


    document.querySelectorAll('.js-photo-file-number')
        .forEach((x: HTMLElement) => {
          x.textContent = viewData.photoFileNumber.toString();
        });

    this.cartItemCountSelect.changeVieData(viewData);
    document.querySelectorAll('.js-photo-price').forEach((price: HTMLElement) => {
      const photoCountElement = document.querySelector('.js-photo-count') as HTMLSelectElement;
      const subtotal = viewData.unitPrice * Number.parseInt(photoCountElement.value);
      price.textContent = formatPrice(subtotal);
    });

    this.cartItemScale.reset();
  }

  private handlePrevNextEvent(event: Event) {
    const target = getClickEventTarget(event);
    const isNext = target.classList.contains('js-cart-detail-next');
    this.moveViewDta(isNext);
  }

  private moveViewDta(isNext: boolean) {
    const nextIndex = (() => {
      const tempNext = this.currentViewDataIndex$.getValue() + (isNext ? 1 : -1);
      if (tempNext < 0) {
        return this.viewDataList.length - 1;
      }
      if (tempNext >= this.viewDataList.length) {
        return 0;
      }
      return tempNext;
    })();

    this.currentViewDataIndex$.next(nextIndex);
  }

  private handlePhotoCountOnChanged(viewData: PhotoViewData) {
    const index = this.viewDataList.findIndex(x => x.itemId === viewData.itemId);
    if (index < 0) {
      return;
    }

    this.viewDataList[index] = viewData;
    // 更新
    if (index === this.currentViewDataIndex$.getValue()) {
      this.currentViewDataIndex$.next(index);
    }

    const itemSubTotal = (viewData.photoCount * viewData.unitPrice);
    const roomSubTotal = this.viewDataList
        .filter(x => x.exhibitionRoomId === viewData.exhibitionRoomId)
        .map(x => {
          return x.photoCount * x.unitPrice;
        }).reduce((sum, current) => sum + current, 0);

    const cartListItem = document.querySelectorAll(`.js-cart-list-item[data-item-id="${viewData.itemId}"]`);
    cartListItem.forEach(x => {
      const element = x as HTMLElement;

      const photoCell = element.querySelector('.js-photo-cell') as HTMLElement;
      photoCell.dataset.photoCount = viewData.photoCount.toString();

      const photoCount = element.querySelector('.js-cart-index-photo-select') as HTMLSelectElement;
      photoCount.value = viewData.photoCount.toString();

      const photoPrice = element.querySelector('.js-cart-index-photo-price-price') as HTMLSelectElement;
      photoPrice.textContent = itemSubTotal.toString();
    });

    const rooms = document.querySelectorAll(`.js-cart-list-item-per-event[data-exhibition-room-id="${viewData.exhibitionRoomId}"]`);
    rooms.forEach(x => {
      const element = x as HTMLElement;
      element.querySelector('.js-cart-index-subtotal-value').textContent = formatPrice(roomSubTotal);
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  if (FeatureFlag.isEnabled('NXVO-863')) {
    if (!document.querySelector('.page.cart')) {
      return;
    }
    CartItemDetail.init();
  }
}
)
;
