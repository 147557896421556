import {getClient, querySelector, querySelectorAll} from '../../utilities';
import {
  BehaviorSubject,
  catchError, combineLatest, filter,
  from,
  fromEvent, map, mergeMap, Observable,
  of,
  switchMap,
  tap, toArray
} from 'rxjs';
import {PhotoQueryParameter} from './photos-index-base';
import {PhotoSearchPageChangedDetail} from './search-by-exhibition-room-dialog';

/**
 * 顔検索URL。
 */
const faceSearchUrl = '/customer/api/faceSearch/search';

const photoIndexUrl = '/customer/api/photoQuery/findPhotoIndex';

interface SearchResultPhoto {
  fileNumber: number;
  hasDownloadable: boolean;
  hasThumbnail: boolean;
  hasWatermarked: boolean;
  id: string;
  originalFileName: string;
  photoId: string;
  saleEventId: string;
  updatedAt: string;
}

interface SearchResultForChildId {
  childId: string;
  exhibitionRoomId: string;
  photos: SearchResultPhoto[];
}

export class SearchByPhotoResult {

  private static readonly _instance: SearchByPhotoResult = new SearchByPhotoResult();

  private rootElement: HTMLElement;
  private listElement: HTMLElement;
  private userId: string;
  private sessionId: string;

  private childIds$ = new BehaviorSubject([] as string[]);

  private exhibitionRoomIds$ = new BehaviorSubject([] as string[]);

  private searchResults$ = new BehaviorSubject(new Map<string, SearchResultPhoto[]>());

  private photoQueryParameter$ = new BehaviorSubject<PhotoQueryParameter>(null);

  static get instance(): SearchByPhotoResult {
    return SearchByPhotoResult._instance;
  }

  // region 初期化
  // #region 初期化

  initialize() {
    this.rootElement = querySelector('#search-by-photo-result-container') as HTMLElement;

    // eslint-disable-next-line max-len
    this.listElement = querySelector('#search-by-photo-result-container .search-by-photo-result-list') as HTMLElement;

    this.userId = (querySelector('#user-id') as HTMLInputElement).value;
    this.sessionId = (querySelector('#session-id') as HTMLInputElement).value;

    fromEvent(document, 'click')
        .pipe(
            filter(e => {
              const target = e.target as HTMLElement;
              if (!target || !target.closest) {
                return false;
              }
              const leftButton = target.closest('#search-by-photo-result-container .left-button');
              const rightButton = target.closest('#search-by-photo-result-container .right-button');
              return !!leftButton || !!rightButton;
            }),
            tap(e => this.handleScrollButtonOnClick(e))
        )
        .subscribe();

    fromEvent(window, 'scroll')
        .pipe(
            tap(() => this.handleWindowOnScrollOrResize())
        )
        .subscribe();

    fromEvent(window, 'resize')
        .pipe(
            tap(() => this.handleWindowOnScrollOrResize())
        )
        .subscribe();

    combineLatest([
      this.childIds$,
      this.exhibitionRoomIds$
    ])
        .pipe(
            switchMap(([childIds, roomIds]) => {
              return this.handleChildIdsOnChange(childIds, roomIds);
            }),
            tap(result => this.updateSearchResult(result))
        )
        .subscribe();

    this.searchResults$
        .pipe(
            tap(result => this.showSearchResult(result))
        )
        .subscribe();

    // combineLatest([
    //   this.photoQueryParameter$,
    //   this.exhibitionRoomIds$
    // ]).pipe(
    //     switchMap(([param, roomIds]) => this.updatePageIndices(param, roomIds))
    // ).subscribe();

    fromEvent(document, 'click')
        .pipe(
            filter(e => {
              const target = e.target as HTMLElement;
              if (!target || !target.matches) {
                return false;
              }
              const baseSelector
                  = '#search-by-photo-result-container .search-by-photo-result-list ';
              const selector = baseSelector + ' .search-by-photo-result-list-item'
                  + ', ' + baseSelector + ' .search-by-photo-result-list-item *';
              return target.matches(selector);
            }),
            tap(e => this.handleSearchResultOnClick(e))
        )
        .subscribe();
  }

  // #endregion 初期化
  // endregion 初期化

  // region API
  // #region API

  public static clearSearchResultsForChild(childId: string) {

    const keys = Array.from(new Array(localStorage.length)).map((_, index) => localStorage.key(index));

    // eslint-disable-next-line guard-for-in
    for (const key in keys) {
      if (!key) {
        continue;
      }

      const timeKey = `lastSearchedTime.${childId}`;
      const resultKey = `childSearchResult.${childId}`;
      if (key.startsWith(timeKey) || key.startsWith(resultKey)) {
        localStorage.removeItem(key);
      }
    }
  }

  /**
   * 指定された子供情報IDで検索する。
   * @param ids 子供情報ID一覧。
   */
  search(ids: string[]) {
    this.childIds$.next(ids);
  }

  /**
   * 展示室ID変更。
   * @param exhibitionRoomIds 展示室ID一覧。
   */
  setExhibitionRoomIds(exhibitionRoomIds: string[]) {
    this.exhibitionRoomIds$.next(exhibitionRoomIds);
  }

  /**
   * 検索パラメータセット。
   * @param parameter 検索パラメータ。
   */
  setSearchParameter(parameter: PhotoQueryParameter) {
    this.photoQueryParameter$.next(parameter);
  }

  // #endregion API
  // endregion API

  // region 画面関連
  // #region 画面関連

  private hideResult() {
    this.rootElement.classList.add('d-none');
  }

  private showResult() {
    this.rootElement.classList.remove('d-none');
  }

  // #endregion 画面関連
  // endregion 画面関連

  // region イベントハンドラ
  // #region イベントハンドラ

  private handleScrollButtonOnClick(e: Event) {
    const element = e.target as HTMLElement;
    const leftButton = element.closest('.left-button');
    if (leftButton) {
      this.scrollItem(true);
    } else {
      this.scrollItem();
    }

  }

  // #endregion イベントハンドラ
  // endregion イベントハンドラ

  // region 要素のスクロール
  // #region 要素のスクロール

  private scrollItem(scrollBack?: boolean) {
    const itemWidth = 228;
    const currentLeft = this.listElement.scrollLeft;
    const n = Math.floor(currentLeft / itemWidth);
    const nextN = scrollBack ? (n - 1) : (n + 1);
    const nextLeft = nextN <= 0 ? 0 : itemWidth * nextN;

    this.listElement.scrollTo({
      left: nextLeft,
      behavior: 'smooth'
    });
  }

  // #endregion 要素のスクロール
  // endregion 要素のスクロール

  // region 顔検索結果をすくろーるの途中まで追随させるための処理
  // #region 顔検索結果をすくろーるの途中まで追随させるための処理

  private handleWindowOnScrollOrResize() {
    this.setRelativeClassToRootElement();
  }

  private setRelativeClassToRootElement() {
    const scrollHeight = this.rootElement.classList.contains('relative')
        ? document.body.scrollHeight - 247
        : document.body.scrollHeight;

    const offset = window.scrollY;
    const windowHeight = window.innerHeight;
    const footer = querySelector('footer') as HTMLElement;
    const footerHeight = footer.offsetHeight;

    // もっとスクロールしないと表示されない部分の高さ。
    const invisibleHeight = scrollHeight - (offset + windowHeight);

    if (invisibleHeight < footerHeight) {
      this.rootElement.classList.add('relative');
    } else {
      this.rootElement.classList.remove('relative');
    }
  }

  // #endregion 顔検索結果をすくろーるの途中まで追随させるための処理
  // endregion 顔検索結果をすくろーるの途中まで追随させるための処理


  // region お子様情報ID変更時の処理。
  // #region お子様情報ID変更時の処理。

  private handleChildIdsOnChange(childIds: string[], exhibitionRoomIds: string[]) {
    if (!childIds
        || !childIds.length
        || !exhibitionRoomIds
        || !exhibitionRoomIds.length) {
      this.hideResult();
      return of(undefined);
    }

    this.showResult();

    const source = [] as { childId: string, exhibitionRoomId: string }[];

    childIds.forEach(childId => {
      return exhibitionRoomIds.forEach(exhibitionRoomId => {
        source.push({
          childId,
          exhibitionRoomId
        });
      });
    });
    return from(source)
        .pipe(
            mergeMap(x => this.searchForChildId(x.childId, x.exhibitionRoomId)),
            toArray()
        );
  }

  private searchForChildId(
      childId: string, exhibitionRoomId: string): Observable<SearchResultForChildId> {
    if (!SearchByPhotoResult.shouldSearch(childId, exhibitionRoomId)) {
      try {
        const key = SearchByPhotoResult.createSearchResultKey(childId, exhibitionRoomId);
        const result = localStorage.getItem(key);
        return of({
          childId,
          exhibitionRoomId,
          photos: JSON.parse(result) as SearchResultPhoto[]
        });
      } catch (err) {
        console.error(`顔検索結果のキャッシュを取得できませんでした。お子様情報ID=${childId}`);
      }
    }
    return this.searchAtServer(childId, exhibitionRoomId);
  }

  private searchAtServer(childId: string, exhibitionRoomId: string) {

    return from(getClient().get(faceSearchUrl, {
      params: {
        childId,
        exhibitionRoomId
      },
      paramsSerializer: SearchByPhotoResult.parameterSerializer
    })).pipe(
        map(x => {
          return {
            childId,
            exhibitionRoomId,
            photos: x.data as SearchResultPhoto[]
          };
        }),
        tap(result => SearchByPhotoResult.saveSearchResults(result)),
        catchError(x => {
          console.error('search error', x);
          return of({
            childId,
            exhibitionRoomId,
            photos: []
          } as SearchResultForChildId);
        })
    );
  }

  /**
   * 検索結果を保存する。
   * @param result 検索結果(お子様情報ID, 展示室ID毎)
   * @private
   */
  private static saveSearchResults(result: SearchResultForChildId) {
    const key = SearchByPhotoResult
        .createSearchResultKey(result.childId, result.exhibitionRoomId);
    localStorage.setItem(key, JSON.stringify(result.photos));

    const lastTimeKey = this.createLastSearchedTimeKey(result.childId, result.exhibitionRoomId);
    localStorage.setItem(lastTimeKey, new Date().toISOString());
  }

  public static clearAllBrowserCache() {
    const keys = Array.from(new Array(localStorage.length)).map((_, index) => localStorage.key(index));
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (!key) {
        continue;
      }
      if (key.startsWith('lastSearchedTime.')
          || key.startsWith('childSearchResult.')
          || key.startsWith('pageIndex.')
          || key.startsWith('pageIndexUpdatedAt.')) {
        localStorage.removeItem(key);
      }
    }
  }

  private static parameterSerializer(parameter: { childId: string, exhibitionRoomId: string }) {
    return `childId=${parameter.childId}&exhibitionRoomId=${parameter.exhibitionRoomId}`;
  }

  private static shouldSearch(childId: string, exhibitionRoomId: string) {
    const now = new Date();
    const searchedAt = SearchByPhotoResult.getLastSearchedTime(childId, exhibitionRoomId);

    if (!searchedAt) {
      return true;
    }

    const diff = (now.getTime() - searchedAt.getTime()) / (1000 * 60 * 60);
    return diff > 0.04;
  }

  private static getLastSearchedTime(childId: string, exhibitionRoomId: string) {
    const key = SearchByPhotoResult.createLastSearchedTimeKey(childId, exhibitionRoomId);
    const lastSearchedTimeText = localStorage.getItem(key);
    if (!lastSearchedTimeText) {
      return null;
    }

    const result = new Date(lastSearchedTimeText);
    if (isNaN(result as any)) {
      return null;
    }
    return result;
  }

  private static createSearchResultKey(childId: string, exhibitionRoomId: string) {
    return `childSearchResult.${childId}.${exhibitionRoomId}`;
  }

  private static createLastSearchedTimeKey(childId: string, exhibitionRoomId: string) {
    return `lastSearchedTime.${childId}.${exhibitionRoomId}`;
  }

  // #endregion お子様情報ID変更時の処理。
  // endregion お子様情報ID変更時の処理。

  // region 検索結果を画面に表示する
  // #region 検索結果を画面に表示する

  private updateSearchResult(result: SearchResultForChildId[]) {

    const current = this.searchResults$.getValue();
    current.clear();
    if (result) {
      result.forEach(x => {
        const key = SearchByPhotoResult.createSearchResultKey(x.childId, x.exhibitionRoomId);
        current.set(key, x.photos);
      });
    }
    this.searchResults$.next(current);
  }

  private showSearchResult(result: Map<string, SearchResultPhoto[]>) {
    querySelectorAll(':scope > *', this.listElement).forEach(x => x.remove());
    Array.from(result.keys()).forEach(key => {
      result.get(key).forEach(photo => {
        const li = document.createElement('li');
        li.classList.add('search-by-photo-result-list-item');
        li.dataset.photoId = photo.photoId;

        const img = document.createElement('img');
        // "/Customer/Api/Photos/@($"{Context.Session.Id}/{_currentScopeService.UserId}/{photo.SaleEventId}/{photo.Id}/{PhotoFileKind.Thumbnail}")"
        img.src = `/Customer/Api/Photos/`
            + `${this.sessionId}/${this.userId}/${photo.saleEventId}/${photo.id}`
            + `/Thumbnail`;
        li.append(img);

        this.listElement.append(li);
      });
    });
  }

  // #endregion 検索結果を画面に表示する
  // endregion 検索結果を画面に表示する

  // region 検索結果の存在するページインデクスに関する処理
  // #region 検索結果の存在するページインデクスに関する処理

  // /**
  //  * ページインデックスを更新する。
  //  * @param parameter 検索パラメータ。
  //  * @param exhibitionRoomIds 展示室ID一覧。
  //  * @return {Observable} ページインデックス更新処理
  //  * @private
  //  */
  // private updatePageIndices(
  //     parameter: PhotoQueryParameter,
  //     exhibitionRoomIds: string[]
  // ) {
  //   if (!parameter) {
  //     return of(undefined);
  //   }
  //   const keySuffix = this.userId + '.'
  //       + exhibitionRoomIds.sort().join('.')
  //       + parameter.pageSize.toString() + '.'
  //       + parameter.secondaryCategoryIds.sort().join('.')
  //       + parameter.tertiaryCategoryIds.sort().join('.');
  //
  //   const timeKey = `pageIndexUpdatedAt.${keySuffix}`;
  //   const pageIndexKey = `pageIndex.${keySuffix}`;
  //   const updatedAtText = localStorage.getItem(timeKey);
  //   const updatedAt = new Date(updatedAtText);
  //   const now = new Date();
  //
  //   if (updatedAtText && !Number.isNaN(updatedAt as any)) {
  //     const diff = (now.getTime() - updatedAt.getTime()) / (1000 * 60 * 60);
  //     if (diff < 2) {
  //       return of(undefined);
  //     }
  //   }
  //
  //   const firstParameter = {
  //     ...parameter,
  //     pageIndex: 0
  //   };
  //   return from(getClient().get(photoQueryUrl, {
  //     params: firstParameter,
  //     paramsSerializer: SearchByPhotoResult.serializePhotoQueryParameter
  //   })).pipe(
  //       switchMap(x => {
  //         const queryResult = x.data as PhotoQueryResult;
  //         const pageIndices = Array.from(new Array(queryResult.lastPage + 1)).map((_, n) => n);
  //         return merge(pageIndices);
  //       }),
  //       concatMap(x => from(getClient().get(photoQueryUrl, {
  //         params: {
  //           ...firstParameter,
  //           pageIndex: x
  //         } as PhotoQueryParameter,
  //         paramsSerializer: SearchByPhotoResult.serializePhotoQueryParameter
  //       })).pipe(
  //           map(x => x.data as PhotoQueryResult)
  //       )),
  //       toArray(),
  //       tap(x => {
  //         localStorage.setItem(pageIndexKey, JSON.stringify(x));
  //         localStorage.setItem(timeKey, new Date().toISOString());
  //       })
  //   );
  // }

  // private static serializePhotoQueryParameter(params: PhotoQueryParameter) {
  //   return `&pageIndex=${params.pageIndex}`
  //       + `&pageSize=${params.pageSize}`
  //       + params.secondaryCategoryIds.map((x, i) => `&secondaryCategoryIds[${i}]=${x}`).join('')
  //       + params.tertiaryCategoryIds.map((x, i) => `&tertiaryCategoryIds[${i}]=${x}`).join('');
  // }

  private static serializePhotoIndexParameter(params: PhotoQueryParameter & {photoId: string}) {
    return `pageSize=${params.pageSize}`
      + `&photoId=${params.photoId}`
      + params.secondaryCategoryIds.map((x, i) => `&secondaryCategoryIds%5B${i}%5D=${x}`).join('')
      + params.tertiaryCategoryIds.map((x, i) => `&tertiaryCategoryIds%5B${i}%5D=${x}`).join('');
  }

  private handleSearchResultOnClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target as HTMLElement;
    const li = target.closest('.search-by-photo-result-list-item') as HTMLElement;
    const photoId = li.dataset.photoId;

    const exhibitionRoomIds = this.exhibitionRoomIds$.getValue();
    const parameter = this.photoQueryParameter$.getValue();
    if (!exhibitionRoomIds || !parameter) {
      console.log('parameter not found');
      return;
    }

    // const keySuffix = this.userId + '.'
    //     + exhibitionRoomIds.sort().join('.')
    //     + parameter.pageSize.toString() + '.'
    //     + parameter.secondaryCategoryIds.sort().join('.')
    //     + parameter.tertiaryCategoryIds.sort().join('.');
    getClient().get(photoIndexUrl, {
      params: {
        ...parameter,
        photoId
      },
      paramsSerializer: SearchByPhotoResult.serializePhotoIndexParameter
    }).then(response => {
      const photoIndex = response.data.index;
      if (Number.isInteger(photoIndex) && photoIndex >= 0) {
        const nextIndex = Math.floor(photoIndex / parameter.pageSize);

        const e = new CustomEvent<PhotoSearchPageChangedDetail>('spss.photoSearchPageChanged', {
          detail: {
            photoId: photoId,
            pageIndex: nextIndex
          }
        });
        document.dispatchEvent(e);
      }

    }).catch(e => {
      console.log(e);
    });

    // const pageIndexKey = `pageIndex.${keySuffix}`;
    // const pageIndicesText = localStorage.getItem(pageIndexKey);
    // if (!pageIndicesText) {
    //   console.log('index not found');
    //   return;
    // }
    // const pageIndices = JSON.parse(pageIndicesText) as PhotoQueryResult[];
    // let nextIndex = -1;
    // pageIndices.forEach((result, index) => {
    //   if (nextIndex >= 0) {
    //     return;
    //   }
    //   if (result.items.some(x => x.photoId === photoId)) {
    //     nextIndex = index;
    //   }
    // });


  }

  // #endregion 検索結果の存在するページインデクスに関する処理
  // endregion 検索結果の存在するページインデクスに関する処理

}

document.addEventListener('DOMContentLoaded', () => {
  if (querySelector('.page.top-page') || querySelector('.page.login-page')) {
    if (!querySelector('html.signed-in')) {
      SearchByPhotoResult.clearAllBrowserCache();
    }
  }
  if (!querySelector('#search-by-photo-result-container')) {
    return;
  }

  SearchByPhotoResult.instance.initialize();
});
