// 購入フローの住所処理(お届け先と請求先)

import {querySelector, querySelectorAll, querySelectorHtml} from '../utilities';
import {isServerSideTrue} from '../utilities/is-server-side-true';

const setRequired = (required: boolean) => {
  const requiredInputKey = '.page.checkout .checkout-address-form #Address_Required';
  const requiredInput
      = querySelectorHtml(requiredInputKey) as HTMLInputElement;
  requiredInput.value = `${required}`;

  const inputsSelector = '.page.checkout .checkout-address-form .required input';
  const inputs = querySelectorAll(inputsSelector);
  inputs.forEach((input: HTMLInputElement) => {
    input.required = required;
  });
};

/**
 * 住所を入力欄に表示する。
 */
export const showAddress = () => {
  handleShippingAddressOnChange();
  setRequired(true);
};


/**
 * 住所入力欄をクリアする。
 */
export const clearAddress = () => {
  const inputs = querySelectorAll('.page.checkout .checkout-address-form input');
  inputs.forEach((input: HTMLInputElement) => {
    input.value = null;
  });
  setRequired(false);
};

function setDefaultInputValues() {
  setDefaultInputValuesImpl('default');
}
function setDefaultInputValuesImpl(valueKeyPrefix: string) {
  const postalCodeInputKey = '.page.checkout .checkout-address-form #Address_PostalCode';
  const postalCodeInput
      = querySelectorHtml(postalCodeInputKey) as HTMLInputElement;
  postalCodeInput.value = (querySelector(`#${valueKeyPrefix}-postal-code`) as HTMLInputElement).value;

  const prefectureInputKey = '.page.checkout .checkout-address-form #Address_Prefecture';
  const prefectureInput
      = querySelectorHtml(prefectureInputKey) as HTMLInputElement;
  prefectureInput.value = (querySelector(`#${valueKeyPrefix}-prefecture`) as HTMLInputElement).value;

  const cityInputKey = '.page.checkout .checkout-address-form #Address_City';
  const cityInput
      = querySelectorHtml(cityInputKey) as HTMLInputElement;
  cityInput.value = (querySelector(`#${valueKeyPrefix}-city`) as HTMLInputElement).value;

  const streetInputKey = '.page.checkout .checkout-address-form #Address_Street';
  const streetInput
      = querySelectorHtml(streetInputKey) as HTMLInputElement;
  streetInput.value = (querySelector(`#${valueKeyPrefix}-street`) as HTMLInputElement).value;

  const buildingInputKey = '.page.checkout .checkout-address-form #Address_Building';
  const buildingInput
      = querySelectorHtml(buildingInputKey) as HTMLInputElement;
  buildingInput.value = (querySelector(`#${valueKeyPrefix}-building`) as HTMLInputElement).value;

  const phoneNumber1InputKey = '.page.checkout .checkout-address-form #Address_PhoneNumber1';
  const phoneNumber1Input
      = querySelectorHtml(phoneNumber1InputKey) as HTMLInputElement;
  phoneNumber1Input.value = (querySelector(`#${valueKeyPrefix}-phone-number-1`) as HTMLInputElement).value;

  const phoneNumber2InputKey = '.page.checkout .checkout-address-form #Address_PhoneNumber2';
  const phoneNumber2Input
      = querySelectorHtml(phoneNumber2InputKey) as HTMLInputElement;
  phoneNumber2Input.value = (querySelector(`#${valueKeyPrefix}-phone-number-2`) as HTMLInputElement).value;

  const phoneNumber3InputKey = '.page.checkout .checkout-address-form #Address_PhoneNumber3';
  const phoneNumber3Input
      = querySelectorHtml(phoneNumber3InputKey) as HTMLInputElement;
  phoneNumber3Input.value = (querySelector(`#${valueKeyPrefix}-phone-number-3`) as HTMLInputElement).value;

  const lastNameInputKey = '.page.checkout .checkout-address-form #Address_LastName';
  const lastNameInput
      = querySelectorHtml(lastNameInputKey) as HTMLInputElement;
  lastNameInput.value = (querySelector(`#${valueKeyPrefix}-last-name`) as HTMLInputElement).value;

  const firstNameInputKey = '.page.checkout .checkout-address-form #Address_FirstName';
  const firstNameInput
      = querySelectorHtml(firstNameInputKey) as HTMLInputElement;
  firstNameInput.value = (querySelector(`#${valueKeyPrefix}-first-name`) as HTMLInputElement).value;

  const lastNameInputKanaKey = '.page.checkout .checkout-address-form #Address_LastNameHiragana';
  const lastNameInputKana
      = querySelectorHtml(lastNameInputKanaKey) as HTMLInputElement;
  lastNameInputKana.value = (querySelector(`#${valueKeyPrefix}-last-name-kana`) as HTMLInputElement).value;

  const firstNameInputKanaKey
      = '.page.checkout .checkout-address-form #Address_FirstNameHiragana';
  const firstNameInputKana
      = querySelectorHtml(firstNameInputKanaKey) as HTMLInputElement;
  firstNameInputKana.value
      = (querySelector(`#${valueKeyPrefix}-first-name-kana`) as HTMLInputElement).value;
}

function setInitialInputValues() {
  const useInitial = querySelector('#initial-use-initial-address') as HTMLInputElement;
  if (useInitial && isServerSideTrue(useInitial.value)) {
    setDefaultInputValuesImpl('initial');
  }
}

const handleShippingAddressOnChange = () => {
  const checkbox
      = querySelector('.page.checkout #use-another-address-checkbox') as HTMLInputElement;

  if (!checkbox) {
    console.error('その他の住所を使用するチェックボックスが存在しない');
    return;
  }

  const addressForm = querySelector('.page.checkout .checkout-address-form');
  if (!addressForm) {
    console.error('配送先住所formが見つからない');
    return;
  }

  const addressDetail = querySelector('.page.checkout .checkout-address-detail');
  if (!addressDetail) {
    console.error('配送先住所detailが見つからない');
    return;
  }

  const addressIndexNode = querySelector('.page.checkout #AddressIndex');
  const addressIndexElement = addressIndexNode as HTMLInputElement;
  if (checkbox.checked) {
    addressForm.classList.remove('d-none');
    const inputs = querySelectorAll(':scope .required input', addressForm);
    inputs.forEach((input: HTMLInputElement) => {
      input.required = true;
    });
    addressDetail.classList.add('d-none');
    addressIndexElement.value = '-1';
    setInitialInputValues();
  } else {
    addressForm.classList.add('d-none');
    addressDetail.classList.remove('d-none');
    addressIndexElement.value = '0';

    const inputs = querySelectorAll(':scope .required input', addressForm);
    inputs.forEach((input: HTMLInputElement) => {
      input.required = false;
    });

    setDefaultInputValues();
  }
};


const initializeAddress = () => {
  if (!querySelector('.page.checkout #use-another-address-checkbox')) {
    return;
  }

  const shippingAddressSelects
      = querySelectorAll('.page.checkout #use-another-address-checkbox');
  shippingAddressSelects.forEach((x: HTMLSelectElement) => {
    x.addEventListener('change', () => {
      handleShippingAddressOnChange();
    });
  });
  handleShippingAddressOnChange();
};

document.addEventListener('DOMContentLoaded', () => {
  initializeAddress();
});
