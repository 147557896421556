/**
 * `selector`に一致する要素の配列を取得する。
 * @param selector セレクタ。
 * @param root 検索を行うルートオブジェクト。 未指定の場合は `document`を利用する。
 * @return 要素の配列。
 */
export const querySelectorAll = (
    selector: string, root?: Element): Element[] => {
  const rootElement = root ? root : document;
  const elements = rootElement.querySelectorAll(selector);
  const result: Element[] = [];
  for (let i = 0; i < elements.length; i++) {
    result.push(elements[i]);
  }
  return result;
};

/**
 * 要素を選択する。
 * @param selector セレクタ。
 * @param root ルート要素。
 * @returns 要素または`null`。
 */
export const querySelector = (
    selector: string, root?: Element): Element | null => {
  const rootElement = root ? root : document;
  const result = rootElement.querySelector(selector);
  if (result) {
    return result;
  }
  return null;
};

// eslint-disable-next-line max-len
export const querySelectorHtml = <T extends HTMLElement>(selector: string, root?: HTMLElement): T | null => {
  return querySelector(selector, root) as T;
};

/**
 * 指定された`element`を含むその祖先要素の内、指定された`classNames`を全て含む要素を検索する。
 * @param element 検索開始要素。
 * @param classNames 検索対象のクラス。
 * @returns 検索結果。見つからない場合は`null`。
 */
export const closestClass = <T extends Element>(element: T, ...classNames: string[]): T | null => {

  return closestByFunc(element, current => {
    if (current == null) {
      return false;
    }
    return !classNames.some(className => !current.classList.contains(className));
  });
};

/**
 * 指定された`element`を含むその祖先要素の内、`predicate`が`true`を返す最初の要素を検索する。
 * @param element 検索開始要素。
 * @param predicate `current`が検索対象の場合に`true`を返す関数。
 * @returns 検索結果。見つからない場合は`null`。
 */
// eslint-disable-next-line max-len
export const closestByFunc = <T extends Element>(element: T, predicate: (current: Element) => boolean): T | null => {
  let current = element as Element;
  while (current != null) {
    if (predicate(current)) {
      return current as T;
    }
    current = current.parentElement;
  }
  return null;
};

// eslint-disable-next-line
export const withQuerySelectorAllAsync = async <TElement extends Element, TResult>(selector: string, callback: (element: TElement, index: number) => TResult, root?: Element): Promise<TResult[]> => {
  const elements = querySelectorAll(selector, root);
  const result = [] as TResult[];
  let index = 0;
  for (const x of elements) {
    const resultItem = await callback(x as TElement, index);
    result.push(resultItem);
    index++;
  }
  return result;
};

