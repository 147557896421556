/**
 * 数値(float)を取得する。
 *
 * - `value`が`null`, `undefined`, `""`, `0`等の`false`として解釈可能な値である場合は`0`を返す。
 * - `value`が数値である場合は、そのまま数値を返す。
 * - `value`が文字列である場合は、`parseFloat`した結果を返す。ただし`isNan`である場合は`0`を返す。
 * - その他の場合は常に`0`を返す。
 *
 * @param {any} value 値
 * @returns {number} 説明を参照
 */
export const getNumberOrZero = (value?: any) => {
  if (!value) {
    return 0;
  }

  if (typeof value === 'number') {
    return value;
  }

  if (typeof value === 'string') {
    const num = parseFloat(value);
    return isNaN(num) ? 0 : num;
  }

  return 0;
};
