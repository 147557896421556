import axios, {AxiosInstance, AxiosResponse} from 'axios';

let client: AxiosInstance = undefined;

export const getClient = (): AxiosInstance => {
  if (client) {
    return client;
  }
  client = axios.create({
    headers: {
      'X-CSRF-TOKEN': getRequestVerificationToken()
    }
  });
  return client;
};

/**
 * Ajax用のリクエスト検証トークンを取得する。
 * @return リクエスト検証トークン
 */
export const getRequestVerificationToken = (): string => {
  const input = document
      .querySelector(
          '#AjaxRequestVerificationToken') as HTMLInputElement;
  return input.value;
};

/**
 * Axiosのレスポンスが成功か否か判定する。
 * @param response レスポンス。
 * @return 成功の場合は`true`。
 */
export const isSucceededResponse = (response: AxiosResponse): boolean => {
  if (!response) {
    return false;
  }
  if (typeof response.status !== 'number') {
    return false;
  }
  return response.status >= 200 && response.status < 300;
};
