import {querySelector, querySelectorAll} from '../utilities';

document.addEventListener('DOMContentLoaded', () => {
  const isCheckoutPayment = querySelector('.page.checkout-payment');
  const isCheckoutConfirmation = querySelector('.page.checkout-confirmation');
  const isOrderHistoryDetail = querySelector('.page.order-detail');

  const isTargetPage = isCheckoutPayment || isCheckoutConfirmation || isOrderHistoryDetail;
  if (!isTargetPage) {
    return;
  }

  const searchValue = 'コンビニ後払い(スコア後払い)';
  const replaceValues = [
    document.createTextNode('コンビニ後払い'),
    document.createElement('br'),
    document.createTextNode('(スコア後払い)')
  ];

  const paymentMethodNameElement = querySelectorAll('.payment-method-name')
      .find(element => element.textContent.toUpperCase() === searchValue.toUpperCase());

  if (!!paymentMethodNameElement) {
    paymentMethodNameElement.childNodes.forEach(x => x.remove());
    replaceValues.forEach(value => {
      paymentMethodNameElement.append(value);
    });
  }
});
