import {querySelectorAll} from '../utilities';

$(() => {
  querySelectorAll('[data-collapse-icon-container]').forEach(collapsable => {
    const $element = $(collapsable);
    $element.on('hidden.bs.collapse', e => {
      const $target = $(e.target);
      const togglerSelector = $target.data('collapse-icon-container');
      querySelectorAll(togglerSelector).forEach(toggler => {
        const $toggler = $(toggler);
        $toggler.addClass('collapsed');
      });
    });
    $element.on('shown.bs.collapse', e => {
      const $target = $(e.target);
      const togglerSelector = $target.data('collapse-icon-container');
      querySelectorAll(togglerSelector).forEach(toggler => {
        const $toggler = $(toggler);
        $toggler.removeClass('collapsed');
      });
    });
  });
});
