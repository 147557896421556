const addComma = (text: string): string => {

  const chars = text.split('').reverse();
  const dotIndex = Math.max(-1, chars.indexOf('.'));
  const lengthWithoutDotIndex = dotIndex >= 0
      ? chars.length - (dotIndex + 1)
      : chars.length;

  const resultChars = [] as string[];
  for (let i = 0; i <= dotIndex; i++) {
    resultChars.push(chars[i]);
  }

  for (let i = 0; i < lengthWithoutDotIndex; i++) {
    const index = i + dotIndex + 1;
    const comma = i > 0 && i % 3 == 0;
    if (comma) {
      resultChars.push(',');
    }
    resultChars.push(chars[index]);
  }
  return resultChars.reverse().join('');
};

export const formatPrice = (price: number): string => {
  const src = price.toFixed(2);
  if (src.slice(-2) === '00') {
    return addComma(price.toFixed(0));
  }
  return addComma(src);
};
