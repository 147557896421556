import {querySelectorAll} from '../utilities';

const characterRegex = /[！-～]/g;

document.addEventListener('DOMContentLoaded', () => {
  const inputs = querySelectorAll('input.half-width-only');
  inputs.forEach((input: HTMLInputElement) => {
    input.addEventListener('blur', e => {
      const target = e.target as HTMLInputElement;
      if (target && target.value) {
        target.value = target.value.replace(characterRegex, char => {
          return String.fromCharCode(char.charCodeAt(0) - 0xFEE0);
        });
      }
    });
  });

  const forms = querySelectorAll('form');
  forms.forEach((form: HTMLFormElement) => {
    form.addEventListener('submit', e => {
      const container = e.target as HTMLFormElement;
      const inputs = querySelectorAll('input.half-width-only', container);
      inputs.forEach((input: HTMLInputElement) => {
        if (input && input.value) {
          input.value = input.value.replace(characterRegex, char => {
            return String.fromCharCode(char.charCodeAt(0) - 0xFEE0);
          });
        }
      });
    });
  });
});
