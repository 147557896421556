import {BehaviorSubject, filter, fromEvent, of, switchMap, tap} from 'rxjs';
import {getClient} from '../../utilities';
import {hideGlobalSpinner, showGlobalSpinner} from '../../common';
import {PhotoViewData} from './PhotoViewData';
import {CartFacade} from './_cart-facade';

/**
 * カート内の写真枚数選択。
 */
export class CartItemCountSelect {

  private viewData$: BehaviorSubject<PhotoViewData> = new BehaviorSubject<PhotoViewData>(undefined);

  constructor(private readonly callback: (viewData: PhotoViewData) => void) {
  }

  changeVieData(viewData: PhotoViewData) {
    this.viewData$.next(viewData);
  }

  bindToDom() {
    this.bindToSelectElementInDetail();

    this.viewData$
        .pipe(
            filter(x => !!x),
            tap(x => this.updateView(x))
        )
        .subscribe();
  }

  private bindToSelectElementInDetail() {
    fromEvent(document, 'input')
        .pipe(
            filter(x => {
              return (x?.target as HTMLElement)?.matches('.js-photo-count');
            }),
            tap(() => showGlobalSpinner()),
            switchMap(() => this.changePhotoCount()),
            tap(() => hideGlobalSpinner())
        )
        .subscribe({
          error: err => console.error(err)
        });
  }

  private updateView(viewData: PhotoViewData) {
    document.querySelectorAll('.js-photo-count').forEach((select: HTMLSelectElement) => {
      select.querySelectorAll(':scope > *').forEach(x => x.remove());

      for (let i = viewData.minPhotoCount; i <= viewData.maxPhotoCount; i++) {
        const option = document.createElement('option');
        const count = i.toString();
        option.text = option.value = count;
        select.appendChild(option);
      }

      const selectedOption = select.querySelector(
          `:scope > option[value="${viewData.photoCount}"]`) as HTMLOptionElement;
      selectedOption.selected = true;
      select.value = viewData.photoCount.toString();
    });
  }

  private changePhotoCount() {
    const countElement = document.querySelector('.js-photo-count') as HTMLSelectElement;
    if (!countElement) {
      return of(undefined);
    }

    const viewData = this.viewData$.getValue();
    const url = `customer/api/cart/item-count/${viewData.photoId}/${viewData.photoSize}`;

    const count = Number.parseInt(countElement.value);
    return getClient().put(url, {
      count
    }).then(() => {
      CartFacade.raiseChangedEvent();
      if (this.callback) {
        const next = viewData.updatePhotoCount(count);
        this.viewData$.next(next);
        this.callback(next);
      }
    } ).catch(e => {
      console.error(e);
      alert('枚数の変更に失敗しました');
    });
  }
}
