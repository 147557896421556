import {
  getClient,
  querySelectorAll
} from '../../utilities';
import {
  Cart,
  CartItem,
  isCart
} from '../models';
import {BehaviorSubject, finalize, from, fromEvent, Observable, switchMap, tap} from 'rxjs';
import {addToCartUrl} from '../common';
import {hideGlobalSpinner, showGlobalSpinner} from '../../common';
import {FeatureFlag} from '../../utilities/FeatureFlag';
import {AxiosResponse} from 'axios';

export interface AddToCartParam {
  /**
   * 写真ID。
   */
  photoId: string;

  /**
   * 写真サイズ。
   */
  photoSize: string;

  /**
   * 枚数。
   */
  count: number;
}

export interface RemoveFromCartParam {
  /**
   * 写真ID。
   */
  photoId: string;

  /**
   * 写真サイズ。
   */
  photoSize: string;
}


// region 再注文処理
// #region 再注文処理

// TODO: 再注文処理はAPIにする。
// const handleReorderButtonOnClickAsync = async (e: Event) => {
//   try {
//     showGlobalSpinner();
//     const target = e.target as HTMLButtonElement;
//     const photoId = target.dataset.cartItemPhotoId;
//
//     if (!photoId) {
//       console.error('再注文対象の写真IDが存在しません。');
//       return;
//     }
//
//     const photoSizeSelector = target.dataset.cartItemPhotoSizeSelector;
//     if (!photoSizeSelector) {
//       console.error('再注文対象の写真サイズセレクタが存在しません。');
//       return;
//     }
//
//     let photoSize = null as string;
//     const photoSizeElement = querySelector(photoSizeSelector) as HTMLSpanElement;
//     if (photoSizeElement) {
//       // 写真選択無しの場合もあり得る。
//       photoSize = photoSizeElement.dataset.photoSize;
//       if (!photoSize) {
//         console.error('再注文対象の写真サイズが指定されていません。');
//         return;
//       }
//     }
//
//     const requestBody = {
//       photoId,
//       photoSize,
//       count: 1
//     };
//
//     const axios = getClient();
//     try {
//       await axios.post('/Customer/Api/Cart/Items', requestBody);
//       await loadCartAsync();
//     } catch (err) {
//       alert('再注文出来ませんでした。');
//     }
//   } finally {
//     hideGlobalSpinner();
//   }
// };

// #endregion 再注文処理
// endregion 再注文処理

// region カートの表示・更新処理
// #region カートの表示・更新処理


// #endregion カートの表示・更新処理
// endregion カートの表示・更新処理

/**
 * カート関連の処理を行う。
 */
export class CartFacade {

  public static get cart$(): Observable<Cart | null> {
    return CartFacade.instance.cart$Subject;
  }

  private static instance: CartFacade = null;

  private numberOfCartItemsElements = [] as HTMLElement[];
  private cart$Subject = new BehaviorSubject<Cart | null>(null);


  constructor() {
    // カートの数量表示要素
    this.numberOfCartItemsElements = querySelectorAll(
        '.number-of-cart-items') as HTMLElement[];

    // カート内容変更イベント。
    fromEvent(document, 'spss.cartChanged')
        .pipe(
            tap(() => this.loadCartAsync())
        )
        .subscribe({
          error: console.error
        });
  }

  static async initializeAsync() {
    CartFacade.instance = new CartFacade();
    await CartFacade.instance.loadCartAsync();
  }

  static addToCart(addToCartParam: AddToCartParam): Observable<any> {
    return CartFacade.instance.addToCartImpl(addToCartParam);
  }

  static removeAllSizeFromCart(photoId: string): Observable<any> {
    return CartFacade.instance.removeAllSizeFromCartImpl(photoId);
  }

  static removeFromCartByItemId(cartItemId: string): Promise<void | AxiosResponse> {
    return getClient().delete(`/Customer/api/Cart/remove/${cartItemId}`)
        .catch(err => {
          console.error(err);
          alert('削除に失敗しました');
        });
  }

  // region カートに項目を追加する
  // #region カートに項目を追加する

  private addToCartImpl(addToCartParam: AddToCartParam): Observable<any> {
    return from(Promise.resolve()).pipe(
        tap(_ => showGlobalSpinner()),
        switchMap(() => getClient().post(addToCartUrl, addToCartParam)),
        tap(_ => {
          CartFacade.raiseChangedEvent();
        }),
        finalize(() => hideGlobalSpinner())
    );
  }

  private removeFromCartImpl(removeFromCartParam: RemoveFromCartParam): Observable<any> {
    return from(Promise.resolve()).pipe(
        tap(_ => showGlobalSpinner()),
        switchMap(() => getClient().delete(`/Customer/api/Cart/photo/${removeFromCartParam.photoId}/${removeFromCartParam.photoSize}`)),
        tap(_ => {
          CartFacade.raiseChangedEvent();
        }),
        finalize(() => hideGlobalSpinner())
    );
  }


  private removeAllSizeFromCartImpl(photoId: string): Observable<any> {
    return from(Promise.resolve()).pipe(
        tap(_ => showGlobalSpinner()),
        switchMap(() => getClient().delete(`/Customer/api/Cart/photos/${photoId}/all-size`)),
        tap(_ => {
          CartFacade.raiseChangedEvent();
        }),
        finalize(() => hideGlobalSpinner())
    );
  }

  // #endregion カートに項目を追加する
  // endregion カートに項目を追加する


  // region カートの内容をAPIで取得
  // #region カートの内容をAPIで取得
  private async loadCartAsync() {

    // カートが画面に存在しない場合は何もしない。
    if (!this.numberOfCartItemsElements.length) {
      return;
    }

    try {
      const response = await getClient().get('/customer/api/cart');
      if (!isCart(response.data) && response.data !== null) {
        console.error('カート取得レスポンス異常', response);
        return;
      }

      this.cart$Subject.next(response.data);

      this.updateCartView(response.data);

    } catch (err) {
      console.error('カートの更新処理に失敗', err);
    }
  }

  // #endregion カートの内容をAPIで取得
  // endregion カートの内容をAPIで取得


  // region 画面更新
  // #region 画面更新

  private updateCartView(cart: Cart) {

    this.numberOfCartItemsElements.forEach(x => {
      const numberOfCertItemsElement = x as HTMLElement;
      const itemCount = cart && Array.isArray(cart.cartItems)
        ? cart.cartItems.map((x: CartItem) => x.count).reduce((total, x) => total + x, 0)
        : 0;

      if (itemCount) {
        numberOfCertItemsElement.innerText = itemCount.toString();
        numberOfCertItemsElement.classList.add('has-any-items');
        numberOfCertItemsElement.classList.remove('item-digit-1');
        numberOfCertItemsElement.classList.remove('item-digit-2');
        numberOfCertItemsElement.classList.remove('item-digit-3');
        if (itemCount < 10) {
          numberOfCertItemsElement.classList.add('item-digit-1');
        } else if (itemCount < 100) {
          numberOfCertItemsElement.classList.add('item-digit-2');
        } else {
          numberOfCertItemsElement.classList.add('item-digit-3');
        }
      } else {
        numberOfCertItemsElement.classList.remove('has-any-items');
        numberOfCertItemsElement.classList.remove('item-digit-1');
        numberOfCertItemsElement.classList.remove('item-digit-2');
        numberOfCertItemsElement.classList.remove('item-digit-3');
      }
    });
  }

  // #endregion 画面更新
  // endregion 画面更新

  // カート内に写真が存在するか？

  /**
   * カートに指定された写真IDとサイズが入っているか判定する。
   * @param photoId 写真ID。
   * @param photoSize 写真サイズ。指定しない(undefined)の場合は、カート内の写真IDの有無のみ判定する。
   * @return {boolean|undefined} 写真がカートに入っている場合は`true`。
   */
  static doesPhotoExistInCart(photoId: string, photoSize?: number): boolean | undefined {
    if (!FeatureFlag.isEnabled('NXVO-867')) {
      photoSize = undefined;
    }
    return CartFacade.instance.doesPhotoExistInCartImpl(photoId, photoSize);
  }

  private doesPhotoExistInCartImpl(photoId: string, photoSize?: number) {
    const cart = this.cart$Subject.getValue();
    if (!cart) {
      return;
    }
    return cart.cartItems.some((x: CartItem) => x.photoId === photoId && (typeof photoSize === 'undefined' || photoSize === x.photoSize));
  }

  static raiseChangedEvent() {
    const e = new CustomEvent<any>('spss.cartChanged');
    document.dispatchEvent(e);
  }
}


// region 初期化
// #region 初期化

document.addEventListener('DOMContentLoaded', async () => {
  await CartFacade.initializeAsync();
});

// #endregion 初期化
// endregion 初期化
