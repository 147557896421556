import {closestClass, querySelector} from '../../utilities';
import {filter, fromEvent, tap} from 'rxjs';
import {showGlobalSpinner} from '../../common';

/**
 * カートページ。
 */
class CartIndex {

  constructor() {

    fromEvent(document, 'input')
        .pipe(
            filter(x => {
              return (x?.target as HTMLElement)?.matches('.page.cart select.photo-count');
            }),
            tap(e => CartIndex.handlePhotoCountOnChange(e))
        )
        .subscribe({
          error: err => console.error(err)
        });

    fromEvent(document, 'click')
        .pipe(
            filter(x => {
              const element = x?.target as HTMLElement;
              if (!element || !element.matches) {
                return false;
              }
              return element.matches('.checkout-button-container .remove-all-cart-item-button, '
                  + ' .checkout-button-container .remove-all-cart-item-button * '
                  + '.checkout-button-container  .go-to-checkout, '
                  + ' .checkout-button-container  .go-to-checkout *');
            }),
            tap(e => CartIndex.handleCheckoutButtonOnClick())
        )
        .subscribe();
  }

  initialize() {
  }


  private static handleCheckoutButtonOnClick() {
    showGlobalSpinner();
  }

  private static handlePhotoCountOnChange(e: Event) {
    showGlobalSpinner();
    const target = e.target as HTMLSelectElement;
    const form = (closestClass(target, 'photo-count-change-form') as any) as HTMLFormElement;
    form.submit();
  }
}

let cartIndex: CartIndex = null;

document.addEventListener('DOMContentLoaded', () => {
  if (!querySelector('.page.cart')) {
    return;
  }

  cartIndex = new CartIndex();
  cartIndex.initialize();
});
