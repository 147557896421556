import {querySelector} from '../../utilities';
import {SearchByPhotoResult} from '../photos/search-by-photo-result';

let isPhotoFileChange = false;

const calcWrapperHeightFromImage = (wrapper: HTMLElement, image: HTMLImageElement) => {
  const borders = wrapper.offsetWidth - wrapper.clientWidth;
  return wrapper.clientWidth / image.naturalWidth * image.naturalHeight + borders;
};

const showImage = (image: HTMLElement, placeholder: HTMLElement) => {
  image.style.visibility = 'visible';
  image.style.display = 'block';
  placeholder.style.visibility = 'hidden';
  placeholder.style.display = 'none';
};

const showPlaceholder = (image: HTMLElement, placeholder: HTMLElement) => {
  image.style.visibility = 'hidden';
  image.style.display = 'none';
  placeholder.style.visibility = 'visible';
  placeholder.style.display = 'block';
};

const setShouldChangePhoto = () => {
  const input = querySelector('#ShouldChangePhoto') as HTMLInputElement;
  if (input) {
    input.value = 'True';
  }
  isPhotoFileChange = true;
};

document.addEventListener('DOMContentLoaded', () => {
  if (!querySelector('.my-page.my-page-child-information')) {
    return;
  }

  const containerSelector = '.child-information .face-recognition-photo-container';
  const imageWrapperSelector = '.face-recognition-photo-wrapper';
  const imageSelector = '#face-recognition-photo';
  const placeholderWrapperSelector = '.face-recognition-placeholder-wrapper';
  const placeholderSelector = '#input-for-face-recognition-photo';
  const deleteButtonSelector = '.delete-button';

  const containerElement = querySelector(containerSelector) as HTMLElement;
  const imageWrapperElement = querySelector(imageWrapperSelector, containerElement) as HTMLElement;
  const imageElement = (querySelector(imageSelector) as HTMLImageElement);
  const placeholderWrapperElement
      = querySelector(placeholderWrapperSelector, containerElement) as HTMLElement;

  const deleteButtonElement
      = (querySelector(deleteButtonSelector, containerElement)) as HTMLButtonElement;

  const inputElement = (querySelector(placeholderSelector) as HTMLInputElement);

  imageElement.addEventListener('load', () => {
    const height = calcWrapperHeightFromImage(imageWrapperElement, imageElement);
    imageWrapperElement.style.height = `${height}px`;
  });

  const fileReader = new FileReader();
  fileReader.onerror = () => {
    showPlaceholder(imageWrapperElement, placeholderWrapperElement);
  };
  fileReader.onload = e => {
    const target = e.currentTarget as FileReader;
    imageElement.src = target.result as string;
    showImage(imageWrapperElement, placeholderWrapperElement);
  };
  fetch(imageElement.src)
      .then(async x => {
        if (!x.ok) {
          showPlaceholder(imageWrapperElement, placeholderWrapperElement);
          return;
        }

        const blob = await x.blob();
        fileReader.readAsDataURL(blob);
      })
      .catch(_ => {
        showPlaceholder(imageWrapperElement, placeholderWrapperElement);
      });

  deleteButtonElement.addEventListener('click', () => {
    inputElement.value = '';
    inputElement.dispatchEvent(new Event('change'));
  });

  inputElement.addEventListener('change', e => {
    const target = e.currentTarget as HTMLInputElement;

    setShouldChangePhoto();

    if (!target || !target.files || !target.files.length) {
      // 写真無しの場合は何もしない。
      showPlaceholder(imageWrapperElement, placeholderWrapperElement);
      return;
    }

    fileReader.readAsDataURL(target.files[0]);
  });

  querySelector('.child-edit-form').addEventListener('submit', () => {
    if (!isPhotoFileChange) {
      return;
    }

    const info
        = querySelector('.my-page.my-page-child-information .child-information') as HTMLElement;
    if (!info) {
      return;
    }

    const childId = info.dataset.childId;
    SearchByPhotoResult.clearSearchResultsForChild(childId);
  });
});
