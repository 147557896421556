import {querySelector, querySelectorAll} from '../utilities';
import {clearAddress, showAddress} from './checkout-address';
import {formatPrice} from '../common';

const updatePaymentMethod = () => {

  const checkedElement = querySelector('.page.checkout-payment input.payment-method-radio:checked');
  const fee = (() => {
    if (!checkedElement) {
      return 0;
    }
    const radio = checkedElement as HTMLInputElement;
    const tempFee = parseFloat(radio.dataset.fee);
    return isNaN(tempFee) ? 0 : tempFee;
  })();

  const paymentFeeElem = querySelector('.price-list-item.payment-fee .value') as HTMLElement;
  paymentFeeElem.innerText = formatPrice(fee).toString();

  const totalPrice = querySelector('.price-list-item.total-price .value') as HTMLElement;
  const photoPriceSrc = parseFloat(totalPrice.dataset.photoPrice);
  const photoPrice = isNaN(photoPriceSrc) ? 0 : photoPriceSrc;
  const shippingFeeSrc = parseFloat(totalPrice.dataset.shippingFee);
  const shippingFee = isNaN(shippingFeeSrc) ? 0 : shippingFeeSrc;

  totalPrice.innerText = formatPrice(photoPrice + shippingFee + fee).toString();

  const needsAddressText = (checkedElement as HTMLInputElement).dataset.needsAddress;
  const needsAddress = needsAddressText.toUpperCase() === `${true}`.toUpperCase();
  const row = querySelector('.page.checkout-payment .row.payment-address-row');
  if (needsAddress) {
    showAddress();
    row.classList.remove('d-none');
  } else {
    clearAddress();
    row.classList.add('d-none');
  }

};

const updateDeferredPaymentDescriptionDisplay = () => {
  const deferredPaymentRadio = querySelector('#payment-method-radio-deferred-payment') as HTMLInputElement;
  if (!deferredPaymentRadio) {
    return;
  }

  querySelectorAll('.js-deferred-payment-description').forEach(x => {
    if (deferredPaymentRadio.checked) {
      x.classList.remove('d-none');
    } else {
      x.classList.add('d-none');
    }
  });
};

const toggleDeferredPaymentDescription = (e: InputEvent) => {
  const target = e.target as HTMLInputElement;
  if (!target || !target.matches) {
    return;
  }

  if (!target.matches('.payment-method-radio[name="PaymentMethodKey"]')) {
    return;
  }
  updateDeferredPaymentDescriptionDisplay();
};

const initCheckoutPayment = () => {
  const radioButtons = querySelectorAll('.page.checkout-payment input.payment-method-radio');
  radioButtons.forEach((radio: HTMLInputElement) => {
    radio.addEventListener('change', () => {
      updatePaymentMethod();
    });
  });
  updatePaymentMethod();

  document.addEventListener('input', e => toggleDeferredPaymentDescription(e as InputEvent));
  updateDeferredPaymentDescriptionDisplay();
};

document.addEventListener('DOMContentLoaded', () => {
  if (!querySelector('.page.checkout-payment')) {
    return;
  }

  initCheckoutPayment();
});
