import {getClient, querySelector, querySelectorAll} from '../utilities';

const showAddresses = (addresses: any[], button: HTMLElement) => {

  const address = (() => {
    if (addresses && addresses.length) {
      return addresses[0];
    }
    return null;
  })();


  const prefecture = querySelector(button.dataset.prefectureSelector) as HTMLInputElement;

  if (prefecture) {
    prefecture.value = address?.prefecture || '';
  }

  const city = querySelector(button.dataset.citySelector) as HTMLInputElement;
  if (city) {
    city.value = address?.city || '';
  }

  const street = querySelector(button.dataset.streetSelector) as HTMLInputElement;
  if (street) {
    street.value = address?.street || '';
  }
};


const handleButtonOnClickAsync = async (e: Event) => {
  const element = e.target as HTMLElement;

  const b = ((): HTMLButtonElement => {
    if (element.tagName.toUpperCase() !== 'BUTTON') {
      return element.parentElement as HTMLButtonElement;
    }
    return element as HTMLButtonElement;
  })();

  const input = querySelector(b.dataset.inputSelector) as HTMLInputElement;
  if (!input) {
    console.warn('input not found');
    return;
  }

  if (!input.value || input.value.length < 3) {
    alert('郵便番号を3文字以上入力してください。');
    return;
  }

  const baseUrl = '/common/api/postalCode/' + input.value;

  try {
    const response = await getClient().get(baseUrl);
    showAddresses(response.data, b);
  } catch (err) {
    console.error(err);
    return;
  }
};

document.addEventListener('DOMContentLoaded', () => {
  const buttons = querySelectorAll('.auto-input-address-button');
  buttons.forEach((button: HTMLButtonElement) => {
    button.addEventListener('click', async e => {
      await handleButtonOnClickAsync(e);
    });
  });
});
