import {
  getClient,
  querySelector,
  querySelectorAll
} from '../../utilities';
import {
  BehaviorSubject,
  delay,
  filter,
  finalize, first,
  from,
  fromEvent,
  map,
  Observable,
  of,
  switchMap,
  tap
} from 'rxjs';
import {hideGlobalSpinner, showGlobalSpinner} from '../../common';
import {
  PhotoSearchPageChangedDetail,
  QueryParameterExhibitionRoomPart,
  SearchByExhibitionRoomDialog
} from './search-by-exhibition-room-dialog';

import {
  SearchByPhotoDialog
} from './search-by-photo-dialog';


import {IPhotoAccessor, PhotoDetailParameter} from './photo-detail-dialog';
import {
  PageData, PhotoQueryParameter,
  PhotosIndexBase
} from './photos-index-base';
import {SearchByPhotoResult} from './search-by-photo-result';

// region APIデータ
// #region APIデータ


// #endregion1 APIデータ
// endregion APIデータ

// region 定数
// #region 定数

// region URL
// #region URL

/**
 * 写真検索URL。
 */
const photoQueryUrl = '/customer/api/photoQuery/search';

// #endregion URL
// endregion URL


// #endregion 定数
// endregion 定数

// region ヘルパー
// #region ヘルパー

// #endregion ヘルパー
// endregion ヘルパー

// region メインクラス
// #region メインクラス

class PhotoPage extends PhotosIndexBase implements IPhotoAccessor {

  /**
   * 展示室で検索ダイアログ。
   * @private
   */
  private searchByExhibitionRoomDialog: SearchByExhibitionRoomDialog;

  /**
   * 写真で検索ダイアログ。
   * @private
   */
  private searchByPhotoDialog: SearchByPhotoDialog;

  /**
   * 写真検索ステータスリスト。
   * @private
   */
  private photoSearchStatusList: HTMLElement = null;

  /**
   * 写真検索ステータス項目テンプレート。
   * @private
   */
  private photoSearchStatusListItemTemplate: HTMLElement = null;

  /**
   * 写真検索パラメータ。
   * @private
   */
  private queryParameter$ = new BehaviorSubject<PhotoQueryParameter>({
    pageIndex: 0,
    pageSize: 100,
    secondaryCategoryIds: [],
    tertiaryCategoryIds: []
  });

  /**
   * 現在の写真ID。
   * @private
   */
  private activePhotoId: string | null = null;

  constructor() {
    super();
    this.searchByExhibitionRoomDialog = new SearchByExhibitionRoomDialog();
    this.searchByPhotoDialog = new SearchByPhotoDialog();
  }

  // region メソッド
  // #region メソッド

  initialize() {
    super.initialize();

    this.initializeQueryParameter();

    this.photoSearchStatusList = querySelector(
        '.page.photo-list .photo-search-status-list') as HTMLElement;

    this.photoSearchStatusListItemTemplate = querySelector(
        '#photo-search-status-list-item-template') as HTMLElement;

    fromEvent(document, 'input')
        .pipe(
            filter(e => {
              const target = e?.target as HTMLElement;
              if (!target || !target.matches) {
                return false;
              }
              return target.matches(
                  'input.photo-search-status-list-item-checkbox[type="checkbox"]');
            }),
            tap(e => this.handlePhotoSearchStatusListCheckboxOnChange(e))
        )
        .subscribe();

    // 検索条件変更。
    this.queryParameter$
        .pipe(
            switchMap(parameter => this.loadPageData(parameter)),
            tap(x => {
              const shouldShowChildPhoto
                  = x.exhibitionRooms
                  && Array.isArray(x.exhibitionRooms)
                  && x.exhibitionRooms.length > 0
                  && x.exhibitionRooms.some(r => r.basicInformation.isFaceSearchTarget);
              if (shouldShowChildPhoto) {
                querySelectorAll('.page.photo-list .search-by-photo-button')
                    .forEach(x => x.classList.remove('d-none'));
              } else {
                querySelectorAll('.page.photo-list .search-by-photo-button')
                    .forEach(x => x.classList.add('d-none'));
              }
            }),
            tap(x => {
              SearchByPhotoResult
                  .instance
                  .setExhibitionRoomIds(x.exhibitionRooms.map(x => x.id));

              const parameter = this.queryParameter$.getValue();
              SearchByPhotoResult
                  .instance
                  .setSearchParameter(parameter);
            }),
            tap(x => {
              this.searchByExhibitionRoomDialog.initialize(
                  x.exhibitionRooms,
                  this.queryParameter$.getValue());
            }),
            tap(x => {
              this.searchByPhotoDialog.updateQueryParameter(
                  x.exhibitionRooms,
                  this.queryParameter$.getValue());
            }),
            tap(x => {
              this.pageData$.next(x);
            }),
            tap(x => {
              this.updatePhotoSearchStatus(x);
            })
        )
        .subscribe({
          next: x => {
            this.updatePhotoList(x);
            if (this.activePhotoId) {
              const e = new CustomEvent<string>('spss.photoDetailClosed', {
                detail: this.activePhotoId
              });
              this.activePhotoId = null;
              setTimeout(() => {
                document.dispatchEvent(e);
              }, 500);
            }
          },
          error: e => console.error(e)
        });


    // ページャー
    fromEvent(document, 'click').pipe(
        filter(x => {
          if (!x || !((x.target as HTMLElement).matches)) {
            return false;
          }
          const selector = '.pagination .page-item:not(.disabled) .page-link';
          return (x.target as HTMLElement).matches(selector);
        }),
        tap(x => this.handlePageOnChange(x))
    ).subscribe();

    querySelector('.pagination-page-select').addEventListener('change', e => {
      const select = e.target as HTMLSelectElement;
      this.changeCurrentPage(Number.parseInt(select.value));
    });

    // 検索実行
    fromEvent(document, 'spss.photoSearchExecuted').pipe(
        tap(x => this.handleOnPhotoSearchExecuted(x))
    ).subscribe({
      error: err => console.error(err)
    });

    fromEvent(document, 'spss.photoSearchPageChanged').pipe(
        tap(x => this.handlePhotoSearchPageChanged(x))
    ).subscribe({
      error: err => console.error(err)
    });

    // 写真詳細を閉じる
    fromEvent(document, 'spss.photoDetailClosed').pipe(
        tap((e: CustomEvent<string>) => {
          const element = document.getElementById(`photo-list-item-${e.detail}`);
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center'
            });
            element.classList.add('focus');
          }
        }),
        delay(5000),
        tap(e => {
          const element = document.getElementById(`photo-list-item-${e.detail}`);
          if (element) {
            element.classList.remove('focus');
          }
        })
    ).subscribe();
  }

  // #endregion メソッド
  // endregion メソッド


  // region 検索関連
  // #region 検索関連

  protected initializeQueryParameter(): void {
    const queryParameter: PhotoQueryParameter = {
      pageIndex: 0,
      pageSize: 100,
      secondaryCategoryIds: [],
      tertiaryCategoryIds: []
    };

    if (!window.location.search) {
      this.queryParameter$.next(queryParameter);
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const parameterBase = this.loadQueryParameterBaseFromUrl(urlParams);
    queryParameter.pageIndex = parameterBase.pageIndex;
    queryParameter.pageSize = parameterBase.pageSize;

    for (let i = 0; i < 10000; i++) {
      const secondaryKey = `secondaryCategoryIds[${i}]`;
      const tertiaryKey = `tertiaryCategoryIds[${i}]`;
      const hasSecondary = urlParams.has(secondaryKey);
      const hasTertiary = urlParams.has(tertiaryKey);

      if (!hasSecondary && !tertiaryKey) {
        break;
      }

      if (hasSecondary) {
        queryParameter.secondaryCategoryIds.push(urlParams.get(secondaryKey));
      }
      if (hasTertiary) {
        queryParameter.tertiaryCategoryIds.push(urlParams.get(tertiaryKey));
      }
    }

    this.queryParameter$.next(queryParameter);
  }

  private loadPageData(parameter: PhotoQueryParameter): Observable<PageData> {
    return of(parameter).pipe(
        tap(() => showGlobalSpinner()),
        switchMap(parameter => PhotoPage.searchPhotos(parameter)),
        switchMap(queryResult => this.loadExhibitionRoomsForCustomer()
            .pipe(
                map(exhibitionRooms => {
                  return {
                    exhibitionRooms,
                    queryResult
                  } as PageData;
                })
            )
        ),
        finalize(() => hideGlobalSpinner())
    );
  }

  private handleOnPhotoSearchExecuted(e: Event) {
    const queryParameter = {
      ...this.queryParameter$.getValue(),
      ...(e as CustomEvent<QueryParameterExhibitionRoomPart>).detail,
      pageIndex: 0
    };
    this.changeQueryParameter(queryParameter);
  }

  private handlePhotoSearchPageChanged(e: Event) {
    const ce = e as CustomEvent<PhotoSearchPageChangedDetail>;
    const detail = ce.detail as PhotoSearchPageChangedDetail;
    const queryParameter = {
      ...this.queryParameter$.getValue(),
      pageIndex: detail.pageIndex
    } as PhotoQueryParameter;
    this.activePhotoId = detail.photoId;
    this.changeQueryParameter(queryParameter);
  }

  private changeQueryParameter(queryParameter: PhotoQueryParameter) {
    window.history.pushState(
        queryParameter,
        '写真検索',
        '/Photos?' + PhotoPage.serializeQueryParameter(queryParameter));

    this.queryParameter$.next(queryParameter);
  }

  // #endregion 検索関連
  // endregion 検索関連


  // region APIアクセス
  // #region APIアクセス

  private static searchPhotos(parameter: PhotoQueryParameter) {
    return from(getClient().get(photoQueryUrl, {
      params: parameter,
      paramsSerializer: PhotoPage.serializeQueryParameter
    })).pipe(
        map(x => x.data)
    );
  }

  private static serializeQueryParameter(params: PhotoQueryParameter) {
    return `pageIndex=${params.pageIndex}`
        + `&pageSize=${params.pageSize}`
        + params.secondaryCategoryIds.map((x, i) => `&secondaryCategoryIds%5B${i}%5D=${x}`).join('')
        + params.tertiaryCategoryIds.map((x, i) => `&tertiaryCategoryIds%5B${i}%5D=${x}`).join('');
  }


  // #endregion APIアクセス
  // endregion APIアクセス


  // region ページ遷移
  // #region ページ遷移

  private handlePageOnChange(e: Event) {
    const target = e.target as HTMLElement;
    const pageIndex = parseInt(target.dataset.pageIndex);
    this.changeCurrentPage(pageIndex);
  }

  private changeCurrentPage(pageIndex: number) {
    if (Number.isNaN(pageIndex)) {
      return;
    }

    const queryParameter: PhotoQueryParameter = {
      ...this.queryParameter$.getValue(),
      pageIndex
    };
    this.changeQueryParameter(queryParameter);
    window.scroll(0, 0);
  }

  // #endregion ページ遷移
  // endregion ページ遷移

  // region 前後の写真を取得する
  // #region 前後の写真を取得する

  getPrevNextPhoto(photoId: string, isPrev: boolean): Observable<PhotoDetailParameter | undefined> {
    const pageData = this.pageData$.getValue();

    const neighborPhoto = pageData.queryResult.items.reduce((result, photo, index) => {
      if (photo.id === photoId) {
        if (isPrev) {
          if (index > 0) {
            return pageData.queryResult.items[index - 1];
          }
          return 'prevPage';
        }
        if ((index + 1) < pageData.queryResult.items.length) {
          return pageData.queryResult.items[index + 1];
        }
        return 'nextPage';
      }

      return result;
    }, null);

    if (typeof neighborPhoto === 'string') {
      return this.loadPrevNextPage(pageData, neighborPhoto === 'prevPage');
    }

    if (!neighborPhoto) {
      return of(undefined);
    }

    const photoDetailParameter = this.loadPhotoDetailParameter(neighborPhoto.id);
    if (!photoDetailParameter) {
      return of(undefined);
    }
    return of(photoDetailParameter);

  }

  private loadPrevNextPage(
      pageData: PageData,
      isPrev: boolean): Observable<PhotoDetailParameter | undefined> {
    const currentQueryParam = this.queryParameter$.getValue();
    if (isPrev) {
      if (pageData.queryResult.pageIndex === 0) {
        return of(undefined);
      }

      const pageIndex = pageData.queryResult.pageIndex - 1;
      const queryParameter = {
        ...currentQueryParam,
        pageIndex
      } as PhotoQueryParameter;
      this.changeQueryParameter(queryParameter);
      return this.loadPrevNextPageData(pageData.queryResult.pageIndex).pipe(
          map(x => {
            if (x?.queryResult?.items) {
              const last = x?.queryResult?.items[x.queryResult.items.length - 1];
              if (last) {
                return this.loadPhotoDetailParameter(last.photoId);
              }
            }
            return undefined;
          })
      );
    }

    if (pageData.queryResult.pageIndex === pageData.queryResult.lastPage) {
      return of(undefined);
    }

    const pageIndex = pageData.queryResult.pageIndex + 1;
    const queryParameter = {
      ...currentQueryParam,
      pageIndex
    } as PhotoQueryParameter;
    this.changeQueryParameter(queryParameter);
    return this.loadPrevNextPageData(pageData.queryResult.pageIndex).pipe(
        map(x => {
          if (x?.queryResult?.items?.length) {
            const first = x.queryResult.items[0];
            if (first) {
              return this.loadPhotoDetailParameter(first.photoId);
            }
          }
          return undefined;
        })
    );
  }

  private loadPrevNextPageData(oldIndex: number): Observable<PageData> {
    const predicate = (x: PageData) => {
      return x.queryResult.pageIndex !== oldIndex;

    };

    const existsHtml = (x: PageData) => {
      if (!x?.queryResult?.items?.length) {
        return true;
      }
      const last = x.queryResult.items[x.queryResult.items.length - 1];
      return !!querySelector(`.photo-list-item[data-photo-id="${last?.photoId}"]`);
    };


    const waitHtml = (x: PageData): Observable<PageData> => {
      return from(
          new Promise<PageData>(resolve => {
            const check = () => {
              if (existsHtml(x)) {
                return resolve(x);
              }
              setTimeout(() => {
                check();
              }, 500);
            };

            setTimeout(() => {
              check();
            }, 500);
          })
      );
    };

    return this.pageData$.pipe(
        first(x => predicate(x)),
        switchMap(pageData => waitHtml(pageData))
    );
  }

  // #endregion 前後の写真を取得する
  // endregion 前後の写真を取得する

  // region 写真検索ステータス
  // #region 写真検索ステータス

  private updatePhotoSearchStatus(pageData: PageData) {
    if (!this.photoSearchStatusList) {
      console.warn('写真検索ステータスリストが見つかりません');
      return;
    }

    const parameter = this.queryParameter$.getValue();

    querySelectorAll(':scope > *', this.photoSearchStatusList).forEach(x => x.remove());

    // 絞り込み無しの場合はリストをクリアする。
    if (!parameter.secondaryCategoryIds.length && !parameter.tertiaryCategoryIds.length) {
      this.photoSearchStatusList.parentElement.classList.add('empty');
      return;
    }

    this.photoSearchStatusList.parentElement.classList.remove('empty');

    let selectedSecondaryIds = [...parameter.secondaryCategoryIds];
    let selectedTertiaryIds = [...parameter.tertiaryCategoryIds];
    parameter.secondaryCategoryIds.forEach(secondaryCategoryId => {
      const room = pageData.exhibitionRooms
          .filter(x => x.secondaryCategories.some(sc => sc.id === secondaryCategoryId))[0];
      if (!room) {
        console.warn('検索対象の展示室が見つかりません。中項目ID:', secondaryCategoryId);
        selectedSecondaryIds = [
          ...parameter.secondaryCategoryIds.filter(x => x !== secondaryCategoryId)
        ];
        return;
      }

      const secondaryCategory = room.secondaryCategories
          .filter(x => x.id === secondaryCategoryId)[0];

      // 検索対象に中項目が含まれていて、中項目以下の小項目が全て検索対象である場合、
      // 表示上は中項目1つとする
      const tempTertiaryCategoryIds = [...secondaryCategory.tertiaryCategories.map(x => x.id)];
      const notSelectedTertiaryCategories = tempTertiaryCategoryIds
          .filter(x => !selectedTertiaryIds.includes(x));
      if (!notSelectedTertiaryCategories.length) {
        selectedTertiaryIds = [
          ...selectedTertiaryIds.filter(x => !tempTertiaryCategoryIds.includes(x))
        ];
      }
    });


    selectedSecondaryIds.forEach(secondaryCategoryId => {
      let found = false;
      pageData.exhibitionRooms.forEach(room => {
        if (found) {
          return;
        }
        room.secondaryCategories.forEach(sc => {
          if (found) {
            return;
          }
          if (sc.id !== secondaryCategoryId) {
            return;
          }
          found = true;

          const template = this.photoSearchStatusListItemTemplate.cloneNode(true) as HTMLElement;
          const input = template.querySelector(':scope > input') as HTMLInputElement;
          const id = `photo-search-status-list-checkbox-${sc.id}`;
          input.id = id;
          input.checked = true;
          input.value = sc.id;
          const label = template.querySelector(':scope > label') as HTMLLabelElement;
          label.htmlFor = id;
          label.textContent = room.basicInformation.eventName + ' ' + sc.secondaryCategoryName;

          const li = document.createElement('li');
          li.classList.add('photo-search-status-list-item');
          li.append(input);
          li.append(label);
          this.photoSearchStatusList.append(li);
        });
      });
    });

    selectedTertiaryIds.forEach(tertiaryCategoryId => {
      let found = false;
      pageData.exhibitionRooms.forEach(room => {
        if (found) {
          return;
        }
        room.secondaryCategories.forEach(sc => {
          if (found) {
            return;
          }
          sc.tertiaryCategories.forEach(tc => {
            if (found) {
              return;
            }
            if (tc.id !== tertiaryCategoryId) {
              return;
            }

            found = true;

            const template = this.photoSearchStatusListItemTemplate.cloneNode(true) as HTMLElement;
            const input = template.querySelector(':scope > input') as HTMLInputElement;
            const id = `photo-search-status-list-checkbox-${tc.id}`;
            input.id = id;
            input.checked = true;
            input.value = tc.id;
            const label = template.querySelector(':scope > label') as HTMLLabelElement;
            label.htmlFor = id;
            label.textContent = room.basicInformation.eventName
                + ' '
                + sc.secondaryCategoryName
                + ' '
                + tc.tertiaryCategoryName;

            const li = document.createElement('li');
            li.classList.add('photo-search-status-list-item');
            li.append(input);
            li.append(label);
            this.photoSearchStatusList.append(li);
          });
        });
      });
    });

  }

  private handlePhotoSearchStatusListCheckboxOnChange(e: Event) {
    const element = e.target as HTMLInputElement;
    const id = element.value;
    if (!id) {
      console.warn('検索ステータス変更:対象のカテゴリIDを取得できません');
      return;
    }

    const pageData = this.pageData$.getValue();
    if (!pageData) {
      console.warn('検索ステータス変更:pageDataが見つかりません');
      return;
    }

    const parameter = this.queryParameter$.getValue();
    if (!parameter) {
      console.warn('検索ステータス変更:parameterが見つかりません');
      return;
    }

    let secondaryCategoryIds = [...parameter.secondaryCategoryIds.filter(x => x !== id)];
    let tertiaryCategoryIds = [...parameter.tertiaryCategoryIds.filter(x => x !== id)];
    let found = false;
    pageData.exhibitionRooms.forEach(room => {
      if (found) {
        return;
      }
      room.secondaryCategories.forEach(sc => {
        if (found) {
          return;
        }
        if (sc.id === id) {
          found = true;
          const tcs = sc.tertiaryCategories.map(x => x.id);
          tertiaryCategoryIds = [...tertiaryCategoryIds.filter(x => !tcs.includes(x))];
          return;
        }

        sc.tertiaryCategories.forEach(tc => {
          if (found) {
            return;
          }
          if (tc.id !== id) {
            return;
          }
          found = true;
          secondaryCategoryIds = [...secondaryCategoryIds.filter(x => x !== sc.id)];
        });
      });
    });

    const newParameter: PhotoQueryParameter = {
      ...parameter,
      secondaryCategoryIds,
      tertiaryCategoryIds
    };
    this.changeQueryParameter(newParameter);
  }

  // #endregion 写真検索ステータス
  // endregion 写真検索ステータス

}


// #endregion メインクラス
// endregion メインクラス

// region 初期化
// #region 初期化

let photoPage: PhotoPage = null;

document.addEventListener('DOMContentLoaded', () => {
  const page = querySelector('.page.photo-list-js') as HTMLElement;
  if (!page || page.classList.contains('favorite')) {
    return;
  }

  photoPage = new PhotoPage();
  photoPage.initialize();
});

// #endregion 初期化
// endregion 初期化
