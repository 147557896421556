const isPageShowsToTopAndBottomButton = () => {
    const urls = [
        '/Cart',
        '/Customer/Checkout/Confirmation',
        '/MyPage/OrderHistory',
        '/MyPage/OrderHistory/Detail',
        '/Photos',
        '/Faq',
    ]
    
    return urls.some(x => location.pathname.startsWith(x))
}

const setIntersectionObserver = (button: HTMLElement, destinationElement: HTMLElement) => {
    const callback = (e) => {
        if (e[0].isIntersecting) {
            button.classList.add('d-none');
        } else {
            button.classList.remove('d-none');
        }
    }
    // 一番下へボタンの移動先に到達した際に一番下へボタンが非表示となるよう1px調整
    const marginBottom = document.documentElement.clientHeight - 1
    // 一番下へ移動後、フッターを除いたコンテンツの最下部が表示されるよう
    // 「#destination-for-to-bottom-button」でネガティブマージン(margin-top: -100vh)を指定している
    // root要素が画面最上部 + 1pxの直線となるようrootMarginを調整し、
    // 一番下へボタンの移動先に到達した際に一番下へボタンが非表示となるようにする
    const options = {
        rootMargin: `0px 0px -${marginBottom}px 0px`
    };
    const observer = new IntersectionObserver(callback, options);

    observer.observe(destinationElement);
}

const initToTopAndBottomButton = () => {
    const toBottomButton = document.querySelector(".to-top-and-bottom-button.to-bottom-button") as HTMLElement
    const toTopButton = document.querySelector(".to-top-and-bottom-button.to-top-button") as HTMLElement
    const header = document.querySelector("header") as HTMLElement
    const destinationForToBottomButton = document.querySelector("#destination-for-to-bottom-button") as HTMLElement

    if (!toTopButton && !toBottomButton && !header && !destinationForToBottomButton) {
        return;
    }

    destinationForToBottomButton.classList.remove('d-none')

    setIntersectionObserver(toBottomButton, destinationForToBottomButton)
    setIntersectionObserver(toTopButton, header)
}

document.addEventListener('DOMContentLoaded', () => {
    if (!isPageShowsToTopAndBottomButton()) {
        return
    }
    
    initToTopAndBottomButton();
});